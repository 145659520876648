import React from 'react'

export function Building(props) {
  return (
    <svg
      {...props}
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      width='24'
      height='24'
      viewBox='0 0 24 24'
    >
      <defs>
        <style>
          {
            '.building-a{fill:none;}.building-b{clip-path:url(#a);}.building-c{}'
          }
        </style>
        <clipPath id='building-a'>
          <rect className='building-a' width='24' height='24' />
        </clipPath>
      </defs>
      <g className='building-b'>
        <path className='building-a' d='M0,0H24V24H0Z' />
        <path
          className='building-c'
          d='M12,7V5a2.006,2.006,0,0,0-2-2H4A2.006,2.006,0,0,0,2,5V19a2.006,2.006,0,0,0,2,2H20a2.006,2.006,0,0,0,2-2V9a2.006,2.006,0,0,0-2-2ZM6,19H4V17H6Zm0-4H4V13H6Zm0-4H4V9H6ZM6,7H4V5H6Zm4,12H8V17h2Zm0-4H8V13h2Zm0-4H8V9h2Zm0-4H8V5h2Zm9,12H12V17h2V15H12V13h2V11H12V9h7a1,1,0,0,1,1,1v8A1,1,0,0,1,19,19Zm-1-8H16v2h2Zm0,4H16v2h2Z'
          {...props}
        />
      </g>
    </svg>
  )
}
