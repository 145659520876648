import React from 'react'

export function Confirmed(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='35.469'
      height='30.073'
      viewBox='0 0 35.469 30.073'
    >
      <defs>
        <style>{'.confirmed-a{fill:#12cb2f;}'}</style>
      </defs>
      <g transform='translate(9.766 85.059)'>
        <path
          className='confirmed-a'
          d='M10.748-55.147A15.077,15.077,0,0,0,25.7-70.1,15.08,15.08,0,0,0,10.733-85.059,15.025,15.025,0,0,0-4.106-71.745a9.713,9.713,0,0,1,2.537-.1,12.337,12.337,0,0,1,12.3-10.718A12.437,12.437,0,0,1,23.211-70.1,12.264,12.264,0,0,1,20-61.76c-1.437-1.716-4.839-3.358-9.267-3.358a16.462,16.462,0,0,0-3.856.455,9.245,9.245,0,0,1,.264,2.214A9.359,9.359,0,0,1,4.9-56.378,14.523,14.523,0,0,0,10.748-55.147ZM10.733-67.61c2.815.029,5.029-2.375,5.029-5.528a5.232,5.232,0,0,0-5.029-5.411A5.2,5.2,0,0,0,5.7-73.138,5.229,5.229,0,0,0,10.733-67.61ZM-2.317-54.985a7.526,7.526,0,0,0,7.449-7.449,7.478,7.478,0,0,0-7.449-7.449,7.5,7.5,0,0,0-7.449,7.449A7.478,7.478,0,0,0-2.317-54.985Zm-.894-3.065a1.059,1.059,0,0,1-.733-.308l-2.8-3.094a.907.907,0,0,1-.191-.587.908.908,0,0,1,.909-.924.9.9,0,0,1,.7.323L-3.241-60.4.616-65.762a.963.963,0,0,1,.777-.411.926.926,0,0,1,.924.909,1.047,1.047,0,0,1-.205.557l-4.545,6.32A.97.97,0,0,1-3.211-58.05Z'
        />
      </g>
    </svg>
  )
}
