import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import { Field } from 'formik'

import Icon from 'components/Icon'

import {
  Container,
  Wrapper,
  Label,
  Masked,
  Control,
  Toggle,
  Error
} from './styled'

const MASKS = {
  currency: createNumberMask({
    prefix: 'R$',
    decimalSymbol: ',',
    allowDecimal: true,
    includeThousandsSeparator: false
  }),
  time: [/\d/, /\d/, ':', /\d/, /\d/],
  date: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/],
  phone: [
    '(',
    /\d/,
    /\d/,
    ')',
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    /\d/
  ],
  cellphone: [
    '(',
    /\d/,
    /\d/,
    ')',
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    /\d/
  ],
  cpf: [
    /\d/,
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/
  ],
  cnpj: [
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    '/',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/
  ],
  zipcode: [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]
}

const renderValue = (value, mask) => {
  if (!value) return ''

  if (mask === 'currency') {
    return `${value.replace(/\./, ',')}`
  }

  return value
}

function Input({
  type,
  name,
  label,
  placeholder,
  onChange,
  onKeyPress,
  autoFocus,
  disabled,
  variant,
  size,
  mask,
  width,
  mb
}) {
  const [isVisible, setIsVisible] = useState(false)

  const handleToggleValue = useCallback(() => {
    setIsVisible(!isVisible)
  }, [isVisible])

  return (
    <Field name={name}>
      {({
        field: { value, ...props },
        meta: { touched, error },
        form: { setFieldValue }
      }) => (
        <Container mb={mb}>
          <Wrapper variant={variant}>
            {label && <Label variant={variant}>{label}</Label>}

            {mask && MASKS[mask] ? (
              <Masked
                mask={MASKS[mask]}
                type={
                  type === 'password' ? (isVisible ? 'text' : 'password') : type
                }
                value={renderValue(value, mask)}
                placeholder={placeholder}
                onKeyPress={onKeyPress}
                autoFocus={autoFocus}
                disabled={disabled}
                error={touched && error ? 1 : 0}
                variant={variant}
                width={width}
                {...props}
                onChange={e => {
                  const { value } = e.target

                  if (mask) {
                    if (mask === 'currency') {
                      const typed = value.replace(/R\$/g, '')
                      setFieldValue(name, typed)

                      return
                    }

                    if (mask === 'zipcode') {
                      setFieldValue(name, value)
                      onChange(value)

                      return
                    }
                  }

                  setFieldValue(name, value)
                }}
                size={size}
              />
            ) : (
              <Control
                type={
                  type === 'password' ? (isVisible ? 'text' : 'password') : type
                }
                value={value}
                placeholder={placeholder}
                onKeyPress={onKeyPress}
                autoFocus={autoFocus}
                disabled={disabled}
                error={touched && error ? 1 : 0}
                variant={variant}
                width={width}
                {...props}
                onChange={e => {
                  setFieldValue(name, e.target.value)

                  if (onChange) {
                    onChange(e.target.value)
                  }
                }}
                size={size}
              />
            )}

            {value && type === 'password' && (
              <Toggle type='button' onClick={handleToggleValue}>
                {!isVisible ? <Icon name='show' /> : <Icon name='hide' />}
              </Toggle>
            )}

            {touched && error && <Error>{error}</Error>}
          </Wrapper>
        </Container>
      )}
    </Field>
  )
}

Input.defaultProps = {
  type: 'text',
  variant: 'primary',
  size: 'default',
  mb: '0px'
}

Input.propTypes = {
  type: PropTypes.oneOf(['text', 'number', 'email', 'password', 'time']),
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onKeyPress: PropTypes.func,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  variant: PropTypes.oneOf(['primary', 'secondary', 'inline', 'highlighted']),
  size: PropTypes.oneOf(['sm', 'default', 'md']),
  mask: PropTypes.string,
  width: PropTypes.string,
  mb: PropTypes.string
}

export default Input
