import React from 'react'

export function Right(props) {
  return (
    <svg
      {...props}
      xmlns='http://www.w3.org/2000/svg'
      width='9.305'
      height='15.792'
      viewBox='0 0 9.305 15.792'
    >
      <defs>
        <style>{'.right-a{fill:#959595;}'}</style>
      </defs>
      <path
        className='right-a'
        d='M8.892,13.372,3.409,7.889,8.892,2.406A1.409,1.409,0,0,0,6.9.413L.413,6.9a1.407,1.407,0,0,0,0,1.992L6.9,15.378a1.407,1.407,0,0,0,1.992,0,1.438,1.438,0,0,0,0-2.007Z'
        transform='translate(9.305 15.792) rotate(180)'
      />
    </svg>
  )
}
