import React from 'react'

export function Appointments({ ...props }) {
  return (
    <svg
      {...props}
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
    >
      <defs>
        <style>
          {
            '.a,.c,.d{fill:none;}.b{}.c,.d{stroke:#494949;stroke-width:0.7px;}.d{stroke-linecap:round;stroke-linejoin:round;}.e{stroke:none;}'
          }
        </style>
      </defs>
      <path className='a' d='M0,0H24V24H0Z' />
      <path
        className='b'
        d='M16,3V4H8V3A1,1,0,0,0,6,3V4H5A1.991,1.991,0,0,0,3.01,6L3,20a2,2,0,0,0,2,2H19a2.006,2.006,0,0,0,2-2V6a2.006,2.006,0,0,0-2-2H18V3a1,1,0,0,0-2,0Zm2,17H6a1,1,0,0,1-1-1V9H19V19A1,1,0,0,1,18,20Z'
      />
      <g transform='translate(22986 -498.174)'>
        <g className='c' transform='translate(-22977 509.174)'>
          <circle className='e' cx='3' cy='3' r='3' />
          <circle className='a' cx='3' cy='3' r='2.65' />
        </g>
        <path
          className='d'
          d='M0,.141l1.552.878L2.879,0'
          transform='translate(-22975.006 510.904) rotate(31)'
        />
      </g>
    </svg>
  )
}
