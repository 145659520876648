import axios from 'axios'

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL
  // baseURL: 'http://localhost:3333/api'
  // baseURL: 'https://api-gurumed.herokuapp.com/api'
})

api.interceptors.request.use(config => {
  const token = localStorage.getItem('token')

  const headers = { ...config.headers }

  if (token) {
    headers.Authorization = `Bearer ${token}`
  }

  return { ...config, headers }
})

export default api
