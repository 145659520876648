import React from 'react'

export function Edit(props) {
  return (
    <svg
      {...props}
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      width='24'
      height='24'
      viewBox='0 0 24 24'
    >
      <defs>
        <style>
          {'.edit-a{fill:none;}.edit-b{clip-path:url(#a);}.edit-c{fill:#fff;}'}
        </style>
        <clipPath id='a'>
          <rect className='edit-a' width='24' height='24' />
        </clipPath>
      </defs>
      <g className='edit-b'>
        <path className='edit-a' d='M0,0H24V24H0Z' />
        <path
          className='edit-c'
          d='M3,17.46V20.5a.5.5,0,0,0,.5.5H6.54a.469.469,0,0,0,.35-.15L17.81,9.94,14.06,6.19,3.15,17.1a.491.491,0,0,0-.15.36ZM20.71,7.04a1,1,0,0,0,0-1.41L18.37,3.29a1,1,0,0,0-1.41,0L15.13,5.12l3.75,3.75,1.83-1.83Z'
        />
      </g>
    </svg>
  )
}
