import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Route as ReactRoute, Redirect } from 'react-router-dom'

import Auth from 'templates/Auth'
import Default from 'templates/Default'

const Route = ({ isPrivate = false, children, ...props }) => {
  const { isAuthenticated } = useSelector(state => state.auth)
  const { to } = useSelector(state => state.user)

  return (
    <ReactRoute
      {...props}
      render={({ location }) => {
        if (isPrivate && isAuthenticated) {
          return <Default>{children}</Default>
        }

        if (!isPrivate && !isAuthenticated) {
          return <Auth>{children}</Auth>
        }

        return (
          <Redirect
            to={{
              pathname: isPrivate ? '/' : to || '/',
              state: { from: location }
            }}
          />
        )
      }}
    />
  )
}

Route.propTypes = {
  isPrivate: PropTypes.bool,
  children: PropTypes.node.isRequired
}

export default Route
