import Immutable from 'seamless-immutable'
import { createActions, createReducer } from 'reduxsauce'

const initialState = Immutable({
  // -> Profile
  profile: JSON.parse(localStorage.getItem('@gurumed:user')) || {},

  // -> Specialties
  specialties: [],

  // -> Locations state
  location: null,
  locations: [],
  selectedLocation: null,
  locationsSearch: null,
  deleteLocationModal: {
    isOpen: false,
    location: null
  },

  // -> Procedures state
  selectedProcedure: null,
  proceduresSearch: null,
  deleteProcedureModal: {
    isOpen: false,
    procedure: null
  },

  // -> Agreements state
  selectedAgreement: null,
  agreementsSearch: null,
  deleteAgreementModal: {
    isOpen: false,
    agreement: null
  },

  // -> Atuations state
  selectedAtuation: null,
  atuationsSearch: null,
  deleteAtuationModal: {
    isOpen: false,
    atuation: null
  },

  // -> Specializations state
  selectedSpecialization: null,
  specializationsSearch: null,
  deleteSpecializationModal: {
    isOpen: false,
    specialization: null
  },

  // -> Exams state
  selectedExam: null,
  examsSearch: null,
  deleteExamModal: {
    isOpen: false,
    exam: null
  },

  loading: {
    avatar: false,
    specialties: false,
    locations: false,
    location: false,
    actions: false
  }
})

const { Types, Creators } = createActions({
  // -> Profile
  updateProfessionalProfileRequest: ['id', 'profile'],
  updateProfessionalProfileSuccess: ['profile'],
  updateProfessionalProfileFailure: [],

  // -> Specialties
  getProfessionalSpecialtiesRequest: [],
  getProfessionalSpecialtiesSuccess: ['specialties'],
  getProfessionalSpecialtiesFailure: [],

  // -> Locations
  getProfessionalLocationRequest: ['name'],
  getProfessionalLocationSuccess: ['location'],
  getProfessionalLocationFailure: [],

  getProfessionalLocationsRequest: ['professional'],
  getProfessionalLocationsSuccess: ['locations'],
  getProfessionalLocationsFailure: [],

  createProfessionalLocationRequest: ['professional', 'location'],
  createProfessionalLocationSuccess: [],
  createProfessionalLocationFailure: [],

  updateProfessionalLocationRequest: [
    'professional',
    'clinic',
    'location',
    'profile'
  ],
  updateProfessionalLocationSuccess: [],
  updateProfessionalLocationFailure: [],

  deleteProfessionalLocationRequest: ['professional', 'location'],
  deleteProfessionalLocationSuccess: ['location'],
  deleteProfessionalLocationFailure: [],

  setProfessionalLocationsSearch: ['value'],

  setProfessionalLocation: ['location'],
  setSelectedProfessionalLocation: ['location'],

  setDeleteProfessionalLocationModal: ['location'],
  openDeleteProfessionalLocationModal: [],
  closeDeleteProfessionalLocationModal: [],

  // -> Procedures
  createProfessionalProcedureRequest: ['id', 'procedures'],
  createProfessionalProcedureSuccess: ['profile'],
  createProfessionalProcedureFailure: [],

  updateProfessionalProcedureRequest: ['id', 'procedures'],
  updateProfessionalProcedureSuccess: ['profile'],
  updateProfessionalProcedureFailure: [],

  deleteProfessionalProcedureRequest: ['id', 'procedures'],
  deleteProfessionalProcedureSuccess: ['profile'],
  deleteProfessionalProcedureFailure: [],

  setSelectedProfessionalProcedure: ['procedure'],

  setDeleteProfessionalProcedureModal: ['procedure'],
  openDeleteProfessionalProcedureModal: [],
  closeDeleteProfessionalProcedureModal: [],

  setProfessionalProceduresSearch: ['value'],

  // -> Agreements
  createProfessionalAgreementRequest: ['id', 'agreements'],
  createProfessionalAgreementSuccess: ['profile'],
  createProfessionalAgreementFailure: [],

  updateProfessionalAgreementRequest: ['id', 'agreements'],
  updateProfessionalAgreementSuccess: ['profile'],
  updateProfessionalAgreementFailure: [],

  deleteProfessionalAgreementRequest: ['id', 'agreements'],
  deleteProfessionalAgreementSuccess: ['profile'],
  deleteProfessionalAgreementFailure: [],

  setSelectedProfessionalAgreement: ['agreement'],

  setDeleteProfessionalAgreementModal: ['agreement'],
  openDeleteProfessionalAgreementModal: [],
  closeDeleteProfessionalAgreementModal: [],

  setProfessionalAgreementsSearch: ['value'],

  // -> Atuations
  createProfessionalAtuationRequest: ['id', 'atuations'],
  createProfessionalAtuationSuccess: ['profile'],
  createProfessionalAtuationFailure: [],

  updateProfessionalAtuationRequest: ['id', 'atuations'],
  updateProfessionalAtuationSuccess: ['profile'],
  updateProfessionalAtuationFailure: [],

  deleteProfessionalAtuationRequest: ['id', 'atuations'],
  deleteProfessionalAtuationSuccess: ['profile'],
  deleteProfessionalAtuationFailure: [],

  setSelectedProfessionalAtuation: ['atuation'],

  setDeleteProfessionalAtuationModal: ['atuation'],
  openDeleteProfessionalAtuationModal: [],
  closeDeleteProfessionalAtuationModal: [],

  setProfessionalAtuationsSearch: ['value'],

  // -> Exams
  createProfessionalExamRequest: ['id', 'exams'],
  createProfessionalExamSuccess: ['profile'],
  createProfessionalExamFailure: [],

  updateProfessionalExamRequest: ['id', 'exams'],
  updateProfessionalExamSuccess: ['profile'],
  updateProfessionalExamFailure: [],

  deleteProfessionalExamRequest: ['id', 'exams'],
  deleteProfessionalExamSuccess: ['profile'],
  deleteProfessionalExamFailure: [],

  setSelectedProfessionalExam: ['exam'],

  setDeleteProfessionalExamModal: ['exam'],
  openDeleteProfessionalExamModal: [],
  closeDeleteProfessionalExamModal: [],

  setProfessionalExamsSearch: ['value'],

  // -> Specializations
  createProfessionalSpecializationRequest: ['id', 'specializations'],
  createProfessionalSpecializationSuccess: ['profile'],
  createProfessionalSpecializationFailure: [],

  updateProfessionalSpecializationRequest: ['id', 'specializations'],
  updateProfessionalSpecializationSuccess: ['profile'],
  updateProfessionalSpecializationFailure: [],

  deleteProfessionalSpecializationRequest: ['id', 'specializations'],
  deleteProfessionalSpecializationSuccess: ['profile'],
  deleteProfessionalSpecializationFailure: [],

  setSelectedProfessionalSpecialization: ['specialization'],

  setDeleteProfessionalSpecializationModal: ['specialization'],
  openDeleteProfessionalSpecializationModal: [],
  closeDeleteProfessionalSpecializationModal: [],

  setProfessionalSpecializationsSearch: ['value'],

  // -> Settings
  updateProfessionalSettingsRequest: ['professional', 'configs'],
  updateProfessionalSettingsSuccess: ['profile'],
  updateProfessionalSettingsFailure: [],

  saveProfile: ['profile'],
  clearProfessionalProfileState: []
})

const professionalProfile = createReducer(initialState, {
  // -> Profile
  [Types.UPDATE_PROFESSIONAL_PROFILE_REQUEST]: state =>
    state.merge({ loading: { ...state.loading, actions: true } }),

  [Types.UPDATE_PROFESSIONAL_PROFILE_SUCCESS]: (state, { profile }) =>
    state.merge({
      profile: {
        ...state.profile,
        profissional: profile
      },
      loading: { ...state.loading, actions: false }
    }),

  [Types.UPDATE_PROFESSIONAL_PROFILE_FAILURE]: state =>
    state.merge({ loading: { ...state.loading, actions: false } }),

  // -> Specialties
  [Types.GET_PROFESSIONAL_SPECIALTIES_REQUEST]: state =>
    state.merge({
      loading: { ...state.loading, specialties: true }
    }),

  [Types.GET_PROFESSIONAL_SPECIALTIES_SUCCESS]: (state, { specialties }) =>
    state.merge({
      specialties,
      loading: { ...state.loading, specialties: true }
    }),

  [Types.GET_PROFESSIONAL_SPECIALTIES_FAILURE]: state =>
    state.merge({
      loading: { ...state.loading, specialties: false }
    }),

  // -> Locations
  [Types.GET_PROFESSIONAL_LOCATION_REQUEST]: state =>
    state.merge({
      loading: {
        ...state.loading,
        location: true
      }
    }),

  [Types.GET_PROFESSIONAL_LOCATION_SUCCESS]: (state, { location }) =>
    state.merge({
      location,
      loading: {
        ...state.loading,
        location: false
      }
    }),

  [Types.GET_PROFESSIONAL_LOCATION_FAILURE]: state =>
    state.merge({
      loading: {
        ...state.loading,
        location: false
      }
    }),

  [Types.GET_PROFESSIONAL_LOCATIONS_REQUEST]: state =>
    state.merge({
      loading: {
        ...state.loading,
        locations: true
      }
    }),

  [Types.GET_PROFESSIONAL_LOCATIONS_SUCCESS]: (state, { locations }) =>
    state.merge({
      locations,
      loading: {
        ...state.loading,
        locations: false
      }
    }),

  [Types.GET_PROFESSIONAL_LOCATIONS_FAILURE]: state =>
    state.merge({
      loading: {
        ...state.loading,
        locations: false
      }
    }),

  [Types.CREATE_PROFESSIONAL_LOCATION_REQUEST]: state =>
    state.merge({
      loading: {
        ...state.loading,
        actions: true
      }
    }),

  [Types.CREATE_PROFESSIONAL_LOCATION_SUCCESS]: state =>
    state.merge({
      loading: {
        ...state.loading,
        actions: false
      }
    }),

  [Types.CREATE_PROFESSIONAL_LOCATION_FAILURE]: state =>
    state.merge({
      loading: {
        ...state.loading,
        actions: false
      }
    }),

  [Types.UPDATE_PROFESSIONAL_LOCATION_REQUEST]: state =>
    state.merge({
      loading: {
        ...state.loading,
        actions: true
      }
    }),

  [Types.UPDATE_PROFESSIONAL_LOCATION_SUCCESS]: state =>
    state.merge({
      loading: {
        ...state.loading,
        actions: false
      }
    }),

  [Types.UPDATE_PROFESSIONAL_LOCATION_FAILURE]: state =>
    state.merge({
      loading: {
        ...state.loading,
        actions: false
      }
    }),

  [Types.DELETE_PROFESSIONAL_LOCATION_REQUEST]: state =>
    state.merge({
      loading: {
        ...state.loading,
        actions: true
      }
    }),

  [Types.DELETE_PROFESSIONAL_LOCATION_SUCCESS]: (state, { location }) =>
    state.merge({
      locations: state.locations.filter(clinic => clinic._id !== location),
      loading: {
        ...state.loading,
        actions: false
      }
    }),

  [Types.DELETE_PROFESSIONAL_LOCATION_FAILURE]: state =>
    state.merge({
      loading: {
        ...state.loading,
        actions: false
      }
    }),

  [Types.SET_PROFESSIONAL_LOCATIONS_SEARCH]: (state, { value }) =>
    state.merge({ locationsSearch: value }),

  [Types.SET_SELECTED_PROFESSIONAL_LOCATION]: (state, { location }) =>
    state.merge({
      selectedLocation: location
    }),

  [Types.SET_PROFESSIONAL_LOCATION]: (state, { location }) =>
    state.merge({
      location
    }),

  [Types.SET_DELETE_PROFESSIONAL_LOCATION_MODAL]: (state, { location }) =>
    state.merge({
      deleteLocationModal: { ...state.deleteLocationModal, location }
    }),

  [Types.OPEN_DELETE_PROFESSIONAL_LOCATION_MODAL]: state =>
    state.merge({
      deleteLocationModal: { ...state.deleteLocationModal, isOpen: true }
    }),

  [Types.CLOSE_DELETE_PROFESSIONAL_LOCATION_MODAL]: state =>
    state.merge({
      deleteLocationModal: { ...state.deleteLocationModal, isOpen: false }
    }),

  // -> Procedures
  [Types.CREATE_PROFESSIONAL_PROCEDURE_REQUEST]: state =>
    state.merge({
      loading: {
        ...state.loading,
        actions: true
      }
    }),

  [Types.CREATE_PROFESSIONAL_PROCEDURE_SUCCESS]: (state, { profile }) =>
    state.merge({
      selectedProcedure: null,
      profile: {
        ...state.profile,
        profissional: profile
      },
      loading: {
        ...state.loading,
        actions: false
      }
    }),

  [Types.CREATE_PROFESSIONAL_PROCEDURE_FAILURE]: state =>
    state.merge({
      loading: {
        ...state.loading,
        actions: false
      }
    }),

  [Types.UPDATE_PROFESSIONAL_PROCEDURE_REQUEST]: state =>
    state.merge({
      loading: {
        ...state.loading,
        actions: true
      }
    }),

  [Types.UPDATE_PROFESSIONAL_PROCEDURE_SUCCESS]: (state, { profile }) =>
    state.merge({
      selectedProcedure: null,
      profile: {
        ...state.profile,
        profissional: profile
      },
      loading: {
        ...state.loading,
        actions: false
      }
    }),

  [Types.UPDATE_PROFESSIONAL_PROCEDURE_FAILURE]: state =>
    state.merge({
      loading: {
        ...state.loading,
        actions: false
      }
    }),

  [Types.DELETE_PROFESSIONAL_PROCEDURE_REQUEST]: state =>
    state.merge({
      loading: {
        ...state.loading,
        actions: true
      }
    }),

  [Types.DELETE_PROFESSIONAL_PROCEDURE_SUCCESS]: (state, { profile }) =>
    state.merge({
      selectedProcedure: null,
      profile: {
        ...state.profile,
        profissional: profile
      },
      loading: {
        ...state.loading,
        actions: false
      }
    }),

  [Types.DELETE_PROFESSIONAL_PROCEDURE_FAILURE]: state =>
    state.merge({
      loading: {
        ...state.loading,
        actions: false
      }
    }),

  [Types.SET_DELETE_PROFESSIONAL_PROCEDURE_MODAL]: (state, { procedure }) =>
    state.merge({
      deleteProcedureModal: { ...state.deleteProcedureModal, procedure }
    }),

  [Types.OPEN_DELETE_PROFESSIONAL_PROCEDURE_MODAL]: state =>
    state.merge({
      deleteProcedureModal: { ...state.deleteProcedureModal, isOpen: true }
    }),

  [Types.CLOSE_DELETE_PROFESSIONAL_PROCEDURE_MODAL]: state =>
    state.merge({
      deleteProcedureModal: { ...state.deleteProcedureModal, isOpen: false }
    }),

  [Types.SET_SELECTED_PROFESSIONAL_PROCEDURE]: (state, { procedure }) =>
    state.merge({
      selectedProcedure: procedure
    }),

  [Types.SET_PROFESSIONAL_PROCEDURES_SEARCH]: (state, { value }) =>
    state.merge({ proceduresSearch: value }),

  // -> Agreements
  [Types.CREATE_PROFESSIONAL_AGREEMENT_REQUEST]: state =>
    state.merge({
      loading: {
        ...state.loading,
        actions: true
      }
    }),

  [Types.CREATE_PROFESSIONAL_AGREEMENT_SUCCESS]: (state, { profile }) =>
    state.merge({
      selectedAgreement: null,
      profile: {
        ...state.profile,
        profissional: profile
      },
      loading: {
        ...state.loading,
        actions: false
      }
    }),

  [Types.CREATE_PROFESSIONAL_AGREEMENT_FAILURE]: state =>
    state.merge({
      loading: {
        ...state.loading,
        actions: false
      }
    }),

  [Types.UPDATE_PROFESSIONAL_AGREEMENT_REQUEST]: state =>
    state.merge({
      loading: {
        ...state.loading,
        actions: true
      }
    }),

  [Types.UPDATE_PROFESSIONAL_AGREEMENT_SUCCESS]: (state, { profile }) =>
    state.merge({
      selectedAgreement: null,
      profile: {
        ...state.profile,
        profissional: profile
      },
      loading: {
        ...state.loading,
        actions: false
      }
    }),

  [Types.UPDATE_PROFESSIONAL_AGREEMENT_FAILURE]: state =>
    state.merge({
      loading: {
        ...state.loading,
        actions: false
      }
    }),

  [Types.DELETE_PROFESSIONAL_AGREEMENT_REQUEST]: state =>
    state.merge({
      loading: {
        ...state.loading,
        actions: true
      }
    }),

  [Types.DELETE_PROFESSIONAL_AGREEMENT_SUCCESS]: (state, { profile }) =>
    state.merge({
      selectedAgreement: null,
      profile: {
        ...state.profile,
        profissional: profile
      },
      loading: {
        ...state.loading,
        actions: false
      }
    }),

  [Types.DELETE_PROFESSIONAL_AGREEMENT_FAILURE]: state =>
    state.merge({
      loading: {
        ...state.loading,
        actions: false
      }
    }),

  [Types.SET_DELETE_PROFESSIONAL_AGREEMENT_MODAL]: (state, { agreement }) =>
    state.merge({
      deleteAgreementModal: { ...state.deleteAgreementModal, agreement }
    }),

  [Types.OPEN_DELETE_PROFESSIONAL_AGREEMENT_MODAL]: state =>
    state.merge({
      deleteAgreementModal: { ...state.deleteAgreementModal, isOpen: true }
    }),

  [Types.CLOSE_DELETE_PROFESSIONAL_AGREEMENT_MODAL]: state =>
    state.merge({
      deleteAgreementModal: { ...state.deleteAgreementModal, isOpen: false }
    }),

  [Types.SET_SELECTED_PROFESSIONAL_AGREEMENT]: (state, { agreement }) =>
    state.merge({
      selectedAgreement: agreement
    }),

  [Types.SET_PROFESSIONAL_AGREEMENTS_SEARCH]: (state, { value }) =>
    state.merge({ agreementsSearch: value }),

  // -> Atuations
  [Types.CREATE_PROFESSIONAL_ATUATION_REQUEST]: state =>
    state.merge({
      loading: {
        ...state.loading,
        actions: true
      }
    }),

  [Types.CREATE_PROFESSIONAL_ATUATION_SUCCESS]: (state, { profile }) =>
    state.merge({
      selectedAtuation: null,
      profile: {
        ...state.profile,
        profissional: profile
      },
      loading: {
        ...state.loading,
        actions: false
      }
    }),

  [Types.CREATE_PROFESSIONAL_ATUATION_FAILURE]: state =>
    state.merge({
      loading: {
        ...state.loading,
        actions: false
      }
    }),

  [Types.UPDATE_PROFESSIONAL_ATUATION_REQUEST]: state =>
    state.merge({
      loading: {
        ...state.loading,
        actions: true
      }
    }),

  [Types.UPDATE_PROFESSIONAL_ATUATION_SUCCESS]: (state, { profile }) =>
    state.merge({
      selectedAtuation: null,
      profile: {
        ...state.profile,
        profissional: profile
      },
      loading: {
        ...state.loading,
        actions: false
      }
    }),

  [Types.UPDATE_PROFESSIONAL_ATUATION_FAILURE]: state =>
    state.merge({
      loading: {
        ...state.loading,
        actions: false
      }
    }),

  [Types.DELETE_PROFESSIONAL_ATUATION_REQUEST]: state =>
    state.merge({
      loading: {
        ...state.loading,
        actions: true
      }
    }),

  [Types.DELETE_PROFESSIONAL_ATUATION_SUCCESS]: (state, { profile }) =>
    state.merge({
      selectedAtuation: null,
      profile: {
        ...state.profile,
        profissional: profile
      },
      loading: {
        ...state.loading,
        actions: false
      }
    }),

  [Types.DELETE_PROFESSIONAL_ATUATION_FAILURE]: state =>
    state.merge({
      loading: {
        ...state.loading,
        actions: false
      }
    }),

  [Types.SET_DELETE_PROFESSIONAL_ATUATION_MODAL]: (state, { atuation }) =>
    state.merge({
      deleteAtuationModal: { ...state.deleteAtuationModal, atuation }
    }),

  [Types.OPEN_DELETE_PROFESSIONAL_ATUATION_MODAL]: state =>
    state.merge({
      deleteAtuationModal: { ...state.deleteAtuationModal, isOpen: true }
    }),

  [Types.CLOSE_DELETE_PROFESSIONAL_ATUATION_MODAL]: state =>
    state.merge({
      deleteAtuationModal: { ...state.deleteAtuationModal, isOpen: false }
    }),

  [Types.SET_SELECTED_PROFESSIONAL_ATUATION]: (state, { atuation }) =>
    state.merge({
      selectedAtuation: atuation
    }),

  [Types.SET_PROFESSIONAL_ATUATIONS_SEARCH]: (state, { value }) =>
    state.merge({ atuationsSearch: value }),

  // -> Exams
  [Types.CREATE_PROFESSIONAL_EXAM_REQUEST]: state =>
    state.merge({
      loading: {
        ...state.loading,
        actions: true
      }
    }),

  [Types.CREATE_PROFESSIONAL_EXAM_SUCCESS]: (state, { profile }) =>
    state.merge({
      selectedExam: null,
      profile: {
        ...state.profile,
        profissional: profile
      },
      loading: {
        ...state.loading,
        actions: false
      }
    }),

  [Types.CREATE_PROFESSIONAL_EXAM_FAILURE]: state =>
    state.merge({
      loading: {
        ...state.loading,
        actions: false
      }
    }),

  [Types.UPDATE_PROFESSIONAL_EXAM_REQUEST]: state =>
    state.merge({
      loading: {
        ...state.loading,
        actions: true
      }
    }),

  [Types.UPDATE_PROFESSIONAL_EXAM_SUCCESS]: (state, { profile }) =>
    state.merge({
      selectedExam: null,
      profile: {
        ...state.profile,
        profissional: profile
      },
      loading: {
        ...state.loading,
        actions: false
      }
    }),

  [Types.UPDATE_PROFESSIONAL_EXAM_FAILURE]: state =>
    state.merge({
      loading: {
        ...state.loading,
        actions: false
      }
    }),

  [Types.DELETE_PROFESSIONAL_EXAM_REQUEST]: state =>
    state.merge({
      loading: {
        ...state.loading,
        modal: true
      }
    }),

  [Types.DELETE_PROFESSIONAL_EXAM_SUCCESS]: (state, { profile }) =>
    state.merge({
      selectedExam: null,
      profile: {
        ...state.profile,
        profissional: profile
      },
      loading: {
        ...state.loading,
        modal: false
      }
    }),

  [Types.DELETE_PROFESSIONAL_EXAM_FAILURE]: state =>
    state.merge({
      loading: {
        ...state.loading,
        modal: false
      }
    }),

  [Types.SET_DELETE_PROFESSIONAL_EXAM_MODAL]: (state, { exam }) =>
    state.merge({
      deleteExamModal: { ...state.deleteExamModal, exam }
    }),

  [Types.OPEN_DELETE_PROFESSIONAL_EXAM_MODAL]: state =>
    state.merge({
      deleteExamModal: { ...state.deleteExamModal, isOpen: true }
    }),

  [Types.CLOSE_DELETE_PROFESSIONAL_EXAM_MODAL]: state =>
    state.merge({
      deleteExamModal: { ...state.deleteExamModal, isOpen: false }
    }),

  [Types.SET_SELECTED_PROFESSIONAL_EXAM]: (state, { exam }) =>
    state.merge({
      selectedExam: exam
    }),

  [Types.SET_PROFESSIONAL_EXAMS_SEARCH]: (state, { value }) =>
    state.merge({ examsSearch: value }),

  // -> Specializations
  [Types.CREATE_PROFESSIONAL_SPECIALIZATION_REQUEST]: state =>
    state.merge({
      loading: {
        ...state.loading,
        actions: true
      }
    }),

  [Types.CREATE_PROFESSIONAL_SPECIALIZATION_SUCCESS]: (state, { profile }) =>
    state.merge({
      selectedSpecialization: null,
      profile: {
        ...state.profile,
        profissional: profile
      },
      loading: {
        ...state.loading,
        actions: false
      }
    }),

  [Types.CREATE_PROFESSIONAL_SPECIALIZATION_FAILURE]: state =>
    state.merge({
      loading: {
        ...state.loading,
        actions: false
      }
    }),

  [Types.UPDATE_PROFESSIONAL_SPECIALIZATION_REQUEST]: state =>
    state.merge({
      loading: {
        ...state.loading,
        actions: true
      }
    }),

  [Types.UPDATE_PROFESSIONAL_SPECIALIZATION_SUCCESS]: (state, { profile }) =>
    state.merge({
      selectedSpecialization: null,
      profile: {
        ...state.profile,
        profissional: profile
      },
      loading: {
        ...state.loading,
        actions: false
      }
    }),

  [Types.UPDATE_PROFESSIONAL_SPECIALIZATION_FAILURE]: state =>
    state.merge({
      loading: {
        ...state.loading,
        actions: false
      }
    }),

  [Types.DELETE_PROFESSIONAL_SPECIALIZATION_REQUEST]: state =>
    state.merge({
      loading: {
        ...state.loading,
        actions: true
      }
    }),

  [Types.DELETE_PROFESSIONAL_SPECIALIZATION_SUCCESS]: (state, { profile }) =>
    state.merge({
      selectedSpecialization: null,
      profile: {
        ...state.profile,
        profissional: profile
      },
      loading: {
        ...state.loading,
        actions: false
      }
    }),

  [Types.DELETE_PROFESSIONAL_SPECIALIZATION_FAILURE]: state =>
    state.merge({
      loading: {
        ...state.loading,
        actions: false
      }
    }),

  [Types.SET_DELETE_PROFESSIONAL_SPECIALIZATION_MODAL]: (
    state,
    { specialization }
  ) =>
    state.merge({
      deleteSpecializationModal: {
        ...state.deleteSpecializationModal,
        specialization
      }
    }),

  [Types.OPEN_DELETE_PROFESSIONAL_SPECIALIZATION_MODAL]: state =>
    state.merge({
      deleteSpecializationModal: {
        ...state.deleteSpecializationModal,
        isOpen: true
      }
    }),

  [Types.CLOSE_DELETE_PROFESSIONAL_SPECIALIZATION_MODAL]: state =>
    state.merge({
      deleteSpecializationModal: {
        ...state.deleteSpecializationModal,
        isOpen: false
      }
    }),

  [Types.SET_SELECTED_PROFESSIONAL_SPECIALIZATION]: (
    state,
    { specialization }
  ) =>
    state.merge({
      selectedSpecialization: specialization
    }),

  [Types.SET_PROFESSIONAL_SPECIALIZATIONS_SEARCH]: (state, { value }) =>
    state.merge({ specializationsSearch: value }),

  [Types.UPDATE_PROFESSIONAL_SETTINGS_REQUEST]: state =>
    state.merge({
      loading: {
        ...state.loading,
        actions: true
      }
    }),

  [Types.UPDATE_PROFESSIONAL_SETTINGS_SUCCESS]: (state, { profile }) =>
    state.merge({
      profile: {
        ...state.profile,
        profissional: profile
      },
      loading: {
        ...state.loading,
        actions: false
      }
    }),

  [Types.UPDATE_PROFESSIONAL_SETTINGS_FAILURE]: state =>
    state.merge({
      loading: {
        ...state.loading,
        actions: false
      }
    }),

  [Types.SAVE_PROFILE]: (state, { profile }) => state.merge({ profile }),

  [Types.CLEAR_PROFESSIONAL_PROFILE_STATE]: _ => initialState
})

export const ProfessionalProfileTypes = Types
export const ProfessionalProfileActions = Creators

export default professionalProfile
