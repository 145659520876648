import React from 'react'
import PropTypes from 'prop-types'

import Header from './Header'
import Nav from './Nav'

import { Container, Wrapper, Content } from './styled'

function Default({ children }) {
  return (
    <Container>
      <Header />

      <Wrapper>
        <Content>
          <Nav />

          {children}
        </Content>
      </Wrapper>
    </Container>
  )
}

Default.propTypes = {
  children: PropTypes.node.isRequired
}

export default Default
