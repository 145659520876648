import { takeLatest, call, put, all } from 'redux-saga/effects'
// import { push } from 'connected-react-router'
import { toast } from 'react-toastify'

import api from 'services/api'

import {
  ClinicProfileTypes,
  ClinicProfileActions
} from '../ducks/clinicProfile'
import { UserActions } from '../ducks/user'

function* mergeProfiles(clinic) {
  try {
    const currentClinic = JSON.parse(localStorage.getItem('@gurumed:user'))

    const updatedClinic = {
      ...currentClinic,
      clinica: {
        ...clinic
      }
    }

    yield put(UserActions.saveUser(updatedClinic))

    localStorage.setItem('@gurumed:user', JSON.stringify(updatedClinic))
  } catch (error) {
    console.error(error)
  }
}

function* updateProfilesImages(data) {
  try {
    const currentClinic = JSON.parse(localStorage.getItem('@gurumed:user'))

    const updatedClinic = {
      ...currentClinic,
      clinica: {
        ...currentClinic.clinica,
        images: [...currentClinic.clinica.images, data]
      }
    }

    yield put(UserActions.saveUser(updatedClinic))

    localStorage.setItem('@gurumed:user', JSON.stringify(updatedClinic))
  } catch (error) {
    console.error(error)
  }
}

function* updateClinicProfile({ id, data }) {
  try {
    const {
      data: { data: clinic }
    } = yield call(api.put, `clinics/${id}`, data)

    yield put(ClinicProfileActions.updateClinicProfileSuccess(clinic))

    yield call(mergeProfiles, clinic)

    toast.success('Perfil atualizado com sucesso!')
  } catch (error) {
    yield put(ClinicProfileActions.updateClinicProfileFailure())

    toast.error('Falha ao atualizar perfil, tente novamente!')
  }
}

function* uploadClinicImage({ clinic, image }) {
  try {
    const {
      data: { data }
    } = yield call(api.post, `clinics/${clinic}/images`, {
      image: [image]
    })

    yield put(ClinicProfileActions.uploadClinicImageSuccess(data))

    yield call(updateProfilesImages, data)
  } catch (error) {
    yield put(ClinicProfileActions.uploadClinicImageFailure())

    toast.error('Falha ao enviar imagem, tente novamente!')
  }
}

// -> Specialties
function* createSpecialty({ id, specialties }) {
  try {
    const {
      data: { data: profile }
    } = yield call(api.put, `/clinics/${id}`, {
      specialties
    })

    yield put(ClinicProfileActions.createClinicSpecialtySuccess(profile))

    yield call(mergeProfiles, profile)

    toast.success('Especialidade cadastrada com sucesso!')
  } catch (error) {
    yield put(ClinicProfileActions.createClinicSpecialtyFailure())

    toast.error('Falha ao cadastrar especialidade, tente novamente!')
  }
}

function* updateSpecialty({ id, specialties }) {
  try {
    const {
      data: { data: profile }
    } = yield call(api.put, `/clinics/${id}`, {
      specialties
    })

    yield put(ClinicProfileActions.updateClinicSpecialtySuccess(profile))

    yield call(mergeProfiles, profile)

    toast.success('Especialidade atualizada com sucesso!')
  } catch (error) {
    yield put(ClinicProfileActions.updateClinicSpecialtyFailure())

    toast.error('Falha ao atualizar especialidade, tente novamente!')
  }
}

function* deleteSpecialty({ id, specialties }) {
  try {
    const {
      data: { data: profile }
    } = yield call(api.put, `/clinics/${id}`, {
      specialties
    })

    yield put(ClinicProfileActions.deleteClinicSpecialtySuccess(profile))
    yield put(ClinicProfileActions.closeDeleteClinicSpecialtyModal())

    yield call(mergeProfiles, profile)

    toast.success('Especialidade removida com sucesso!')
  } catch (error) {
    yield put(ClinicProfileActions.deleteClinicSpecialtyFailure())

    toast.error('Falha ao remover especialidade, tente novamente!')
  }
}

// -> Procedures
function* createProcedure({ id, procedures }) {
  try {
    const {
      data: { data: profile }
    } = yield call(api.put, `/clinics/${id}`, {
      procedures
    })

    yield put(ClinicProfileActions.createClinicProcedureSuccess(profile))

    yield call(mergeProfiles, profile)

    toast.success('Procedimento cadastrado com sucesso!')
  } catch (error) {
    yield put(ClinicProfileActions.createClinicProcedureFailure())

    toast.error('Falha ao cadastrar procedimento, tente novamente!')
  }
}

function* updateProcedure({ id, procedures }) {
  try {
    const {
      data: { data: profile }
    } = yield call(api.put, `/clinics/${id}`, {
      procedures
    })

    yield put(ClinicProfileActions.updateClinicProcedureSuccess(profile))

    yield call(mergeProfiles, profile)

    toast.success('Procedimento atualizado com sucesso!')
  } catch (error) {
    yield put(ClinicProfileActions.updateClinicProcedureFailure())

    toast.error('Falha ao atualizar procedimento, tente novamente!')
  }
}

function* deleteProcedure({ id, procedures }) {
  try {
    const {
      data: { data: profile }
    } = yield call(api.put, `/clinics/${id}`, {
      procedures
    })

    yield put(ClinicProfileActions.deleteClinicProcedureSuccess(profile))
    yield put(ClinicProfileActions.closeDeleteClinicProcedureModal())

    yield call(mergeProfiles, profile)

    toast.success('Procedimento removido com sucesso!')
  } catch (error) {
    yield put(ClinicProfileActions.deleteClinicProcedureFailure())

    toast.error('Falha ao remover procedimento, tente novamente!')
  }
}

// -> Exams
function* createExam({ id, exams }) {
  try {
    const {
      data: { data: profile }
    } = yield call(api.put, `/clinics/${id}`, {
      exams
    })

    yield put(ClinicProfileActions.createClinicExamSuccess(profile))

    yield call(mergeProfiles, profile)

    toast.success('Exame cadastrado com sucesso!')
  } catch (error) {
    yield put(ClinicProfileActions.createClinicExamFailure())

    toast.error('Falha ao cadastrar exame, tente novamente!')
  }
}

function* updateExam({ id, exams }) {
  try {
    const {
      data: { data: profile }
    } = yield call(api.put, `/clinics/${id}`, {
      exams
    })

    yield put(ClinicProfileActions.updateClinicExamSuccess(profile))

    yield call(mergeProfiles, profile)

    toast.success('Exame atualizado com sucesso!')
  } catch (error) {
    yield put(ClinicProfileActions.updateClinicExamFailure())

    toast.error('Falha ao atualizar exame, tente novamente!')
  }
}

function* deleteExam({ id, exams }) {
  try {
    const {
      data: { data: profile }
    } = yield call(api.put, `/clinics/${id}`, {
      exams
    })

    yield put(ClinicProfileActions.deleteClinicExamSuccess(profile))
    yield put(ClinicProfileActions.closeDeleteClinicExamModal())

    yield call(mergeProfiles, profile)

    toast.success('Exame removido com sucesso!')
  } catch (error) {
    yield put(ClinicProfileActions.deleteClinicExamFailure())

    toast.error('Falha ao remover exame, tente novamente!')
  }
}

function* updateSettings({ clinic, configs }) {
  try {
    const {
      data: { data: profile }
    } = yield call(api.put, `/clinics/${clinic}`, {
      configs
    })

    yield put(ClinicProfileActions.updateClinicSettingsSuccess(profile))

    yield call(mergeProfiles, profile)

    toast.success('Configurações atualizadas com sucesso!')
  } catch (error) {
    yield put(ClinicProfileActions.updateClinicSettingsFailure())

    toast.error('Falha ao atualizar configurações, tente novamente!')
  }
}

export default all([
  takeLatest(
    ClinicProfileTypes.UPDATE_CLINIC_PROFILE_REQUEST,
    updateClinicProfile
  ),
  takeLatest(ClinicProfileTypes.UPLOAD_CLINIC_IMAGE_REQUEST, uploadClinicImage),

  // -> Specialties
  takeLatest(
    ClinicProfileTypes.CREATE_CLINIC_SPECIALTY_REQUEST,
    createSpecialty
  ),
  takeLatest(
    ClinicProfileTypes.UPDATE_CLINIC_SPECIALTY_REQUEST,
    updateSpecialty
  ),
  takeLatest(
    ClinicProfileTypes.DELETE_CLINIC_SPECIALTY_REQUEST,
    deleteSpecialty
  ),

  // -> Procedures
  takeLatest(
    ClinicProfileTypes.CREATE_CLINIC_PROCEDURE_REQUEST,
    createProcedure
  ),
  takeLatest(
    ClinicProfileTypes.UPDATE_CLINIC_PROCEDURE_REQUEST,
    updateProcedure
  ),
  takeLatest(
    ClinicProfileTypes.DELETE_CLINIC_PROCEDURE_REQUEST,
    deleteProcedure
  ),

  // -> Exams
  takeLatest(ClinicProfileTypes.CREATE_CLINIC_EXAM_REQUEST, createExam),
  takeLatest(ClinicProfileTypes.UPDATE_CLINIC_EXAM_REQUEST, updateExam),
  takeLatest(ClinicProfileTypes.DELETE_CLINIC_EXAM_REQUEST, deleteExam),

  // -> Settings
  takeLatest(ClinicProfileTypes.UPDATE_CLINIC_SETTINGS_REQUEST, updateSettings)
])
