import styled from 'styled-components/macro'
import { theme } from 'styled-tools'

export const Container = styled.main``

export const Wrapper = styled.div`
  width: 100%;
  max-width: 1444px;
  margin-left: auto;
  margin-right: auto;
  padding: 38px ${theme('spacing.default')};
`

export const Content = styled.div`
  display: flex;
`
