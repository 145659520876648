import React from 'react'

import { Container, Animation } from './styled'

export default function Loading() {
  return (
    <Container>
      <Animation />
    </Container>
  )
}
