import { createGlobalStyle } from 'styled-components/macro'
import normalize from 'styled-normalize'
import 'react-toastify/dist/ReactToastify.min.css'

const GlobalStyle = createGlobalStyle`
  ${normalize}

  *,
  *::before,
  *::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html,
  body,
  #root {
    height: 100%;
  }

  body {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    line-height: 1.5;
    color: #494949;
  }

  ul {
    list-style: none;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin-top: 0;
    margin-bottom: 0;
  }

  input,
  textarea,
  button {
    font-family: inherit;
    letter-spacing: inherit;
  }

  img {
    display: block;
    max-width: 100%;
    height: auto;
  }
`

export default GlobalStyle
