import styled, { css } from 'styled-components/macro'
import { theme, prop, ifProp, switchProp } from 'styled-tools'
import MaskedInput from 'react-text-mask'

export const Container = styled.div`
  position: relative;
  margin-bottom: ${prop('mb')};
`

export const Wrapper = styled.label`
  display: flex;
  flex-direction: column;

  ${switchProp('variant', {
    inline: css`
      flex-direction: row;
      align-items: center;
    `
  })}
`

export const Label = styled.span`
  font-weight: 500;
  margin-bottom: ${theme('spacing.xs')};

  ${switchProp('variant', {
    highlighted: css`
      font-weight: 700;
      color: ${theme('color.lightGreen')};
    `,

    primary: css`
      color: ${theme('color.text')};
    `,

    secondary: css`
      color: ${theme('color.white')};
    `,

    inline: css`
      width: 72px;
    `
  })}
`

export const Masked = styled(MaskedInput)`
  width: ${prop('width')};
  padding-left: ${theme('spacing.default')};
  padding-right: ${theme('spacing.default')};
  color: ${theme('color.text')};
  background-color: ${theme('color.white')};
  border-radius: ${theme('radius.default')};
  appearance: none;

  &:focus {
    outline: none;

    ${ifProp(
      'error',
      css`
        border: ${theme('border.danger')};
      `,
      null
    )}
  }

  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }

  &::placeholder {
    color: ${theme('color.grey')};
  }

  ${switchProp('variant', {
    highlighted: css`
      border: ${theme('border.secondary')};

      &:focus {
        border: ${theme('border.primary')};
      }
    `,

    primary: css`
      border: ${theme('border.secondary')};

      &:focus {
        border: ${theme('border.primary')};
      }
    `,

    secondary: css`
      border: none;
    `,

    inline: css`
      height: 40px;
      border: ${theme('border.secondary')};

      &:focus {
        border: ${theme('border.primary')};
      }
    `
  })};

  ${switchProp('size', {
    sm: css`
      height: 40px;
    `,

    default: css`
      height: 50px;
    `,

    md: css`
      height: 60px;
    `
  })}

  ${ifProp(
    'error',
    css`
      border: ${theme('border.danger')};
    `,
    null
  )}

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }
`

export const Control = styled.input`
  width: ${prop('width')};
  height: 50px;
  padding-left: ${theme('spacing.default')};
  padding-right: ${theme('spacing.default')};
  color: ${theme('color.text')};
  background-color: ${theme('color.white')};
  border-radius: ${theme('radius.default')};

  &:focus {
    outline: none;

    ${ifProp(
      'error',
      css`
        border: ${theme('border.danger')};
      `,
      null
    )}
  }

  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }

  &::placeholder {
    color: ${theme('color.grey')};
  }

  ${switchProp('variant', {
    highlighted: css`
      border: ${theme('border.secondary')};

      &:focus {
        border: ${theme('border.primary')};
      }
    `,

    primary: css`
      border: ${theme('border.secondary')};

      &:focus {
        border: ${theme('border.primary')};
      }
    `,

    secondary: css`
      border: none;
    `,

    inline: css`
      height: 40px;
      border: ${theme('border.secondary')};

      &:focus {
        border: ${theme('border.primary')};
      }
    `
  })};

  ${switchProp('size', {
    sm: css`
      height: 40px;
    `,

    default: css`
      height: 50px;
    `,

    md: css`
      height: 60px;
    `
  })}

  ${ifProp(
    'error',
    css`
      border: ${theme('border.danger')};
    `,
    null
  )}

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }
`

export const Toggle = styled.button`
  position: absolute;
  top: 40px;
  right: 16px;
  width: 24px;
  height: 24px;
  background-color: transparent;
  border: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`

export const Error = styled.p`
  position: absolute;
  top: 100%;
  left: 0;
  margin-top: ${theme('spacing.xs')};
  font-size: 0.875rem;
  color: ${theme('color.red')};
`
