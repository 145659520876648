import { takeLatest, call, put, all } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import {
  format,
  setHours,
  setMinutes,
  setSeconds,
  parseISO,
  isPast,
  isAfter,
  addDays
} from 'date-fns'
import { formatToPhone } from 'brazilian-values'

import {
  AppointmentsTypes,
  AppointmentsActions
} from 'store/ducks/appointments'

import api from 'services/api'

// import { capitalize } from 'utils/string'

import avatar from 'images/avatar.jpg'

function* getAppointments({ context, id, filter }) {
  try {
    const { page = 1, name = null, from, to } = filter

    const types = {
      clinic: `/clinics/${id}/appointments?page=${page}&name=${name}&dateBegin=${from}&dateEnd=${to}`,
      laboratory: `/laboratories/${id}/appointments?page=${page}&name=${name}&dateBegin=${from}&dateEnd=${to}`,
      professional: `/professionals/${id}/appointments?page=${page}&name=${name}&dateBegin=${from}&dateEnd=${to}`
    }

    const { data, headers } = yield call(api.get, types[context])

    const appointments = data.map(appointment => {
      const {
        unit,
        professional,
        patient,
        time,
        date,
        // shift,
        confirmedAt,
        canceledAt,
        attendConfirmedAt,
        createdAt
      } = appointment

      let parsedDate = null
      let formattedDate = null

      if (time) {
        const [hour, minute] = time.split(':')
        parsedDate = addDays(
          setHours(setMinutes(parseISO(date), minute), hour),
          1
        )
        formattedDate = format(parsedDate, "dd/MM/yyyy, 'às' HH:mm")
      }

      // if (shift) {
      //   // parsedDate = addDays(parseISO(date), 1)
      //   formattedDate = `${format(
      //     date,
      //     'dd/MM/yyyy'
      //   )}, pela ${capitalize(shift)}`
      // }

      const parsedCreatedAtDate = createdAt ? parseISO(createdAt) : null

      const formattedCreatedAtDate =
        parsedCreatedAtDate &&
        format(parsedCreatedAtDate, "dd/MM/yyyy, 'às' HH:mm")

      const parsedConfirmedDate = confirmedAt ? parseISO(confirmedAt) : null

      const formattedConfirmedDate =
        parsedConfirmedDate &&
        format(parsedConfirmedDate, "dd/MM/yyyy, 'às' HH:mm")

      const parsedCanceledDate = canceledAt ? parseISO(canceledAt) : null

      const formattedCanceledDate =
        parsedCanceledDate &&
        format(parsedCanceledDate, "dd/MM/yyyy, 'às' HH:mm")

      const parsedAttendConfirmedDate = attendConfirmedAt
        ? parseISO(attendConfirmedAt)
        : null

      const formattedAttendConfirmedDate =
        parsedAttendConfirmedDate &&
        format(parsedAttendConfirmedDate, "dd/MM/yyyy, 'às' HH:mm")

      // const shouldDisable =
      //   isToday(parseISO(date)) || isAfter(parseISO(date), new Date())

      const finishDate = setSeconds(
        setMinutes(setHours(parseISO(date), 16), 0),
        0
      )

      const shouldDisable = isAfter(finishDate, new Date())

      return {
        ...appointment,
        formattedCreatedAtDate,
        // formattedDate,
        formattedConfirmedDate,
        formattedCanceledDate,
        formattedAttendConfirmedDate,
        isPast: isPast(parsedDate),
        shouldDisable,
        unit: {
          ...unit,
          image: unit?.image ?? avatar
        },
        patient: {
          ...patient,
          image: patient?.image ?? avatar,
          phone: patient.phone && formatToPhone(patient.phone)
        },
        professional: {
          ...professional,
          image: professional?.image ?? avatar
        }
      }
    })

    const details = {
      dates:
        from && to
          ? `${from.replace(/-/g, '/')} a ${to.replace(/-/g, '/')}`
          : null,
      total: headers['x-total-appointments'],
      confirmed: headers['x-total-appointments-confirmed'],
      realized: headers['x-total-appointments-accomplished'],
      not_realized: headers['x-total-appointments-not-accomplished']
    }

    yield put(AppointmentsActions.getAppointmentsSuccess(appointments, details))
  } catch (error) {
    yield put(AppointmentsActions.getAppointmentsFailure())
    console.log(error, 'error')
    toast.error('Falha ao buscar agendamentos, tente novamente!')
  }
}

function* confirmAppointment({ confirmedBy, appointment }) {
  try {
    const { data } = yield call(api.put, `/appointments/${appointment}`, {
      confirmedBy,
      confirmedAt: new Date(),
      status: 'Confirmado'
    })

    const { confirmedAt } = data

    const parsedConfirmedDate = confirmedAt ? parseISO(confirmedAt) : null

    const formattedConfirmedDate =
      parsedConfirmedDate &&
      format(parsedConfirmedDate, "dd/MM/yyyy, 'às' HH:mm")

    const confimedAppointment = {
      ...data,
      formattedConfirmedDate
    }

    yield put(
      AppointmentsActions.confirmAppointmentSuccess(confimedAppointment)
    )
    yield put(
      AppointmentsActions.setAppointmentsModal({
        isOpen: false,
        type: null,
        appointment: null
      })
    )

    toast.success('Agendamento confirmado com sucesso!')
  } catch (error) {
    yield put(AppointmentsActions.confirmAppointmentFailure())

    toast.error('Falha ao confirmar agendamento, tente novamente!')
  }
}

function* cancelAppointment({
  justification,
  canceledBy,
  canceledByType,
  appointment
}) {
  try {
    const { data } = yield call(api.put, `/appointments/${appointment}`, {
      justification,
      canceledBy,
      canceledByType,
      canceledAt: new Date(),
      status: 'Cancelado'
    })

    const { canceledAt } = data

    const parsedCanceledDate = canceledAt ? parseISO(canceledAt) : null

    const formattedCanceledDate =
      parsedCanceledDate && format(parsedCanceledDate, "dd/MM/yyyy, 'às' HH:mm")

    const canceledAppointment = {
      ...data,
      formattedCanceledDate
    }

    yield put(AppointmentsActions.cancelAppointmentSuccess(canceledAppointment))
    yield put(
      AppointmentsActions.setAppointmentsModal({
        isOpen: false,
        type: null,
        appointment: null
      })
    )

    toast.success('Agendamento cancelado com sucesso!')
  } catch (error) {
    yield put(AppointmentsActions.cancelAppointmentFailure())

    toast.error('Falha ao cancelar agendamento, tente novamente!')
  }
}

function* attendAppointment({ attendConfirmedBy, appointment }) {
  try {
    const { data } = yield call(api.put, `/appointments/${appointment}`, {
      attendConfirmedBy,
      attendConfirmedAt: new Date(),
      status: 'Realizado'
    })

    const { attendConfirmedAt } = data

    const parsedAttendConfirmedDate = attendConfirmedAt
      ? parseISO(attendConfirmedAt)
      : null

    const formattedAttendConfirmedDate =
      parsedAttendConfirmedDate &&
      format(parsedAttendConfirmedDate, "dd/MM/yyyy, 'às' HH:mm")

    const attendedAppointment = {
      ...data,
      formattedAttendConfirmedDate
    }

    yield put(AppointmentsActions.attendAppointmentSuccess(attendedAppointment))
    yield put(
      AppointmentsActions.setAppointmentsModal({
        isOpen: false,
        type: null,
        appointment: null
      })
    )

    toast.success('Atendimento confirmado com sucesso!')
  } catch (error) {
    yield put(AppointmentsActions.attendAppointmentFailure())

    toast.error('Falha ao confirmar Atendimento, tente novamente!')
  }
}

function* notAttendAppointment({ appointment }) {
  try {
    const { data } = yield call(api.put, `/appointments/${appointment}`, {
      status: 'Não realizado'
    })

    const notAttendedAppointment = {
      ...data
    }

    yield put(
      AppointmentsActions.notAttendAppointmentSuccess(notAttendedAppointment)
    )
    yield put(
      AppointmentsActions.setAppointmentsModal({
        isOpen: false,
        type: null,
        appointment: null
      })
    )

    toast.success('Atendimento não confirmado atualizado com sucesso!')
  } catch (error) {
    yield put(AppointmentsActions.notAttendAppointmentSuccess())

    toast.error('Falha ao não confirmar atendimento, tente novamente!')
  }
}

export default all([
  takeLatest(AppointmentsTypes.GET_APPOINTMENTS_REQUEST, getAppointments),
  takeLatest(AppointmentsTypes.CONFIRM_APPOINTMENT_REQUEST, confirmAppointment),
  takeLatest(AppointmentsTypes.CANCEL_APPOINTMENT_REQUEST, cancelAppointment),
  takeLatest(AppointmentsTypes.ATTEND_APPOINTMENT_REQUEST, attendAppointment),
  takeLatest(
    AppointmentsTypes.NOT_ATTEND_APPOINTMENT_REQUEST,
    notAttendAppointment
  )
])
