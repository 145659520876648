import { takeLatest, call, put, all } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import { push } from 'connected-react-router'

import api from 'services/api'

import {
  ProfessionalProfileTypes,
  ProfessionalProfileActions
} from '../ducks/professionalProfile'
import { UserActions } from '../ducks/user'

function* mergeProfiles(professional) {
  try {
    const currentProfessional = JSON.parse(
      localStorage.getItem('@gurumed:user')
    )

    const updatedProfessional = {
      ...currentProfessional,
      profissional: {
        ...professional
      }
    }

    yield put(UserActions.saveUser(updatedProfessional))

    localStorage.setItem('@gurumed:user', JSON.stringify(updatedProfessional))
  } catch (error) {
    console.error(error)
  }
}

function* updateProfile({ id, profile }) {
  try {
    const {
      data: { data: professional }
    } = yield call(api.put, `professionals/${id}`, profile)

    yield put(
      ProfessionalProfileActions.updateProfessionalProfileSuccess(professional)
    )

    yield call(mergeProfiles, professional)

    toast.success('Perfil atualizado com sucesso!')
  } catch (error) {
    yield put(ProfessionalProfileActions.updateProfessionalProfileFailure())

    toast.error('Falha ao atualizar perfil, tente novamente!')
  }
}

function* getSpecialties() {
  try {
    const { data } = yield call(api.get, '/specialties')

    const specialties = data.map(specialty => ({
      id: specialty._id,
      label: specialty.title,
      value: specialty.title
    }))

    yield put(
      ProfessionalProfileActions.getProfessionalSpecialtiesSuccess(specialties)
    )
  } catch (error) {
    yield put(ProfessionalProfileActions.getProfessionalSpecialtiesFailure())
  }
}

// -> Locations
function* getLocation({ name }) {
  try {
    const { data } = yield call(api.get, `/clinics/name/${name}`)

    yield put(ProfessionalProfileActions.getProfessionalLocationSuccess(data))
  } catch (error) {
    yield put(ProfessionalProfileActions.getProfessionalLocationFailure())
  }
}

function* getLocations({ professional }) {
  try {
    const { data } = yield call(
      api.get,
      `/professionals/${professional}/clinicas`
    )

    const locations = data[0].clinicas

    yield put(
      ProfessionalProfileActions.getProfessionalLocationsSuccess(locations)
    )
  } catch (error) {
    yield put(ProfessionalProfileActions.getProfessionalLocationsFailure())

    toast.error('Falha ao buscar locais de atendimento, tente novamente!')
  }
}

function* createLocation({ professional, location }) {
  try {
    const data = {
      name: location.name,
      address: location.address
    }

    const {
      data: { clinic: clinicData }
    } = yield call(api.post, 'clinics/out/true', data)

    const professionalLocation = {
      profile: clinicData?.[0]?._id ?? clinicData?._id,
      ...location
    }

    yield call(
      api.put,
      `/professionals/${professional}/clinica`,
      professionalLocation
    )

    yield put(ProfessionalProfileActions.createProfessionalLocationSuccess())

    toast.success('Local de atendimento cadastrado com sucesso!')

    yield put(push('/professional/profile/locations'))
  } catch (error) {
    yield put(ProfessionalProfileActions.createProfessionalLocationFailure())

    toast.error('Falha ao cadastrar local de atendimento, tente novamente!')
  }
}

function* updateLocation({ professional, clinic, location, profile }) {
  try {
    yield call(api.put, `/professionals/${professional}/clinica`, {
      _id: clinic,
      ...location,
      profile
    })

    yield put(ProfessionalProfileActions.updateProfessionalLocationSuccess())

    toast.success('Local de atendimento atualizado com sucesso!')

    yield put(push('/professional/profile/locations'))
  } catch (error) {
    yield put(ProfessionalProfileActions.updateProfessionalLocationFailure())

    toast.error('Falha ao atualizar local de atendimento, tente novamente!')
  }
}

function* deleteLocation({ professional, location }) {
  try {
    yield call(api.delete, `/professionals/${professional}/clinica`, {
      data: {
        clinica_id: location
      }
    })

    yield put(
      ProfessionalProfileActions.deleteProfessionalLocationSuccess(location)
    )
    yield put(ProfessionalProfileActions.closeDeleteProfessionalLocationModal())

    toast.success('Local de atendimento removido com sucesso!')
  } catch (error) {
    yield put(ProfessionalProfileActions.deleteProfessionalProcedureFailure())

    toast.error('Falha ao remover local de atendimento, tente novamente!')
  }
}

// -> Procedures
function* createProcedure({ id, procedures }) {
  try {
    const {
      data: { data: profile }
    } = yield call(api.put, `/professionals/${id}`, {
      procedures
    })

    yield put(
      ProfessionalProfileActions.createProfessionalProcedureSuccess(profile)
    )

    yield call(mergeProfiles, profile)

    toast.success('Procedimento cadastrado com sucesso!')
  } catch (error) {
    yield put(ProfessionalProfileActions.createProfessionalProcedureFailure())

    toast.error('Falha ao cadastrar procedimento, tente novamente!')
  }
}

function* updateProcedure({ id, procedures }) {
  try {
    const {
      data: { data: profile }
    } = yield call(api.put, `/professionals/${id}`, {
      procedures
    })

    yield put(
      ProfessionalProfileActions.updateProfessionalProcedureSuccess(profile)
    )

    yield call(mergeProfiles, profile)

    toast.success('Procedimento atualizado com sucesso!')
  } catch (error) {
    yield put(ProfessionalProfileActions.updateProfessionalProcedureFailure())

    toast.error('Falha ao atualizar procedimento, tente novamente!')
  }
}

function* deleteProcedure({ id, procedures }) {
  try {
    const {
      data: { data: profile }
    } = yield call(api.put, `/professionals/${id}`, {
      procedures
    })

    yield put(
      ProfessionalProfileActions.deleteProfessionalProcedureSuccess(profile)
    )
    yield put(
      ProfessionalProfileActions.closeDeleteProfessionalProcedureModal()
    )

    yield call(mergeProfiles, profile)

    toast.success('Procedimento removido com sucesso!')
  } catch (error) {
    yield put(ProfessionalProfileActions.deleteProfessionalProcedureFailure())

    toast.error('Falha ao remover procedimento, tente novamente!')
  }
}

// -> Agreements
function* createAgreement({ id, agreements }) {
  try {
    const {
      data: { data: profile }
    } = yield call(api.put, `/professionals/${id}`, {
      agreements
    })

    yield put(
      ProfessionalProfileActions.createProfessionalAgreementSuccess(profile)
    )

    yield call(mergeProfiles, profile)

    toast.success('Convênio cadastrado com sucesso!')
  } catch (error) {
    yield put(ProfessionalProfileActions.createProfessionalAgreementFailure())

    toast.error('Falha ao cadastrar convênio, tente novamente!')
  }
}

function* updateAgreement({ id, agreements }) {
  try {
    const {
      data: { data: profile }
    } = yield call(api.put, `/professionals/${id}`, {
      agreements
    })

    yield put(
      ProfessionalProfileActions.updateProfessionalAgreementSuccess(profile)
    )

    yield call(mergeProfiles, profile)

    toast.success('Convênio atualizado com sucesso!')
  } catch (error) {
    yield put(ProfessionalProfileActions.updateProfessionalAgreementFailure())

    toast.error('Falha ao atualizar convênio, tente novamente!')
  }
}

function* deleteAgreement({ id, agreements }) {
  try {
    const {
      data: { data: profile }
    } = yield call(api.put, `/professionals/${id}`, {
      agreements
    })

    yield put(
      ProfessionalProfileActions.deleteProfessionalAgreementSuccess(profile)
    )
    yield put(
      ProfessionalProfileActions.closeDeleteProfessionalAgreementModal()
    )

    yield call(mergeProfiles, profile)

    toast.success('Convênio removido com sucesso!')
  } catch (error) {
    yield put(ProfessionalProfileActions.deleteProfessionalAgreementFailure())

    toast.error('Falha ao remover convênio, tente novamente!')
  }
}

// -> Atuations
function* createAtuation({ id, atuations }) {
  try {
    const {
      data: { data: profile }
    } = yield call(api.put, `/professionals/${id}`, {
      atuations
    })

    yield put(
      ProfessionalProfileActions.createProfessionalAtuationSuccess(profile)
    )

    yield call(mergeProfiles, profile)

    toast.success('Área de atuação cadastrada com sucesso!')
  } catch (error) {
    yield put(ProfessionalProfileActions.createProfessionalAtuationFailure())

    toast.error('Falha ao cadastrar área de atuação, tente novamente!')
  }
}

function* updateAtuation({ id, atuations }) {
  try {
    const {
      data: { data: profile }
    } = yield call(api.put, `/professionals/${id}`, {
      atuations
    })

    yield put(
      ProfessionalProfileActions.updateProfessionalAtuationSuccess(profile)
    )

    yield call(mergeProfiles, profile)

    toast.success('Área de atuação atualizada com sucesso!')
  } catch (error) {
    yield put(ProfessionalProfileActions.updateProfessionalAtuationFailure())

    toast.error('Falha ao atualizar área de atuação, tente novamente!')
  }
}

function* deleteAtuation({ id, atuations }) {
  try {
    const {
      data: { data: profile }
    } = yield call(api.put, `/professionals/${id}`, {
      atuations
    })

    yield put(
      ProfessionalProfileActions.deleteProfessionalAtuationSuccess(profile)
    )
    yield put(ProfessionalProfileActions.closeDeleteProfessionalAtuationModal())

    yield call(mergeProfiles, profile)

    toast.success('Área de atuação removida com sucesso!')
  } catch (error) {
    yield put(ProfessionalProfileActions.deleteProfessionalAtuationFailure())

    toast.error('Falha ao remover área de atuação, tente novamente!')
  }
}

// -> Exams
function* createExam({ id, exams }) {
  try {
    const {
      data: { data: profile }
    } = yield call(api.put, `/professionals/${id}`, {
      exams
    })

    yield put(ProfessionalProfileActions.createProfessionalExamSuccess(profile))

    yield call(mergeProfiles, profile)

    toast.success('Exame cadastrado com sucesso!')
  } catch (error) {
    yield put(ProfessionalProfileActions.createProfessionalExamFailure())

    toast.error('Falha ao cadastrar exame, tente novamente!')
  }
}

function* updateExam({ id, exams }) {
  try {
    const {
      data: { data: profile }
    } = yield call(api.put, `/professionals/${id}`, {
      exams
    })

    yield put(ProfessionalProfileActions.updateProfessionalExamSuccess(profile))

    yield call(mergeProfiles, profile)

    toast.success('Exame atualizado com sucesso!')
  } catch (error) {
    yield put(ProfessionalProfileActions.updateProfessionalExamFailure())

    toast.error('Falha ao atualizar exame, tente novamente!')
  }
}

function* deleteExam({ id, exams }) {
  try {
    const {
      data: { data: profile }
    } = yield call(api.put, `/professionals/${id}`, {
      exams
    })

    yield put(ProfessionalProfileActions.deleteProfessionalExamSuccess(profile))
    yield put(ProfessionalProfileActions.closeDeleteProfessionalExamModal())

    yield call(mergeProfiles, profile)

    toast.success('Exame removido com sucesso!')
  } catch (error) {
    yield put(ProfessionalProfileActions.deleteProfessionalExamFailure())

    toast.error('Falha ao remover exame, tente novamente!')
  }
}

// -> Specializations
function* createSpecialization({ id, specializations }) {
  try {
    const {
      data: { data: profile }
    } = yield call(api.put, `/professionals/${id}`, {
      specializations
    })

    yield put(
      ProfessionalProfileActions.createProfessionalSpecializationSuccess(
        profile
      )
    )

    yield call(mergeProfiles, profile)

    toast.success('Especialização cadastrada com sucesso!')
  } catch (error) {
    yield put(
      ProfessionalProfileActions.createProfessionalSpecializationFailure()
    )

    toast.error('Falha ao cadastrar especialização, tente novamente!')
  }
}

function* updateSpecialization({ id, specializations }) {
  try {
    const {
      data: { data: profile }
    } = yield call(api.put, `/professionals/${id}`, {
      specializations
    })

    yield put(
      ProfessionalProfileActions.updateProfessionalSpecializationSuccess(
        profile
      )
    )

    yield call(mergeProfiles, profile)

    toast.success('Especialização atualizada com sucesso!')
  } catch (error) {
    yield put(
      ProfessionalProfileActions.updateProfessionalSpecializationFailure()
    )

    toast.error('Falha ao atualizar especialização, tente novamente!')
  }
}

function* deleteSpecialization({ id, specializations }) {
  try {
    const {
      data: { data: profile }
    } = yield call(api.put, `/professionals/${id}`, {
      specializations
    })

    yield put(
      ProfessionalProfileActions.deleteProfessionalSpecializationSuccess(
        profile
      )
    )
    yield put(
      ProfessionalProfileActions.closeDeleteProfessionalSpecializationModal()
    )

    yield call(mergeProfiles, profile)

    toast.success('Especialização removida com sucesso!')
  } catch (error) {
    yield put(
      ProfessionalProfileActions.deleteProfessionalSpecializationFailure()
    )

    toast.error('Falha ao remover especialização, tente novamente!')
  }
}

// -> Settings
function* updateSettings({ professional, configs }) {
  try {
    const {
      data: { data: profile }
    } = yield call(api.put, `/professionals/${professional}`, {
      configs
    })

    yield put(
      ProfessionalProfileActions.updateProfessionalSettingsSuccess(profile)
    )

    yield call(mergeProfiles, profile)

    toast.success('Configurações atualizadas com sucesso!')
  } catch (error) {
    yield put(ProfessionalProfileActions.updateProfessionalSettingsFailure())

    toast.error('Falha ao atualizar configurações, tente novamente!')
  }
}

export default all([
  // -> Profile
  takeLatest(
    ProfessionalProfileTypes.UPDATE_PROFESSIONAL_PROFILE_REQUEST,
    updateProfile
  ),

  // -> Specialties
  takeLatest(
    ProfessionalProfileTypes.GET_PROFESSIONAL_SPECIALTIES_REQUEST,
    getSpecialties
  ),

  // -> Locations
  takeLatest(
    ProfessionalProfileTypes.GET_PROFESSIONAL_LOCATION_REQUEST,
    getLocation
  ),
  takeLatest(
    ProfessionalProfileTypes.GET_PROFESSIONAL_LOCATIONS_REQUEST,
    getLocations
  ),
  takeLatest(
    ProfessionalProfileTypes.CREATE_PROFESSIONAL_LOCATION_REQUEST,
    createLocation
  ),
  takeLatest(
    ProfessionalProfileTypes.UPDATE_PROFESSIONAL_LOCATION_REQUEST,
    updateLocation
  ),
  takeLatest(
    ProfessionalProfileTypes.DELETE_PROFESSIONAL_LOCATION_REQUEST,
    deleteLocation
  ),

  // -> Procedures
  takeLatest(
    ProfessionalProfileTypes.CREATE_PROFESSIONAL_PROCEDURE_REQUEST,
    createProcedure
  ),
  takeLatest(
    ProfessionalProfileTypes.UPDATE_PROFESSIONAL_PROCEDURE_REQUEST,
    updateProcedure
  ),
  takeLatest(
    ProfessionalProfileTypes.DELETE_PROFESSIONAL_PROCEDURE_REQUEST,
    deleteProcedure
  ),

  // -> Agreements
  takeLatest(
    ProfessionalProfileTypes.CREATE_PROFESSIONAL_AGREEMENT_REQUEST,
    createAgreement
  ),
  takeLatest(
    ProfessionalProfileTypes.UPDATE_PROFESSIONAL_AGREEMENT_REQUEST,
    updateAgreement
  ),
  takeLatest(
    ProfessionalProfileTypes.DELETE_PROFESSIONAL_AGREEMENT_REQUEST,
    deleteAgreement
  ),

  // -> Atuations
  takeLatest(
    ProfessionalProfileTypes.CREATE_PROFESSIONAL_ATUATION_REQUEST,
    createAtuation
  ),
  takeLatest(
    ProfessionalProfileTypes.UPDATE_PROFESSIONAL_ATUATION_REQUEST,
    updateAtuation
  ),
  takeLatest(
    ProfessionalProfileTypes.DELETE_PROFESSIONAL_ATUATION_REQUEST,
    deleteAtuation
  ),

  // -> Exams
  takeLatest(
    ProfessionalProfileTypes.CREATE_PROFESSIONAL_EXAM_REQUEST,
    createExam
  ),
  takeLatest(
    ProfessionalProfileTypes.UPDATE_PROFESSIONAL_EXAM_REQUEST,
    updateExam
  ),
  takeLatest(
    ProfessionalProfileTypes.DELETE_PROFESSIONAL_EXAM_REQUEST,
    deleteExam
  ),

  // -> Specializations
  takeLatest(
    ProfessionalProfileTypes.CREATE_PROFESSIONAL_SPECIALIZATION_REQUEST,
    createSpecialization
  ),
  takeLatest(
    ProfessionalProfileTypes.UPDATE_PROFESSIONAL_SPECIALIZATION_REQUEST,
    updateSpecialization
  ),
  takeLatest(
    ProfessionalProfileTypes.DELETE_PROFESSIONAL_SPECIALIZATION_REQUEST,
    deleteSpecialization
  ),

  // -> Settings
  takeLatest(
    ProfessionalProfileTypes.UPDATE_PROFESSIONAL_SETTINGS_REQUEST,
    updateSettings
  )
])
