const theme = {
  color: {
    blue: '#126dea',
    green: '#0d5249',
    lightGreen: '#00c5ae',
    mediumLightGreen: '#00c581',
    mediumGreen: '#12cb2f',
    orange: '#ffad1b',
    mediumOrange: '#ff690b',
    darkerOrange: '#ff8900',
    purple: '#7b0ed8',
    red: '#ff0000',
    grey: '#959595',
    lightGrey: '#fafafa',
    mediumLightGrey: '#dbdbdb',
    mediumGrey: '#808080',
    text: '#494949',
    black: '#000',
    white: '#fff'
  },

  spacing: {
    xs: '4px',
    sm: '8px',
    default: '16px',
    md: '24px',
    lg: '34px'
  },

  radius: {
    default: '5px',
    md: '8px',
    lg: '10px'
  },

  border: {
    primary: '1px solid #00c5ae',
    secondary: '1px solid #bfbfbf',
    tertiary: '1px solid #dbdbdb',
    quaternary: '1px solid #7b0ed8',
    quinary: '1px solid #c1c1c1',
    danger: '1px solid #ff0000',
    success: '1px solid #12cb2f',
    pending: '1px solid #ff690b',
    white: '1px solid #fff',
    orange: '1px solid #ffad1b',
    outline: '1px solid #126dea'
  }
}

export default theme
