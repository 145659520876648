import Immutable from 'seamless-immutable'
import { createActions, createReducer } from 'reduxsauce'

const initialState = Immutable({
  profile: JSON.parse(localStorage.getItem('@gurumed:user')) || {},

  // -> Specialties
  selectedSpecialty: null,
  specialtiesSearch: null,
  deleteSpecialtyModal: {
    isOpen: false,
    specialty: null
  },

  // -> Procedures
  selectedProcedure: null,
  proceduresSearch: null,
  deleteProcedureModal: {
    isOpen: false,
    procedure: null
  },

  // -> Examms
  selectedExam: null,
  examsSearch: null,
  deleteExamModal: {
    isOpen: false,
    exam: null
  },

  // -> Faq
  selectedQuestion: null,
  deleteQuestionModal: {
    isOpen: false,
    question: null
  },

  // -> Locations
  selectedLocation: null,
  deleteLocationModal: {
    isOpen: false,
    location: null
  },

  loading: {
    profile: false,
    avatar: false,
    images: false,
    actions: false,
    modal: false
  },

  success: {
    images: false
  }
})

const { Types, Creators } = createActions({
  updateLaboratoryProfileRequest: ['id', 'data'],
  updateLaboratoryProfileSuccess: ['profile'],
  updateLaboratoryProfileFailure: [],

  uploadLaboratoryImageRequest: ['laboratory', 'image'],
  uploadLaboratoryImageSuccess: ['image'],
  uploadLaboratoryImageFailure: [],

  removeLaboratoryImage: ['id'],

  // -> Specialties
  createLaboratorySpecialtyRequest: ['id', 'specialties'],
  createLaboratorySpecialtySuccess: ['profile'],
  createLaboratorySpecialtyFailure: [],

  updateLaboratorySpecialtyRequest: ['id', 'specialties'],
  updateLaboratorySpecialtySuccess: ['profile'],
  updateLaboratorySpecialtyFailure: [],

  deleteLaboratorySpecialtyRequest: ['id', 'specialties'],
  deleteLaboratorySpecialtySuccess: ['profile'],
  deleteLaboratorySpecialtyFailure: [],

  setSelectedLaboratorySpecialty: ['specialty'],
  setLaboratorySpecialtiesSearch: ['value'],
  setDeleteLaboratorySpecialtyModal: ['specialty'],
  openDeleteLaboratorySpecialtyModal: [],
  closeDeleteLaboratorySpecialtyModal: [],

  // -> Procedures
  createLaboratoryProcedureRequest: ['id', 'procedures'],
  createLaboratoryProcedureSuccess: ['profile'],
  createLaboratoryProcedureFailure: [],

  updateLaboratoryProcedureRequest: ['id', 'procedures'],
  updateLaboratoryProcedureSuccess: ['profile'],
  updateLaboratoryProcedureFailure: [],

  deleteLaboratoryProcedureRequest: ['id', 'procedures'],
  deleteLaboratoryProcedureSuccess: ['profile'],
  deleteLaboratoryProcedureFailure: [],

  setSelectedLaboratoryProcedure: ['procedure'],
  setLaboratoryProceduresSearch: ['value'],
  setDeleteLaboratoryProcedureModal: ['procedure'],
  openDeleteLaboratoryProcedureModal: [],
  closeDeleteLaboratoryProcedureModal: [],

  // -> Exams
  createLaboratoryExamRequest: ['id', 'exams'],
  createLaboratoryExamSuccess: ['profile'],
  createLaboratoryExamFailure: [],

  updateLaboratoryExamRequest: ['id', 'exams'],
  updateLaboratoryExamSuccess: ['profile'],
  updateLaboratoryExamFailure: [],

  deleteLaboratoryExamRequest: ['id', 'exams'],
  deleteLaboratoryExamSuccess: ['profile'],
  deleteLaboratoryExamFailure: [],

  setSelectedLaboratoryExam: ['exam'],
  setLaboratoryExamsSearch: ['value'],
  setDeleteLaboratoryExamModal: ['exam'],
  openDeleteLaboratoryExamModal: [],
  closeDeleteLaboratoryExamModal: [],

  // -> Faq
  createLaboratoryQuestionRequest: ['id', 'faq'],
  createLaboratoryQuestionSuccess: ['profile'],
  createLaboratoryQuestionFailure: [],

  updateLaboratoryQuestionRequest: ['id', 'faq'],
  updateLaboratoryQuestionSuccess: ['profile'],
  updateLaboratoryQuestionFailure: [],

  deleteLaboratoryQuestionRequest: ['id', 'faq'],
  deleteLaboratoryQuestionSuccess: ['profile'],
  deleteLaboratoryQuestionFailure: [],

  setSelectedLaboratoryQuestion: ['question'],

  setDeleteLaboratoryQuestionModal: ['question'],
  openDeleteLaboratoryQuestionModal: [],
  closeDeleteLaboratoryQuestionModal: [],

  // -> Locations
  createLaboratoryLocationRequest: ['id', 'locations'],
  createLaboratoryLocationSuccess: ['profile'],
  createLaboratoryLocationFailure: [],

  updateLaboratoryLocationRequest: ['id', 'locations'],
  updateLaboratoryLocationSuccess: ['profile'],
  updateLaboratoryLocationFailure: [],

  deleteLaboratoryLocationRequest: ['id', 'locations'],
  deleteLaboratoryLocationSuccess: ['profile'],
  deleteLaboratoryLocationFailure: [],

  setSelectedLaboratoryLocation: ['location'],

  setDeleteLaboratoryLocationModal: ['location'],
  openDeleteLaboratoryLocationModal: [],
  closeDeleteLaboratoryLocationModal: [],

  // -> Settings
  updateLaboratorySettingsRequest: ['laboratory', 'configs'],
  updateLaboratorySettingsSuccess: ['profile'],
  updateLaboratorySettingsFailure: [],

  saveProfile: ['profile'],
  clearLaboratoryProfileState: []
})

const laboratoryProfile = createReducer(initialState, {
  [Types.UPDATE_LABORATORY_PROFILE_REQUEST]: state =>
    state.merge({ loading: { ...state.loading, profile: true } }),

  [Types.UPDATE_LABORATORY_PROFILE_SUCCESS]: (state, { profile }) =>
    state.merge({
      profile: {
        laboratorio: {
          ...profile
        }
      },
      loading: { ...state.loading, profile: false }
    }),

  [Types.UPDATE_LABORATORY_PROFILE_FAILURE]: state =>
    state.merge({ loading: { ...state.loading, profile: false } }),

  [Types.UPLOAD_LABORATORY_IMAGE_REQUEST]: state =>
    state.merge({
      loading: { ...state.loading, images: true },
      success: { ...state.success, images: false }
    }),

  [Types.UPLOAD_LABORATORY_IMAGE_SUCCESS]: (state, { image }) =>
    state.merge({
      profile: {
        ...state.profile,
        laboratorio: {
          ...state.profile.laboratorio,
          images: [...state.profile.laboratorio.images, image]
        }
      },
      loading: { ...state.loading, images: false },
      success: { ...state.success, images: true }
    }),

  [Types.UPLOAD_LABORATORY_IMAGE_FAILURE]: state =>
    state.merge({
      loading: { ...state.loading, images: false },
      success: { ...state.success, images: false }
    }),

  [Types.REMOVE_LABORATORY_IMAGE]: (state, { id }) =>
    state.merge({
      profile: {
        ...state.profile,
        laboratorio: {
          ...state.profile.laboratorio,
          images: state.profile.laboratorio.images.filter(
            image => image.imageId !== id
          )
        }
      }
    }),

  // -> Specialties
  [Types.CREATE_LABORATORY_SPECIALTY_REQUEST]: state =>
    state.merge({
      loading: {
        ...state.loading,
        actions: true
      }
    }),

  [Types.CREATE_LABORATORY_SPECIALTY_SUCCESS]: (state, { profile }) =>
    state.merge({
      selectedSpecialty: null,
      profile: {
        ...state.profile,
        laboratorio: profile
      },
      loading: {
        ...state.loading,
        actions: false
      }
    }),

  [Types.CREATE_LABORATORY_SPECIALTY_FAILURE]: state =>
    state.merge({
      loading: {
        ...state.loading,
        actions: false
      }
    }),

  [Types.UPDATE_LABORATORY_SPECIALTY_REQUEST]: state =>
    state.merge({
      loading: {
        ...state.loading,
        actions: true
      }
    }),

  [Types.UPDATE_LABORATORY_SPECIALTY_SUCCESS]: (state, { profile }) =>
    state.merge({
      selectedSpecialty: null,
      profile: {
        ...state.profile,
        laboratorio: profile
      },
      loading: {
        ...state.loading,
        actions: false
      }
    }),

  [Types.UPDATE_LABORATORY_SPECIALTY_FAILURE]: state =>
    state.merge({
      loading: {
        ...state.loading,
        actions: false
      }
    }),

  [Types.DELETE_LABORATORY_SPECIALTY_REQUEST]: state =>
    state.merge({
      loading: {
        ...state.loading,
        modal: true
      }
    }),

  [Types.DELETE_LABORATORY_SPECIALTY_SUCCESS]: (state, { profile }) =>
    state.merge({
      selectedSpecialty: null,
      profile: {
        ...state.profile,
        laboratorio: profile
      },
      loading: {
        ...state.loading,
        modal: false
      }
    }),

  [Types.DELETE_LABORATORY_SPECIALTY_FAILURE]: state =>
    state.merge({
      loading: {
        ...state.loading,
        modal: false
      }
    }),

  [Types.SET_DELETE_LABORATORY_SPECIALTY_MODAL]: (state, { specialty }) =>
    state.merge({
      deleteSpecialtyModal: { ...state.deleteSpecialtyModal, specialty }
    }),

  [Types.OPEN_DELETE_LABORATORY_SPECIALTY_MODAL]: state =>
    state.merge({
      deleteSpecialtyModal: { ...state.deleteSpecialtyModal, isOpen: true }
    }),

  [Types.CLOSE_DELETE_LABORATORY_SPECIALTY_MODAL]: state =>
    state.merge({
      deleteSpecialtyModal: { ...state.deleteSpecialtyModal, isOpen: false }
    }),

  [Types.SET_SELECTED_LABORATORY_SPECIALTY]: (state, { specialty }) =>
    state.merge({
      selectedSpecialty: specialty
    }),

  [Types.SET_LABORATORY_SPECIALTIES_SEARCH]: (state, { value }) =>
    state.merge({ specialtiesSearch: value }),

  // -> Procedures

  [Types.CREATE_LABORATORY_PROCEDURE_REQUEST]: state =>
    state.merge({
      loading: {
        ...state.loading,
        actions: true
      }
    }),

  [Types.CREATE_LABORATORY_PROCEDURE_SUCCESS]: (state, { profile }) =>
    state.merge({
      selectedProcedure: null,
      profile: {
        ...state.profile,
        laboratorio: profile
      },
      loading: {
        ...state.loading,
        actions: false
      }
    }),

  [Types.CREATE_LABORATORY_PROCEDURE_FAILURE]: state =>
    state.merge({
      loading: {
        ...state.loading,
        actions: false
      }
    }),

  [Types.UPDATE_LABORATORY_PROCEDURE_REQUEST]: state =>
    state.merge({
      loading: {
        ...state.loading,
        actions: true
      }
    }),

  [Types.UPDATE_LABORATORY_PROCEDURE_SUCCESS]: (state, { profile }) =>
    state.merge({
      selectedProcedure: null,
      profile: {
        ...state.profile,
        laboratorio: profile
      },
      loading: {
        ...state.loading,
        actions: false
      }
    }),

  [Types.UPDATE_LABORATORY_PROCEDURE_FAILURE]: state =>
    state.merge({
      loading: {
        ...state.loading,
        actions: false
      }
    }),

  [Types.DELETE_LABORATORY_PROCEDURE_REQUEST]: state =>
    state.merge({
      loading: {
        ...state.loading,
        modal: true
      }
    }),

  [Types.DELETE_LABORATORY_PROCEDURE_SUCCESS]: (state, { profile }) =>
    state.merge({
      selectedProcedure: null,
      profile: {
        ...state.profile,
        laboratorio: profile
      },
      loading: {
        ...state.loading,
        modal: false
      }
    }),

  [Types.DELETE_LABORATORY_PROCEDURE_FAILURE]: state =>
    state.merge({
      loading: {
        ...state.loading,
        modal: false
      }
    }),

  [Types.SET_DELETE_LABORATORY_PROCEDURE_MODAL]: (state, { procedure }) =>
    state.merge({
      deleteProcedureModal: { ...state.deleteProcedureModal, procedure }
    }),

  [Types.OPEN_DELETE_LABORATORY_PROCEDURE_MODAL]: state =>
    state.merge({
      deleteProcedureModal: { ...state.deleteProcedureModal, isOpen: true }
    }),

  [Types.CLOSE_DELETE_LABORATORY_PROCEDURE_MODAL]: state =>
    state.merge({
      deleteProcedureModal: { ...state.deleteProcedureModal, isOpen: false }
    }),

  [Types.SET_SELECTED_LABORATORY_PROCEDURE]: (state, { procedure }) =>
    state.merge({
      selectedProcedure: procedure
    }),

  [Types.SET_LABORATORY_PROCEDURES_SEARCH]: (state, { value }) =>
    state.merge({ proceduresSearch: value }),

  // -> Exams
  [Types.CREATE_LABORATORY_EXAM_REQUEST]: state =>
    state.merge({
      loading: {
        ...state.loading,
        actions: true
      }
    }),

  [Types.CREATE_LABORATORY_EXAM_SUCCESS]: (state, { profile }) =>
    state.merge({
      selectedExam: null,
      profile: {
        ...state.profile,
        laboratorio: profile
      },
      loading: {
        ...state.loading,
        actions: false
      }
    }),

  [Types.CREATE_LABORATORY_EXAM_FAILURE]: state =>
    state.merge({
      loading: {
        ...state.loading,
        actions: false
      }
    }),

  [Types.UPDATE_LABORATORY_EXAM_REQUEST]: state =>
    state.merge({
      loading: {
        ...state.loading,
        actions: true
      }
    }),

  [Types.UPDATE_LABORATORY_EXAM_SUCCESS]: (state, { profile }) =>
    state.merge({
      selectedExam: null,
      profile: {
        ...state.profile,
        laboratorio: profile
      },
      loading: {
        ...state.loading,
        actions: false
      }
    }),

  [Types.UPDATE_LABORATORY_EXAM_FAILURE]: state =>
    state.merge({
      loading: {
        ...state.loading,
        actions: false
      }
    }),

  [Types.DELETE_LABORATORY_EXAM_REQUEST]: state =>
    state.merge({
      loading: {
        ...state.loading,
        modal: true
      }
    }),

  [Types.DELETE_LABORATORY_EXAM_SUCCESS]: (state, { profile }) =>
    state.merge({
      selectedExam: null,
      profile: {
        ...state.profile,
        laboratorio: profile
      },
      loading: {
        ...state.loading,
        modal: false
      }
    }),

  [Types.DELETE_LABORATORY_EXAM_FAILURE]: state =>
    state.merge({
      loading: {
        ...state.loading,
        modal: false
      }
    }),

  [Types.SET_DELETE_LABORATORY_EXAM_MODAL]: (state, { exam }) =>
    state.merge({
      deleteExamModal: { ...state.deleteExamModal, exam }
    }),

  [Types.OPEN_DELETE_LABORATORY_EXAM_MODAL]: state =>
    state.merge({
      deleteExamModal: { ...state.deleteExamModal, isOpen: true }
    }),

  [Types.CLOSE_DELETE_LABORATORY_EXAM_MODAL]: state =>
    state.merge({
      deleteExamModal: { ...state.deleteExamModal, isOpen: false }
    }),

  [Types.SET_SELECTED_LABORATORY_EXAM]: (state, { exam }) =>
    state.merge({
      selectedExam: exam
    }),

  [Types.SET_LABORATORY_EXAMS_SEARCH]: (state, { value }) =>
    state.merge({ examsSearch: value }),

  // -> Faq
  [Types.CREATE_LABORATORY_QUESTION_REQUEST]: state =>
    state.merge({
      loading: {
        ...state.loading,
        actions: true
      }
    }),

  [Types.CREATE_LABORATORY_QUESTION_SUCCESS]: (state, { profile }) =>
    state.merge({
      selectedQuestion: null,
      profile: {
        ...state.profile,
        laboratorio: profile
      },
      loading: {
        ...state.loading,
        actions: false
      }
    }),

  [Types.CREATE_LABORATORY_QUESTION_FAILURE]: state =>
    state.merge({
      loading: {
        ...state.loading,
        actions: false
      }
    }),

  [Types.UPDATE_LABORATORY_QUESTION_REQUEST]: state =>
    state.merge({
      loading: {
        ...state.loading,
        actions: true
      }
    }),

  [Types.UPDATE_LABORATORY_QUESTION_SUCCESS]: (state, { profile }) =>
    state.merge({
      selectedQuestion: null,
      profile: {
        ...state.profile,
        laboratorio: profile
      },
      loading: {
        ...state.loading,
        actions: false
      }
    }),

  [Types.UPDATE_LABORATORY_QUESTION_FAILURE]: state =>
    state.merge({
      loading: {
        ...state.loading,
        actions: false
      }
    }),

  [Types.DELETE_LABORATORY_QUESTION_REQUEST]: state =>
    state.merge({
      loading: {
        ...state.loading,
        modal: true
      }
    }),

  [Types.DELETE_LABORATORY_QUESTION_SUCCESS]: (state, { profile }) =>
    state.merge({
      selectedQuestion: null,
      profile: {
        ...state.profile,
        laboratorio: profile
      },
      loading: {
        ...state.loading,
        modal: false
      }
    }),

  [Types.DELETE_LABORATORY_QUESTION_FAILURE]: state =>
    state.merge({
      loading: {
        ...state.loading,
        modal: false
      }
    }),

  [Types.SET_DELETE_LABORATORY_QUESTION_MODAL]: (state, { question }) =>
    state.merge({
      deleteQuestionModal: { ...state.deleteQuestionModal, question }
    }),

  [Types.OPEN_DELETE_LABORATORY_QUESTION_MODAL]: state =>
    state.merge({
      deleteQuestionModal: { ...state.deleteQuestionModal, isOpen: true }
    }),

  [Types.CLOSE_DELETE_LABORATORY_QUESTION_MODAL]: state =>
    state.merge({
      deleteQuestionModal: { ...state.deleteQuestionModal, isOpen: false }
    }),

  [Types.SET_SELECTED_LABORATORY_QUESTION]: (state, { question }) =>
    state.merge({
      selectedQuestion: question
    }),

  // -> Locations
  [Types.CREATE_LABORATORY_LOCATION_REQUEST]: state =>
    state.merge({
      loading: {
        ...state.loading,
        actions: true
      }
    }),

  [Types.CREATE_LABORATORY_LOCATION_SUCCESS]: (state, { profile }) =>
    state.merge({
      selectedQuestion: null,
      profile: {
        ...state.profile,
        laboratorio: profile
      },
      loading: {
        ...state.loading,
        actions: false
      }
    }),

  [Types.CREATE_LABORATORY_LOCATION_FAILURE]: state =>
    state.merge({
      loading: {
        ...state.loading,
        actions: false
      }
    }),

  [Types.UPDATE_LABORATORY_LOCATION_REQUEST]: state =>
    state.merge({
      loading: {
        ...state.loading,
        actions: true
      }
    }),

  [Types.UPDATE_LABORATORY_LOCATION_SUCCESS]: (state, { profile }) =>
    state.merge({
      selectedLocation: null,
      profile: {
        ...state.profile,
        laboratorio: profile
      },
      loading: {
        ...state.loading,
        actions: false
      }
    }),

  [Types.UPDATE_LABORATORY_LOCATION_FAILURE]: state =>
    state.merge({
      loading: {
        ...state.loading,
        actions: false
      }
    }),

  [Types.DELETE_LABORATORY_LOCATION_REQUEST]: state =>
    state.merge({
      loading: {
        ...state.loading,
        modal: true
      }
    }),

  [Types.DELETE_LABORATORY_LOCATION_SUCCESS]: (state, { profile }) =>
    state.merge({
      selectedLocation: null,
      profile: {
        ...state.profile,
        laboratorio: profile
      },
      loading: {
        ...state.loading,
        modal: false
      }
    }),

  [Types.DELETE_LABORATORY_LOCATION_FAILURE]: state =>
    state.merge({
      loading: {
        ...state.loading,
        modal: false
      }
    }),

  [Types.SET_DELETE_LABORATORY_LOCATION_MODAL]: (state, { location }) =>
    state.merge({
      deleteLocationModal: { ...state.deleteLocationModal, location }
    }),

  [Types.OPEN_DELETE_LABORATORY_LOCATION_MODAL]: state =>
    state.merge({
      deleteLocationModal: { ...state.deleteLocationModal, isOpen: true }
    }),

  [Types.CLOSE_DELETE_LABORATORY_LOCATION_MODAL]: state =>
    state.merge({
      deleteLocationModal: { ...state.deleteLocationModal, isOpen: false }
    }),

  [Types.SET_SELECTED_LABORATORY_LOCATION]: (state, { location }) =>
    state.merge({
      selectedLocation: location
    }),

  // -> Settings
  [Types.UPDATE_LABORATORY_SETTINGS_REQUEST]: state =>
    state.merge({
      loading: {
        ...state.loading,
        actions: true
      }
    }),

  [Types.UPDATE_LABORATORY_SETTINGS_SUCCESS]: (state, { profile }) =>
    state.merge({
      profile: {
        ...state.profile,
        laboratorio: profile
      },
      loading: {
        ...state.loading,
        actions: false
      }
    }),

  [Types.UPDATE_LABORATORY_SETTINGS_FAILURE]: state =>
    state.merge({
      loading: {
        ...state.loading,
        actions: false
      }
    }),

  [Types.SAVE_PROFILE]: (state, { profile }) => state.merge({ profile }),

  [Types.CLEAR_LABORATORY_PROFILE_STATE]: state => ({
    profile: state.profile,
    ...initialState
  })
})

export const LaboratoryProfileTypes = Types
export const LaboratoryProfileActions = Creators

export default laboratoryProfile
