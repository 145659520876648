import React, { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Formik } from 'formik'
import { object, string } from 'yup'

import { AuthActions } from 'store/ducks/auth'

import Input from 'components/Input'
import Button from 'components/Button'

import Modal from './Modal'

import { Container, Wrapper, Description, Form, Bottom, Anchor } from './styled'

const initialValues = {
  email: ''
}

const validationSchema = object().shape({
  email: string()
    .email('O campo email deve conter um email válido.')
    .required('O campo email é obrigatório.')
})

function ForgotPassword() {
  const { modal, loading } = useSelector(state => state.auth)
  const dispatch = useDispatch()

  const handleSubmit = useCallback(
    (values, { setSubmitting }) => {
      const { email } = values

      dispatch(AuthActions.forgotPasswordRequest(email))

      setSubmitting(false)
    },
    [dispatch]
  )

  return (
    <Container>
      <Wrapper>
        <Description>
          Digite o email cadastrado para recuperar seu acesso
        </Description>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched }) => (
            <>
              <Form>
                <Input
                  type='email'
                  name='email'
                  label='Email'
                  variant='primary'
                  mb='18px'
                />

                <Bottom>
                  <Button
                    type='submit'
                    variant='primary'
                    loading={loading}
                    mb='28px'
                  >
                    Enviar
                  </Button>

                  <Anchor to='/signin'>Voltar para o login</Anchor>
                </Bottom>
              </Form>

              {modal.isOpen && <Modal />}
            </>
          )}
        </Formik>
      </Wrapper>
    </Container>
  )
}

export default ForgotPassword
