import { takeLatest, call, put, all } from 'redux-saga/effects'
import { push, goBack } from 'connected-react-router'
import { toast } from 'react-toastify'

import { SchedulesTypes, SchedulesActions } from '../ducks/schedules'

import api from 'services/api'

function* getSchedules({ context, id }) {
  try {
    const types = {
      clinic: `/clinics/${id}/schedules/all`,
      laboratory: `/laboratories/${id}/schedules/all`,
      professional: `/professionals/${id}/schedules/all`
    }

    const { data } = yield call(api.get, types[context])

    const schedules = data.map(schedule => ({
      ...schedule,
      month: String(+schedule.month - 1)
    }))

    yield put(SchedulesActions.getSchedulesSuccess(schedules))
  } catch (error) {
    yield put(SchedulesActions.getSchedulesFailure())

    toast.error('Falha ao buscar agenda, tente novamente!')
  }
}

function* createSchedules({ context, schedule, redirect }) {
  try {
    const types = {
      clinic: '/clinic/schedules',
      laboratory: '/laboratory/schedules',
      professional: '/professional/schedules'
    }

    const data = {
      ...schedule,
      professional: schedule?.professional?._id ?? schedule.professional,
      clinic: schedule?.clinic?._id ?? schedule?.clinic,
      laboratory: schedule?.laboratory?._id ?? schedule?.laboratory,
      month: String(+schedule.month + 1),
      type: context
    }

    const { data: currentSchedule } = yield call(api.post, '/schedules', data)

    const createdSchedule = {
      ...currentSchedule,
      month: String(+currentSchedule.month - 1)
    }

    yield put(SchedulesActions.createSchedulesSuccess(createdSchedule))

    toast.success('Agenda criada com sucesso!')

    if (redirect) {
      yield put(push(types[context]))
    }
  } catch (error) {
    yield put(SchedulesActions.createSchedulesFailure())

    toast.error('Falha ao criar agenda, tente novamente!')
  }
}

function* updateSchedules({ context, id, schedule, redirect }) {
  const types = {
    clinic: '/clinic/schedules',
    laboratory: '/laboratory/schedules',
    professional: '/professional/schedules'
  }

  try {
    const data = {
      ...schedule,
      professional: schedule?.professional?._id ?? schedule?.professional,
      clinic: schedule?.clinic?._id ?? schedule?.clinic,
      laboratory: schedule?.laboratory?._id ?? schedule?.laboratory,
      month: String(+schedule.month + 1),
      type: context
    }

    const { data: currentSchedule } = yield call(
      api.put,
      `/schedules/${id}`,
      data
    )

    const updatedSchedule = {
      ...currentSchedule,
      month: String(+currentSchedule.month - 1)
    }

    yield put(SchedulesActions.updateSchedulesSuccess(updatedSchedule))

    toast.success('Agenda atualizada com sucesso!')

    if (redirect) {
      yield put(push(types[context]))
    }
  } catch (error) {
    yield put(SchedulesActions.updateSchedulesFailure())

    toast.error('Falha ao atualizar agenda, tente novamente!')
  }
}

function* deleteSchedules({ schedule, navigate }) {
  try {
    yield call(api.delete, `/schedules/${schedule}`)

    yield put(SchedulesActions.deleteSchedulesSuccess(schedule))
    yield put(SchedulesActions.closeDeleteSchedulesModal())

    if (navigate) {
      yield put(goBack())
    }
  } catch (error) {
    yield put(SchedulesActions.deleteSchedulesFailure())

    toast.error('Falha ao remover agenda, tente novamente!')
  }
}

export default all([
  takeLatest(SchedulesTypes.GET_SCHEDULES_REQUEST, getSchedules),
  takeLatest(SchedulesTypes.CREATE_SCHEDULES_REQUEST, createSchedules),
  takeLatest(SchedulesTypes.UPDATE_SCHEDULES_REQUEST, updateSchedules),
  takeLatest(SchedulesTypes.DELETE_SCHEDULES_REQUEST, deleteSchedules)
])
