import styled from 'styled-components/macro'
import { theme } from 'styled-tools'
import { Form as BaseForm } from 'formik'
import { Link } from 'react-router-dom'

export const Container = styled.section``

export const Wrapper = styled.div`
  max-width: 470px;
  margin-left: auto;
  margin-right: auto;
`

export const Title = styled.h2`
  margin-bottom: 12px;
  font-size: 1.75rem;
  font-weight: 500;
  text-align: center;
`

export const Description = styled.p`
  margin-bottom: 38px;
  font-size: 1.125rem;
  text-align: center;
`

export const Form = styled(BaseForm)``

export const Row = styled.div`
  margin-bottom: 30px;
`

export const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Anchor = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: ${theme('spacing.md')};
  font-size: 0.875rem;
  color: ${theme('color.grey')};
`

export const Forgot = styled.span`
  margin-left: ${theme('spacing.sm')};
`
