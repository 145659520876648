import Immutable from 'seamless-immutable'
import { createActions, createReducer } from 'reduxsauce'

const initialState = Immutable({
  ...(JSON.parse(localStorage.getItem('@gurumed:user')) || {})
})

const { Types, Creators } = createActions({
  saveUser: ['user']
})

const user = createReducer(initialState, {
  [Types.SAVE_USER]: (state, { user }) => state.merge({ ...user })
})

export const UserTypes = Types
export const UserActions = Creators

export default user
