import Immutable from 'seamless-immutable'
import { createActions, createReducer } from 'reduxsauce'

const initialState = Immutable({
  search: '',
  professionals: [],
  professional: null,
  selectedProfessional: null,
  deleteModal: {
    isOpen: false,
    clinic: null,
    professional: null
  },
  loading: {
    professionals: false,
    actions: false
  }
})

const { Types, Creators } = createActions({
  setProfessionalsSearch: ['value'],

  getProfessionalsRequest: ['clinic'],
  getProfessionalsSuccess: ['professionals'],
  getProfessionalsFailure: [],

  createProfessionalsRequest: ['clinic', 'professional'],
  createProfessionalsSuccess: [],
  createProfessionalsFailure: [],

  updateProfessionalsRequest: ['clinic', 'professional', 'profile'],
  updateProfessionalsSuccess: [],
  updateProfessionalsFailure: [],

  deleteProfessionalsRequest: ['clinic', 'professional'],
  deleteProfessionalsSuccess: ['professional'],
  deleteProfessionalsFailure: [],

  setSelectedProfessional: ['professional'],

  setDeleteProfessionalsModal: ['clinic', 'professional'],
  openDeleteProfessionalsModal: [],
  closeDeleteProfessionalsModal: [],

  clearProfessionalsState: []
})

const professionals = createReducer(initialState, {
  [Types.SET_PROFESSIONALS_SEARCH]: (state, { value }) =>
    state.merge({ search: value }),

  [Types.GET_PROFESSIONALS_REQUEST]: state =>
    state.merge({ loading: { ...state.loading, professionals: true } }),

  [Types.GET_PROFESSIONALS_SUCCESS]: (state, { professionals }) =>
    state.merge({
      professionals,
      loading: { ...state.loading, professionals: false }
    }),

  [Types.GET_PROFESSIONALS_FAILURE]: state =>
    state.merge({ loading: { ...state.loading, professionals: false } }),

  [Types.CREATE_PROFESSIONALS_REQUEST]: state =>
    state.merge({ loading: { ...state.loading, actions: true } }),

  [Types.CREATE_PROFESSIONALS_SUCCESS]: state =>
    state.merge({ loading: { ...state.loading, actions: false } }),

  [Types.CREATE_PROFESSIONALS_FAILURE]: state =>
    state.merge({ loading: { ...state.loading, actions: false } }),

  [Types.UPDATE_PROFESSIONALS_REQUEST]: state =>
    state.merge({
      loading: {
        ...state.loading,
        actions: true
      }
    }),

  [Types.UPDATE_PROFESSIONALS_SUCCESS]: state =>
    state.merge({
      loading: {
        ...state.loading,
        actions: false
      }
    }),

  [Types.UPDATE_PROFESSIONALS_FAILURE]: state =>
    state.merge({
      loading: {
        ...state.loading,
        actions: false
      }
    }),

  [Types.DELETE_PROFESSIONALS_REQUEST]: state =>
    state.merge({
      loading: {
        ...state.loading,
        actions: true
      }
    }),

  [Types.DELETE_PROFESSIONALS_SUCCESS]: (state, { professional }) =>
    state.merge({
      professionals: state.professionals.filter(
        current => current._id !== professional
      ),
      loading: {
        ...state.loading,
        actions: false
      }
    }),

  [Types.DELETE_PROFESSIONALS_FAILURE]: state =>
    state.merge({
      loading: {
        ...state.loading,
        actions: false
      }
    }),

  [Types.SET_SELECTED_PROFESSIONAL]: (state, { professional }) =>
    state.merge({ selectedProfessional: professional }),

  [Types.SET_DELETE_PROFESSIONALS_MODAL]: (state, { clinic, professional }) =>
    state.merge({
      deleteModal: { ...state.deleteModal, clinic, professional }
    }),

  [Types.OPEN_DELETE_PROFESSIONALS_MODAL]: state =>
    state.merge({
      deleteModal: { ...state.deleteModal, isOpen: true }
    }),

  [Types.CLOSE_DELETE_PROFESSIONALS_MODAL]: state =>
    state.merge({
      deleteModal: { ...state.deleteModal, isOpen: false }
    }),

  [Types.CLEAR_PROFESSIONALS_STATE]: () => initialState
})

export const ProfessionalsTypes = Types
export const ProfessionalsActions = Creators

export default professionals
