import React from 'react'

export function Report(props) {
  return (
    <svg
      {...props}
      xmlns='http://www.w3.org/2000/svg'
      width='21'
      height='21'
      viewBox='0 0 21 21'
    >
      <defs>
        <style>{'.report-a{fill:none;}.report-b{fill:#126dea;}'}</style>
      </defs>
      <path className='report-a' d='M0,0H21V21H0Z' />
      <path className='report-a' d='M0,0H21V21H0Z' />
      <path
        className='report-b'
        d='M10.5,4.5a10.229,10.229,0,0,0-9.5,6,10.229,10.229,0,0,0,9.5,6,10.229,10.229,0,0,0,9.5-6A10.229,10.229,0,0,0,10.5,4.5Zm0,10a4.17,4.17,0,0,1-4.318-4,4.17,4.17,0,0,1,4.318-4,4.17,4.17,0,0,1,4.318,4A4.17,4.17,0,0,1,10.5,14.5Zm0-6.4a2.5,2.5,0,0,0-2.591,2.4A2.5,2.5,0,0,0,10.5,12.9a2.5,2.5,0,0,0,2.591-2.4A2.5,2.5,0,0,0,10.5,8.1Z'
      />
    </svg>
  )
}
