import React from 'react'
import PropTypes from 'prop-types'

import * as Icons from './Icons'

import { capitalize } from 'utils/string'

function Icon({ name, ...props }) {
  const Current = Icons[capitalize(name)]

  return <Current {...props} />
}

Icon.propTypes = {
  name: PropTypes.string.isRequired
}

export default Icon
