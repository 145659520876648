import { takeLatest, call, put, all } from 'redux-saga/effects'
// import { push } from 'connected-react-router'
import { toast } from 'react-toastify'

import api from 'services/api'

import {
  LaboratoryProfileTypes,
  LaboratoryProfileActions
} from '../ducks/laboratoryProfile'
import { UserActions } from '../ducks/user'

function* mergeProfiles(laboratory) {
  try {
    const currentLaboratory = JSON.parse(localStorage.getItem('@gurumed:user'))

    const updatedLaboratory = {
      ...currentLaboratory,
      laboratorio: {
        ...laboratory
      }
    }

    yield put(UserActions.saveUser(updatedLaboratory))

    localStorage.setItem('@gurumed:user', JSON.stringify(updatedLaboratory))
  } catch (error) {
    console.error(error)
  }
}

function* updateProfilesImages(data) {
  try {
    const currentLaboratory = JSON.parse(localStorage.getItem('@gurumed:user'))

    const updatedLaboratory = {
      ...currentLaboratory,
      laboratorio: {
        ...currentLaboratory.laboratorio,
        images: [...currentLaboratory.laboratorio.images, data]
      }
    }

    yield put(UserActions.saveUser(updatedLaboratory))

    localStorage.setItem('@gurumed:user', JSON.stringify(updatedLaboratory))
  } catch (error) {
    console.error(error)
  }
}

function* updateLaboratoryProfile({ id, data }) {
  try {
    const {
      data: { data: laboratory }
    } = yield call(api.put, `laboratories/${id}`, data)

    yield put(
      LaboratoryProfileActions.updateLaboratoryProfileSuccess(laboratory)
    )

    yield call(mergeProfiles, laboratory)

    toast.success('Perfil atualizado com sucesso!')
  } catch (error) {
    yield put(LaboratoryProfileActions.updateLaboratoryProfileFailure())

    toast.error('Falha ao atualizar perfil, tente novamente!')
  }
}

function* uploadLaboratoryImage({ laboratory, image }) {
  try {
    const {
      data: { data }
    } = yield call(api.post, `laboratories/${laboratory}/images`, {
      image: [image]
    })

    yield put(LaboratoryProfileActions.uploadLaboratoryImageSuccess(data))

    yield call(updateProfilesImages, data)
  } catch (error) {
    yield put(LaboratoryProfileActions.uploadLaboratoryImageFailure())

    toast.error('Falha ao enviar imagem, tente novamente!')
  }
}

// -> Specialties
function* createSpecialty({ id, specialties }) {
  try {
    const {
      data: { data: profile }
    } = yield call(api.put, `/laboratories/${id}`, {
      specialties
    })

    yield put(
      LaboratoryProfileActions.createLaboratorySpecialtySuccess(profile)
    )

    yield call(mergeProfiles, profile)

    toast.success('Especialidade cadastrada com sucesso!')
  } catch (error) {
    yield put(LaboratoryProfileActions.createLaboratorySpecialtyFailure())

    toast.error('Falha ao cadastrar especialidade, tente novamente!')
  }
}

function* updateSpecialty({ id, specialties }) {
  try {
    const {
      data: { data: profile }
    } = yield call(api.put, `/laboratories/${id}`, {
      specialties
    })

    yield put(
      LaboratoryProfileActions.updateLaboratorySpecialtySuccess(profile)
    )

    yield call(mergeProfiles, profile)

    toast.success('Especialidade atualizada com sucesso!')
  } catch (error) {
    yield put(LaboratoryProfileActions.updateLaboratorySpecialtyFailure())

    toast.error('Falha ao atualizar especialidade, tente novamente!')
  }
}

function* deleteSpecialty({ id, specialties }) {
  try {
    const {
      data: { data: profile }
    } = yield call(api.put, `/laboratories/${id}`, {
      specialties
    })

    yield put(
      LaboratoryProfileActions.deleteLaboratorySpecialtySuccess(profile)
    )
    yield put(LaboratoryProfileActions.closeDeleteLaboratorySpecialtyModal())

    yield call(mergeProfiles, profile)

    toast.success('Especialidade removida com sucesso!')
  } catch (error) {
    yield put(LaboratoryProfileActions.deleteLaboratorySpecialtyFailure())

    toast.error('Falha ao remover especialidade, tente novamente!')
  }
}

// -> Procedures
function* createProcedure({ id, procedures }) {
  try {
    const {
      data: { data: profile }
    } = yield call(api.put, `/laboratories/${id}`, {
      procedures
    })

    yield put(
      LaboratoryProfileActions.createLaboratoryProcedureSuccess(profile)
    )

    yield call(mergeProfiles, profile)

    toast.success('Procedimento cadastrado com sucesso!')
  } catch (error) {
    yield put(LaboratoryProfileActions.createLaboratoryProcedureFailure())

    toast.error('Falha ao cadastrar procedimento, tente novamente!')
  }
}

function* updateProcedure({ id, procedures }) {
  try {
    const {
      data: { data: profile }
    } = yield call(api.put, `/laboratories/${id}`, {
      procedures
    })

    yield put(
      LaboratoryProfileActions.updateLaboratoryProcedureSuccess(profile)
    )

    yield call(mergeProfiles, profile)

    toast.success('Procedimento atualizado com sucesso!')
  } catch (error) {
    yield put(LaboratoryProfileActions.updateLaboratoryProcedureFailure())

    toast.error('Falha ao atualizar procedimento, tente novamente!')
  }
}

function* deleteProcedure({ id, procedures }) {
  try {
    const {
      data: { data: profile }
    } = yield call(api.put, `/laboratories/${id}`, {
      procedures
    })

    yield put(
      LaboratoryProfileActions.deleteLaboratoryProcedureSuccess(profile)
    )
    yield put(LaboratoryProfileActions.closeDeleteLaboratoryProcedureModal())

    yield call(mergeProfiles, profile)

    toast.success('Procedimento removido com sucesso!')
  } catch (error) {
    yield put(LaboratoryProfileActions.deleteLaboratoryProcedureFailure())

    toast.error('Falha ao remover procedimento, tente novamente!')
  }
}

// -> Exams
function* createExam({ id, exams }) {
  try {
    const {
      data: { data: profile }
    } = yield call(api.put, `/laboratories/${id}`, {
      exams
    })

    yield put(LaboratoryProfileActions.createLaboratoryExamSuccess(profile))

    yield call(mergeProfiles, profile)

    toast.success('Exame cadastrado com sucesso!')
  } catch (error) {
    yield put(LaboratoryProfileActions.createLaboratoryExamFailure())

    toast.error('Falha ao cadastrar exame, tente novamente!')
  }
}

function* updateExam({ id, exams }) {
  try {
    const {
      data: { data: profile }
    } = yield call(api.put, `/laboratories/${id}`, {
      exams
    })

    yield put(LaboratoryProfileActions.updateLaboratoryExamSuccess(profile))

    yield call(mergeProfiles, profile)

    toast.success('Exame atualizado com sucesso!')
  } catch (error) {
    yield put(LaboratoryProfileActions.updateLaboratoryExamFailure())

    toast.error('Falha ao atualizar exame, tente novamente!')
  }
}

function* deleteExam({ id, exams }) {
  try {
    const {
      data: { data: profile }
    } = yield call(api.put, `/laboratories/${id}`, {
      exams
    })

    yield put(LaboratoryProfileActions.deleteLaboratoryExamSuccess(profile))
    yield put(LaboratoryProfileActions.closeDeleteLaboratoryExamModal())

    yield call(mergeProfiles, profile)

    toast.success('Exame removido com sucesso!')
  } catch (error) {
    yield put(LaboratoryProfileActions.deleteLaboratoryExamFailure())

    toast.error('Falha ao remover exame, tente novamente!')
  }
}

// -> Faq
function* createQuestion({ id, faq }) {
  try {
    const {
      data: { data: profile }
    } = yield call(api.put, `/laboratories/${id}`, {
      faq
    })

    yield put(LaboratoryProfileActions.createLaboratoryQuestionSuccess(profile))

    yield call(mergeProfiles, profile)

    toast.success('Pergunta/resposta cadastrada com sucesso!')
  } catch (error) {
    yield put(LaboratoryProfileActions.createLaboratoryQuestionFailure())

    toast.error('Falha ao cadastrar pergunta/resposta, tente novamente!')
  }
}

function* updateQuestion({ id, faq }) {
  try {
    const {
      data: { data: profile }
    } = yield call(api.put, `/laboratories/${id}`, {
      faq
    })

    yield put(LaboratoryProfileActions.updateLaboratoryQuestionSuccess(profile))

    yield call(mergeProfiles, profile)

    toast.success('Pergunta/resposta atualizada com sucesso!')
  } catch (error) {
    yield put(LaboratoryProfileActions.updateLaboratoryQuestionFailure())

    toast.error('Falha ao atualizar pergunta/resposta, tente novamente!')
  }
}

function* deleteQuestion({ id, faq }) {
  try {
    const {
      data: { data: profile }
    } = yield call(api.put, `/laboratories/${id}`, {
      faq
    })

    yield put(LaboratoryProfileActions.deleteLaboratoryQuestionSuccess(profile))
    yield put(LaboratoryProfileActions.closeDeleteLaboratoryQuestionModal())

    yield call(mergeProfiles, profile)

    toast.success('Pergunta/resposta removida com sucesso!')
  } catch (error) {
    yield put(LaboratoryProfileActions.deleteLaboratoryQuestionFailure())

    toast.error('Falha ao remover pergunta/resposta, tente novamente!')
  }
}

// -> Location
function* createLocation({ id, locations }) {
  try {
    const {
      data: { data: profile }
    } = yield call(api.put, `/laboratories/${id}`, {
      locations
    })

    yield put(LaboratoryProfileActions.createLaboratoryLocationSuccess(profile))

    yield call(mergeProfiles, profile)

    toast.success('Unidade cadastrada com sucesso!')
  } catch (error) {
    yield put(LaboratoryProfileActions.createLaboratoryLocationFailure())

    toast.error('Falha ao cadastrar unidade, tente novamente!')
  }
}

function* updateLocation({ id, locations }) {
  try {
    const {
      data: { data: profile }
    } = yield call(api.put, `/laboratories/${id}`, {
      locations
    })

    yield put(LaboratoryProfileActions.updateLaboratoryLocationSuccess(profile))

    yield call(mergeProfiles, profile)

    toast.success('Unidade atualizada com sucesso!')
  } catch (error) {
    yield put(LaboratoryProfileActions.updateLaboratoryLocationFailure())

    toast.error('Falha ao atualizar unidade, tente novamente!')
  }
}

function* deleteLocation({ id, locations }) {
  try {
    const {
      data: { data: profile }
    } = yield call(api.put, `/laboratories/${id}`, {
      locations
    })

    yield put(LaboratoryProfileActions.deleteLaboratoryLocationSuccess(profile))
    yield put(LaboratoryProfileActions.closeDeleteLaboratoryLocationModal())

    yield call(mergeProfiles, profile)

    toast.success('Unidade removida com sucesso!')
  } catch (error) {
    yield put(LaboratoryProfileActions.deleteLaboratoryLocationFailure())

    toast.error('Falha ao remover unidade, tente novamente!')
  }
}

// -> Settings
function* updateSettings({ laboratory, configs }) {
  try {
    const {
      data: { data: profile }
    } = yield call(api.put, `/laboratories/${laboratory}`, {
      configs
    })

    yield put(LaboratoryProfileActions.updateLaboratorySettingsSuccess(profile))

    yield call(mergeProfiles, profile)

    toast.success('Configurações atualizadas com sucesso!')
  } catch (error) {
    yield put(LaboratoryProfileActions.updateLaboratorySettingsFailure())

    toast.error('Falha ao atualizar configurações, tente novamente!')
  }
}

export default all([
  takeLatest(
    LaboratoryProfileTypes.UPDATE_LABORATORY_PROFILE_REQUEST,
    updateLaboratoryProfile
  ),
  takeLatest(
    LaboratoryProfileTypes.UPLOAD_LABORATORY_IMAGE_REQUEST,
    uploadLaboratoryImage
  ),

  // -> Specialties
  takeLatest(
    LaboratoryProfileTypes.CREATE_LABORATORY_SPECIALTY_REQUEST,
    createSpecialty
  ),
  takeLatest(
    LaboratoryProfileTypes.UPDATE_LABORATORY_SPECIALTY_REQUEST,
    updateSpecialty
  ),
  takeLatest(
    LaboratoryProfileTypes.DELETE_LABORATORY_SPECIALTY_REQUEST,
    deleteSpecialty
  ),

  // -> Procedures
  takeLatest(
    LaboratoryProfileTypes.CREATE_LABORATORY_PROCEDURE_REQUEST,
    createProcedure
  ),
  takeLatest(
    LaboratoryProfileTypes.UPDATE_LABORATORY_PROCEDURE_REQUEST,
    updateProcedure
  ),
  takeLatest(
    LaboratoryProfileTypes.DELETE_LABORATORY_PROCEDURE_REQUEST,
    deleteProcedure
  ),

  // -> Exams
  takeLatest(LaboratoryProfileTypes.CREATE_LABORATORY_EXAM_REQUEST, createExam),
  takeLatest(LaboratoryProfileTypes.UPDATE_LABORATORY_EXAM_REQUEST, updateExam),
  takeLatest(LaboratoryProfileTypes.DELETE_LABORATORY_EXAM_REQUEST, deleteExam),

  // -> Faq
  takeLatest(
    LaboratoryProfileTypes.CREATE_LABORATORY_QUESTION_REQUEST,
    createQuestion
  ),
  takeLatest(
    LaboratoryProfileTypes.UPDATE_LABORATORY_QUESTION_REQUEST,
    updateQuestion
  ),
  takeLatest(
    LaboratoryProfileTypes.DELETE_LABORATORY_QUESTION_REQUEST,
    deleteQuestion
  ),

  // -> Locations
  takeLatest(
    LaboratoryProfileTypes.CREATE_LABORATORY_LOCATION_REQUEST,
    createLocation
  ),
  takeLatest(
    LaboratoryProfileTypes.UPDATE_LABORATORY_LOCATION_REQUEST,
    updateLocation
  ),
  takeLatest(
    LaboratoryProfileTypes.DELETE_LABORATORY_LOCATION_REQUEST,
    deleteLocation
  ),

  // -> Settings
  takeLatest(
    LaboratoryProfileTypes.UPDATE_LABORATORY_SETTINGS_REQUEST,
    updateSettings
  )
])
