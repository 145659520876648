import { takeLatest, call, put, all } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { toast } from 'react-toastify'

import {
  ProfessionalsTypes,
  ProfessionalsActions
} from '../ducks/professionals'

import api from 'services/api'

function* getProfessionals({ clinic }) {
  try {
    const { data } = yield call(api.get, `/clinic/${clinic}/staff`)

    const professionals = data[0].clinicalStaff

    yield put(ProfessionalsActions.getProfessionalsSuccess(professionals))
  } catch (error) {
    yield put(ProfessionalsActions.getProfessionalsFailure())

    toast.error('Falha ao buscar profissionais, tente novamente!')
  }
}

function* createProfessionals({ clinic, professional }) {
  try {
    const data = {
      name: professional.name,
      register: {
        register: professional.register,
        uf: professional.uf,
        number: professional.number
      },
      specialties: [{ title: professional.specialty }]
    }

    const {
      data: { professional: professionalData }
    } = yield call(api.post, 'professionals/out/true', data)

    const clinicProfessional = {
      profile: professionalData?.[0]?._id ?? professionalData?._id,
      ...professional
    }

    yield call(api.put, `/clinics/${clinic}/professional`, clinicProfessional)

    yield put(ProfessionalsActions.createProfessionalsSuccess())

    toast.success('Profissional adicionado com sucesso!')

    yield put(push('/clinic/professionals'))
  } catch (error) {
    yield put(ProfessionalsActions.createProfessionalsFailure())

    toast.error('Falha ao adicionar profissional, tente novamente!')
  }
}

function* updateProfessionals({ clinic, professional, profile }) {
  try {
    yield call(api.put, `/clinics/${clinic}/professional`, {
      ...professional,
      profile
    })

    yield put(ProfessionalsActions.updateProfessionalsSuccess())

    toast.success('Profissional atualizado com sucesso!')

    yield put(push('/clinic/professionals'))
  } catch (error) {
    yield put(ProfessionalsActions.updateProfessionalsFailure())

    toast.error('Falha ao atualizar profissional, tente novamente!')
  }
}

function* deleteProfessionals({ clinic, professional }) {
  try {
    yield call(api.delete, `/clinics/${clinic}/professional`, {
      data: { professional_id: professional }
    })

    yield put(ProfessionalsActions.deleteProfessionalsSuccess(professional))
    yield put(ProfessionalsActions.closeDeleteProfessionalsModal())
  } catch (error) {
    yield put(ProfessionalsActions.deleteProfessionalsFailure())

    toast.error('Falha ao remover profissional, tente novamente!')
  }
}

export default all([
  takeLatest(ProfessionalsTypes.GET_PROFESSIONALS_REQUEST, getProfessionals),
  takeLatest(
    ProfessionalsTypes.CREATE_PROFESSIONALS_REQUEST,
    createProfessionals
  ),
  takeLatest(
    ProfessionalsTypes.UPDATE_PROFESSIONALS_REQUEST,
    updateProfessionals
  ),
  takeLatest(
    ProfessionalsTypes.DELETE_PROFESSIONALS_REQUEST,
    deleteProfessionals
  )
])
