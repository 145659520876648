import Immutable from 'seamless-immutable'
import { createActions, createReducer } from 'reduxsauce'

const initialState = Immutable({
  reports: null,
  loading: {
    reports: false,
    actions: false
  }
})

const { Types, Creators } = createActions({
  getReportsRequest: ['id', 'context'],
  getReportsSuccess: ['reports'],
  getReportsFailure: [],

  clearReportsState: []
})

const getReportsRequest = state =>
  state.merge({
    loading: {
      ...state.loading,
      reports: true
    }
  })

const getReportsSuccess = (state, { reports }) =>
  state.merge({
    reports,
    loading: {
      ...state.loading,
      reports: false
    }
  })

const getReportsFailure = state =>
  state.merge({
    loading: {
      ...state.loading,
      reports: false
    }
  })

const reports = createReducer(initialState, {
  [Types.GET_REPORTS_REQUEST]: getReportsRequest,
  [Types.GET_REPORTS_SUCCESS]: getReportsSuccess,
  [Types.GET_REPORTS_FAILURE]: getReportsFailure,

  [Types.CLEAR_REPORTS_STATE]: () => initialState
})

export const ReportsTypes = Types
export const ReportsActions = Creators

export default reports
