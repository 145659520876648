import Immutable from 'seamless-immutable'
import { createActions, createReducer } from 'reduxsauce'

const initialState = Immutable({
  profile: JSON.parse(localStorage.getItem('@gurumed:user')) || {},

  // -> Specialties
  selectedSpecialty: null,
  specialtiesSearch: null,
  deleteSpecialtyModal: {
    isOpen: false,
    specialty: null
  },

  // -> Procedures
  selectedProcedure: null,
  proceduresSearch: null,
  deleteProcedureModal: {
    isOpen: false,
    procedure: null
  },

  // -> Examms
  selectedExam: null,
  examsSearch: null,
  deleteExamModal: {
    isOpen: false,
    exam: null
  },

  loading: {
    profile: false,
    avatar: false,
    images: false,
    actions: false,
    modal: false
  },

  success: {
    images: false
  }
})

const { Types, Creators } = createActions({
  updateClinicProfileRequest: ['id', 'data'],
  updateClinicProfileSuccess: ['profile'],
  updateClinicProfileFailure: [],

  uploadClinicImageRequest: ['clinic', 'image'],
  uploadClinicImageSuccess: ['image'],
  uploadClinicImageFailure: [],

  removeClinicImage: ['id'],

  // -> Specialties
  createClinicSpecialtyRequest: ['id', 'specialties'],
  createClinicSpecialtySuccess: ['profile'],
  createClinicSpecialtyFailure: [],

  updateClinicSpecialtyRequest: ['id', 'specialties'],
  updateClinicSpecialtySuccess: ['profile'],
  updateClinicSpecialtyFailure: [],

  deleteClinicSpecialtyRequest: ['id', 'specialties'],
  deleteClinicSpecialtySuccess: ['profile'],
  deleteClinicSpecialtyFailure: [],

  setSelectedClinicSpecialty: ['specialty'],
  setClinicSpecialtiesSearch: ['value'],
  setDeleteClinicSpecialtyModal: ['specialty'],
  openDeleteClinicSpecialtyModal: [],
  closeDeleteClinicSpecialtyModal: [],

  // -> Procedures
  createClinicProcedureRequest: ['id', 'procedures'],
  createClinicProcedureSuccess: ['profile'],
  createClinicProcedureFailure: [],

  updateClinicProcedureRequest: ['id', 'procedures'],
  updateClinicProcedureSuccess: ['profile'],
  updateClinicProcedureFailure: [],

  deleteClinicProcedureRequest: ['id', 'procedures'],
  deleteClinicProcedureSuccess: ['profile'],
  deleteClinicProcedureFailure: [],

  setSelectedClinicProcedure: ['procedure'],
  setClinicProceduresSearch: ['value'],
  setDeleteClinicProcedureModal: ['procedure'],
  openDeleteClinicProcedureModal: [],
  closeDeleteClinicProcedureModal: [],

  // -> Exams
  createClinicExamRequest: ['id', 'exams'],
  createClinicExamSuccess: ['profile'],
  createClinicExamFailure: [],

  updateClinicExamRequest: ['id', 'exams'],
  updateClinicExamSuccess: ['profile'],
  updateClinicExamFailure: [],

  deleteClinicExamRequest: ['id', 'exams'],
  deleteClinicExamSuccess: ['profile'],
  deleteClinicExamFailure: [],

  setSelectedClinicExam: ['exam'],
  setClinicExamsSearch: ['value'],
  setDeleteClinicExamModal: ['exam'],
  openDeleteClinicExamModal: [],
  closeDeleteClinicExamModal: [],

  // -> Settings
  updateClinicSettingsRequest: ['clinic', 'configs'],
  updateClinicSettingsSuccess: ['profile'],
  updateClinicSettingsFailure: [],

  saveProfile: ['profile'],
  clearClinicProfileState: []
})

const clinicProfile = createReducer(initialState, {
  [Types.UPDATE_CLINIC_PROFILE_REQUEST]: state =>
    state.merge({ loading: { ...state.loading, profile: true } }),

  [Types.UPDATE_CLINIC_PROFILE_SUCCESS]: (state, { profile }) =>
    state.merge({
      profile: {
        clinica: {
          ...profile
        }
      },
      loading: { ...state.loading, profile: false }
    }),

  [Types.UPDATE_CLINIC_PROFILE_FAILURE]: state =>
    state.merge({ loading: { ...state.loading, profile: false } }),

  [Types.UPLOAD_CLINIC_IMAGE_REQUEST]: state =>
    state.merge({
      loading: { ...state.loading, images: true },
      success: { ...state.success, images: false }
    }),

  [Types.UPLOAD_CLINIC_IMAGE_SUCCESS]: (state, { image }) =>
    state.merge({
      profile: {
        ...state.profile,
        clinica: {
          ...state.profile.clinica,
          images: [...state.profile.clinica.images, image]
        }
      },
      loading: { ...state.loading, images: false },
      success: { ...state.success, images: true }
    }),

  [Types.UPLOAD_CLINIC_IMAGE_FAILURE]: state =>
    state.merge({
      loading: { ...state.loading, images: false },
      success: { ...state.success, images: false }
    }),

  [Types.REMOVE_CLINIC_IMAGE]: (state, { id }) =>
    state.merge({
      profile: {
        ...state.profile,
        clinica: {
          ...state.profile.clinica,
          images: state.profile.clinica.images.filter(
            image => image.imageId !== id
          )
        }
      }
    }),

  // -> Specialties
  [Types.CREATE_CLINIC_SPECIALTY_REQUEST]: state =>
    state.merge({
      loading: {
        ...state.loading,
        actions: true
      }
    }),

  [Types.CREATE_CLINIC_SPECIALTY_SUCCESS]: (state, { profile }) =>
    state.merge({
      selectedSpecialty: null,
      profile: {
        ...state.profile,
        clinica: profile
      },
      loading: {
        ...state.loading,
        actions: false
      }
    }),

  [Types.CREATE_CLINIC_SPECIALTY_FAILURE]: state =>
    state.merge({
      loading: {
        ...state.loading,
        actions: false
      }
    }),

  [Types.UPDATE_CLINIC_SPECIALTY_REQUEST]: state =>
    state.merge({
      loading: {
        ...state.loading,
        actions: true
      }
    }),

  [Types.UPDATE_CLINIC_SPECIALTY_SUCCESS]: (state, { profile }) =>
    state.merge({
      selectedSpecialty: null,
      profile: {
        ...state.profile,
        clinica: profile
      },
      loading: {
        ...state.loading,
        actions: false
      }
    }),

  [Types.UPDATE_CLINIC_SPECIALTY_FAILURE]: state =>
    state.merge({
      loading: {
        ...state.loading,
        actions: false
      }
    }),

  [Types.DELETE_CLINIC_SPECIALTY_REQUEST]: state =>
    state.merge({
      loading: {
        ...state.loading,
        modal: true
      }
    }),

  [Types.DELETE_CLINIC_SPECIALTY_SUCCESS]: (state, { profile }) =>
    state.merge({
      selectedSpecialty: null,
      profile: {
        ...state.profile,
        clinica: profile
      },
      loading: {
        ...state.loading,
        modal: false
      }
    }),

  [Types.DELETE_CLINIC_SPECIALTY_FAILURE]: state =>
    state.merge({
      loading: {
        ...state.loading,
        modal: false
      }
    }),

  [Types.SET_DELETE_CLINIC_SPECIALTY_MODAL]: (state, { specialty }) =>
    state.merge({
      deleteSpecialtyModal: { ...state.deleteSpecialtyModal, specialty }
    }),

  [Types.OPEN_DELETE_CLINIC_SPECIALTY_MODAL]: state =>
    state.merge({
      deleteSpecialtyModal: { ...state.deleteSpecialtyModal, isOpen: true }
    }),

  [Types.CLOSE_DELETE_CLINIC_SPECIALTY_MODAL]: state =>
    state.merge({
      deleteSpecialtyModal: { ...state.deleteSpecialtyModal, isOpen: false }
    }),

  [Types.SET_SELECTED_CLINIC_SPECIALTY]: (state, { specialty }) =>
    state.merge({
      selectedSpecialty: specialty
    }),

  [Types.SET_CLINIC_SPECIALTIES_SEARCH]: (state, { value }) =>
    state.merge({ specialtiesSearch: value }),

  // -> Procedures

  [Types.CREATE_CLINIC_PROCEDURE_REQUEST]: state =>
    state.merge({
      loading: {
        ...state.loading,
        actions: true
      }
    }),

  [Types.CREATE_CLINIC_PROCEDURE_SUCCESS]: (state, { profile }) =>
    state.merge({
      selectedProcedure: null,
      profile: {
        ...state.profile,
        clinica: profile
      },
      loading: {
        ...state.loading,
        actions: false
      }
    }),

  [Types.CREATE_CLINIC_PROCEDURE_FAILURE]: state =>
    state.merge({
      loading: {
        ...state.loading,
        actions: false
      }
    }),

  [Types.UPDATE_CLINIC_PROCEDURE_REQUEST]: state =>
    state.merge({
      loading: {
        ...state.loading,
        actions: true
      }
    }),

  [Types.UPDATE_CLINIC_PROCEDURE_SUCCESS]: (state, { profile }) =>
    state.merge({
      selectedProcedure: null,
      profile: {
        ...state.profile,
        clinica: profile
      },
      loading: {
        ...state.loading,
        actions: false
      }
    }),

  [Types.UPDATE_CLINIC_PROCEDURE_FAILURE]: state =>
    state.merge({
      loading: {
        ...state.loading,
        actions: false
      }
    }),

  [Types.DELETE_CLINIC_PROCEDURE_REQUEST]: state =>
    state.merge({
      loading: {
        ...state.loading,
        modal: true
      }
    }),

  [Types.DELETE_CLINIC_PROCEDURE_SUCCESS]: (state, { profile }) =>
    state.merge({
      selectedProcedure: null,
      profile: {
        ...state.profile,
        clinica: profile
      },
      loading: {
        ...state.loading,
        modal: false
      }
    }),

  [Types.DELETE_CLINIC_PROCEDURE_FAILURE]: state =>
    state.merge({
      loading: {
        ...state.loading,
        modal: false
      }
    }),

  [Types.SET_DELETE_CLINIC_PROCEDURE_MODAL]: (state, { procedure }) =>
    state.merge({
      deleteProcedureModal: { ...state.deleteProcedureModal, procedure }
    }),

  [Types.OPEN_DELETE_CLINIC_PROCEDURE_MODAL]: state =>
    state.merge({
      deleteProcedureModal: { ...state.deleteProcedureModal, isOpen: true }
    }),

  [Types.CLOSE_DELETE_CLINIC_PROCEDURE_MODAL]: state =>
    state.merge({
      deleteProcedureModal: { ...state.deleteProcedureModal, isOpen: false }
    }),

  [Types.SET_SELECTED_CLINIC_PROCEDURE]: (state, { procedure }) =>
    state.merge({
      selectedProcedure: procedure
    }),

  [Types.SET_CLINIC_PROCEDURES_SEARCH]: (state, { value }) =>
    state.merge({ proceduresSearch: value }),

  // -> Exams

  [Types.CREATE_CLINIC_EXAM_REQUEST]: state =>
    state.merge({
      loading: {
        ...state.loading,
        actions: true
      }
    }),

  [Types.CREATE_CLINIC_EXAM_SUCCESS]: (state, { profile }) =>
    state.merge({
      selectedExam: null,
      profile: {
        ...state.profile,
        clinica: profile
      },
      loading: {
        ...state.loading,
        actions: false
      }
    }),

  [Types.CREATE_CLINIC_EXAM_FAILURE]: state =>
    state.merge({
      loading: {
        ...state.loading,
        actions: false
      }
    }),

  [Types.UPDATE_CLINIC_EXAM_REQUEST]: state =>
    state.merge({
      loading: {
        ...state.loading,
        actions: true
      }
    }),

  [Types.UPDATE_CLINIC_EXAM_SUCCESS]: (state, { profile }) =>
    state.merge({
      selectedExam: null,
      profile: {
        ...state.profile,
        clinica: profile
      },
      loading: {
        ...state.loading,
        actions: false
      }
    }),

  [Types.UPDATE_CLINIC_EXAM_FAILURE]: state =>
    state.merge({
      loading: {
        ...state.loading,
        actions: false
      }
    }),

  [Types.DELETE_CLINIC_EXAM_REQUEST]: state =>
    state.merge({
      loading: {
        ...state.loading,
        modal: true
      }
    }),

  [Types.DELETE_CLINIC_EXAM_SUCCESS]: (state, { profile }) =>
    state.merge({
      selectedExam: null,
      profile: {
        ...state.profile,
        clinica: profile
      },
      loading: {
        ...state.loading,
        modal: false
      }
    }),

  [Types.DELETE_CLINIC_EXAM_FAILURE]: state =>
    state.merge({
      loading: {
        ...state.loading,
        modal: false
      }
    }),

  [Types.SET_DELETE_CLINIC_EXAM_MODAL]: (state, { exam }) =>
    state.merge({
      deleteExamModal: { ...state.deleteExamModal, exam }
    }),

  [Types.OPEN_DELETE_CLINIC_EXAM_MODAL]: state =>
    state.merge({
      deleteExamModal: { ...state.deleteExamModal, isOpen: true }
    }),

  [Types.CLOSE_DELETE_CLINIC_EXAM_MODAL]: state =>
    state.merge({
      deleteExamModal: { ...state.deleteExamModal, isOpen: false }
    }),

  [Types.SET_SELECTED_CLINIC_EXAM]: (state, { exam }) =>
    state.merge({
      selectedExam: exam
    }),

  [Types.SET_CLINIC_EXAMS_SEARCH]: (state, { value }) =>
    state.merge({ examsSearch: value }),

  [Types.UPDATE_CLINIC_SETTINGS_REQUEST]: state =>
    state.merge({
      loading: {
        ...state.loading,
        actions: true
      }
    }),

  [Types.UPDATE_CLINIC_SETTINGS_SUCCESS]: (state, { profile }) =>
    state.merge({
      profile: {
        ...state.profile,
        clinica: profile
      },
      loading: {
        ...state.loading,
        actions: false
      }
    }),

  [Types.UPDATE_CLINIC_SETTINGS_FAILURE]: state =>
    state.merge({
      loading: {
        ...state.loading,
        actions: false
      }
    }),

  [Types.SAVE_PROFILE]: (state, { profile }) => state.merge({ profile }),

  [Types.CLEAR_CLINIC_PROFILE_STATE]: state => ({
    profile: state.profile,
    ...initialState
  })
})

export const ClinicProfileTypes = Types
export const ClinicProfileActions = Creators

export default clinicProfile
