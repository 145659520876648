import React, { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Formik } from 'formik'
import { object, string } from 'yup'

import { AuthActions } from 'store/ducks/auth'

import Input from 'components/Input'
import Button from 'components/Button'
import Icon from 'components/Icon'

import {
  Container,
  Wrapper,
  Title,
  Description,
  Form,
  Row,
  Bottom,
  Anchor,
  Forgot
} from './styled'

const initialValues = {
  email: '',
  password: ''
}

const validationSchema = object().shape({
  email: string()
    .email('O campo email deve conter um email válido.')
    .required('O campo email é obrigatório.'),
  password: string()
    .min(3, 'O campo senha deve conter pelo menos 3 caracteres.')
    .required('O campo senha é obrigatório.')
})

function SignIn() {
  const { loading } = useSelector(state => state.auth)
  const dispatch = useDispatch()

  const handleSubmit = useCallback(
    (values, { setSubmitting }) => {
      const { email, password } = values

      dispatch(AuthActions.signInRequest(email, password))

      setSubmitting(false)
    },
    [dispatch]
  )

  return (
    <Container>
      <Wrapper>
        <Title>Seja bem vindo</Title>
        <Description>
          Faça login para acessar seu painel de controle
        </Description>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {() => (
            <Form>
              <Row>
                <Input
                  type='email'
                  name='email'
                  label='Email'
                  variant='primary'
                />
              </Row>

              <Row>
                <Input
                  type='password'
                  name='password'
                  label='Senha'
                  variant='primary'
                />
              </Row>

              <Bottom>
                <Anchor to='/forgot-password'>
                  <Icon name='lock' />
                  <Forgot>Esqueci minha senha</Forgot>
                </Anchor>

                <Button type='submit' variant='primary' loading={loading}>
                  Entrar
                </Button>
              </Bottom>
            </Form>
          )}
        </Formik>
      </Wrapper>
    </Container>
  )
}

export default SignIn
