import React from 'react'

export function Round(props) {
  return (
    <svg
      {...props}
      xmlns='http://www.w3.org/2000/svg'
      width='24.524'
      height='24.524'
      viewBox='0 0 24.524 24.524'
    >
      <defs>
        <style>
          {
            '.round-a{fill:none;}.round-b{fill:#0d5249;stroke:#0d5249;stroke-width:2px;}'
          }
        </style>
      </defs>
      <g transform='translate(0.421 0.104)'>
        <path className='round-a' d='M0,0H24V24H0Z' />
        <path
          className='round-b'
          d='M25.916,17.871H17.871v8.044a1.609,1.609,0,0,1-3.218,0V17.871H6.609a1.609,1.609,0,0,1,0-3.218h8.044V6.609a1.609,1.609,0,0,1,3.218,0v8.044h8.044a1.609,1.609,0,0,1,0,3.218Z'
          transform='translate(-4.421 -4.104)'
        />
      </g>
    </svg>
  )
}
