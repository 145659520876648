import React from 'react'

export function Upload(props) {
  return (
    <svg
      {...props}
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      width='27'
      height='27'
      viewBox='0 0 27 27'
    >
      <defs>
        <style>
          {
            '.upload-a{fill:#0d5249;}.upload-b{fill:none;}.upload-c{clip-path:url(#a);}.upload-d{clip-path:url(#b);}'
          }
        </style>
        <clipPath id='a'>
          <rect className='upload-b' width='27' height='27' />
        </clipPath>
        <clipPath id='b'>
          <path className='upload-a' d='M26.42,26.42H0V0H26.42Z' />
        </clipPath>
      </defs>
      <g className='upload-c'>
        <g className='upload-d'>
          <path
            className='upload-a'
            d='M3.3,4.3V1H5.5V4.3h3.3V6.5H5.5v3.3H3.3V6.5H0V4.3Zm3.3,6.605v-3.3h3.3V4.3h7.706l2.015,2.2h3.49a2.208,2.208,0,0,1,2.2,2.2v13.21a2.208,2.208,0,0,1-2.2,2.2H5.5a2.208,2.208,0,0,1-2.2-2.2V10.908Zm7.706,9.908a5.5,5.5,0,1,0-5.5-5.5A5.506,5.506,0,0,0,14.311,20.815Zm-3.523-5.5a3.523,3.523,0,1,0,3.523-3.523,3.519,3.519,0,0,0-3.523,3.523Z'
            transform='translate(0 0.101)'
          />
        </g>
      </g>
    </svg>
  )
}
