import React from 'react'

export function Professionals(props) {
  return (
    <svg
      {...props}
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      width='24'
      height='24'
      viewBox='0 0 24 24'
    >
      <defs>
        <style>
          {
            '.professionals-a{fill:none;}.professionals-b{clip-path:url(#professionals-a);}'
          }
        </style>
        <clipPath id='professionals-a'>
          <rect className='professionals-a' width='24' height='24' />
        </clipPath>
      </defs>
      <g className='professionals-b'>
        <path className='professionals-a' d='M0,0H24V24H0Z' />
        <g transform='translate(-340.901 -198.001)'>
          <path
            className='professionals-c'
            d='M601.574,200a4.351,4.351,0,1,0,4.348,4.352A4.352,4.352,0,0,0,601.574,200Z'
            transform='translate(-248.696)'
          />
          <path
            className='professionals-c'
            d='M360.847,898.419c0-.045-.009-.182-.01-.206a3.786,3.786,0,0,0-.311-1.2,6.066,6.066,0,0,0-3.1-2.88,5.158,5.158,0,0,1,.069,1.24c-.057.676-.115,1.131-.174,1.441a2.158,2.158,0,0,1,1.008.959,4.24,4.24,0,0,1,.138,2.846.8.8,0,1,1-.744-.244,3.441,3.441,0,0,0-.085-2.24,1.389,1.389,0,0,0-.649-.615l-.016-.009a2.067,2.067,0,0,0-.358-.125,1.483,1.483,0,0,0-1.257.182,3.488,3.488,0,0,0-1.125,1.943.8.8,0,1,1-.774-.121,4.263,4.263,0,0,1,1.46-2.468,2.2,2.2,0,0,1,1.651-.341c.042-.247.095-.643.149-1.276a5.2,5.2,0,0,0-.157-1.526l-.112-.038c-.214-.072-.432-.138-.657-.2a11.661,11.661,0,0,0-5.834,0q-.338.09-.659.2l0,0,0,0a7.04,7.04,0,0,0-.4,2.5,5.405,5.405,0,0,0,.133,1.235,1.738,1.738,0,0,1,.209-.015,1.606,1.606,0,1,1-.939.307,6.016,6.016,0,0,1-.183-1.527,8.584,8.584,0,0,1,.25-2.131,6.091,6.091,0,0,0-3.136,2.9,3.793,3.793,0,0,0-.311,1.2c0,.024-.009.161-.01.206s0,.147,0,.157v3.5a1.257,1.257,0,0,0,1.257,1.257h13.436a1.257,1.257,0,0,0,1.257-1.257v-3.495C360.851,898.565,360.847,898.454,360.847,898.419Z'
            transform='translate(0 -683.214)'
          />
        </g>
      </g>
    </svg>
  )
}
