import React, { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'

import { AuthActions } from 'store/ducks/auth'

import logo from 'images/logo-white.svg'

import { Container, Wrapper, Clinic, Logout } from './styled'

function Header() {
  const { name } = useSelector(state => state.user)
  const dispatch = useDispatch()

  const handleLogout = useCallback(() => {
    dispatch(AuthActions.signOut())
  }, [dispatch])

  return (
    <Container>
      <Wrapper>
        <Link to='/clinic/home'>
          <img src={logo} alt='Gurumed' />
        </Link>

        <Clinic>{name}</Clinic>

        <Logout onClick={handleLogout}>Sair</Logout>
      </Wrapper>
    </Container>
  )
}

export default Header
