import styled, { keyframes } from 'styled-components/macro'
import { theme, withProp } from 'styled-tools'
import { transparentize } from 'polished'

const animation = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

export const Container = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${theme('color.white')};
  transition: all 0.2s linear;
`

export const Animation = styled.span`
  display: inline-block;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  animation: 1s 0.3s ${animation} infinite;
  border: 4px solid ${withProp(theme('color.green'), transparentize(0.6))};
  border-top-color: ${withProp(theme('color.green'), transparentize(0.1))};
`
