import React from 'react'

export function Realized(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='35.47'
      height='30.065'
      viewBox='0 0 35.47 30.065'
    >
      <defs>
        <style>{'.realized-a{fill:#ff690b;}'}</style>
      </defs>
      <g transform='translate(9.619 84.18)'>
        <path
          className='realized-a'
          d='M10.915-54.309A15.031,15.031,0,0,0,25.851-69.244,15.044,15.044,0,0,0,10.9-84.18,15.008,15.008,0,0,0-3.961-70.51a9.6,9.6,0,0,1,1.742-.149,9.174,9.174,0,0,1,8.875,6.984,15.633,15.633,0,0,1,4.244-.581c4.72,0,8.279,1.966,9.6,3.8a12.993,12.993,0,0,1-9.6,4.244,12.805,12.805,0,0,1-5.093-1.072,6.31,6.31,0,0,1-1.057,1.623A14.575,14.575,0,0,0,10.915-54.309ZM10.9-66.8c-2.859-.015-5.063-2.427-5.078-5.614A5.3,5.3,0,0,1,10.9-77.911a5.309,5.309,0,0,1,5.078,5.495C15.978-69.229,13.76-66.787,10.9-66.8ZM-2.218-54.115a7.468,7.468,0,0,0,7.4-7.4,7.442,7.442,0,0,0-7.4-7.416,7.452,7.452,0,0,0-7.4,7.416A7.439,7.439,0,0,0-2.218-54.115Zm-3.9-6.612a.818.818,0,0,1-.8-.789.82.82,0,0,1,.8-.8h7.8a.82.82,0,0,1,.8.8.818.818,0,0,1-.8.789Z'
        />
      </g>
    </svg>
  )
}
