import React from 'react'

export function Delete(props) {
  return (
    <svg
      {...props}
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      width='24'
      height='24'
      viewBox='0 0 24 24'
    >
      <defs>
        <style>
          {
            '.delete-a,.delete-c{fill:none;}.delete-b{clip-path:url(#delete-a);}.delete-c{opacity:0.87;}.delete-d{fill:red;}'
          }
        </style>
        <clipPath id='a'>
          <rect className='delete-a' width='24' height='24' />
        </clipPath>
      </defs>
      <g className='delete-b'>
        <path className='delete-c' d='M0,0H24V24H0Z' />
        <path
          className='delete-d'
          d='M12,2A10,10,0,1,0,22,12,9.991,9.991,0,0,0,12,2Zm4.3,14.3a1,1,0,0,1-1.41,0L12,13.41,9.11,16.3A1,1,0,1,1,7.7,14.89L10.59,12,7.7,9.11A1,1,0,0,1,9.11,7.7L12,10.59,14.89,7.7A1,1,0,0,1,16.3,9.11L13.41,12l2.89,2.89a1.014,1.014,0,0,1,0,1.41Z'
        />
      </g>
    </svg>
  )
}
