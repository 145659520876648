import styled, { css } from 'styled-components/macro'
import { theme, ifProp } from 'styled-tools'
import { NavLink } from 'react-router-dom'

import Icon from 'components/Icon'

export const Container = styled.nav`
  width: 230px;
  min-width: 230px;
  margin-right: 58px;
`

export const List = styled.ul``

export const Item = styled.li`
  display: flex;

  &:not(:last-child) {
    margin-bottom: ${theme('spacing.default')};
  }
`

export const Symbol = styled(Icon)`
  margin-right: ${theme('spacing.default')};
  /* fill: ${theme('color.text')}; */
`

export const Link = styled(NavLink)`
  display: flex;
  align-items: center;
  flex: 1;
  height: 60px;
  padding-left: ${theme('spacing.default')};
  padding-right: ${theme('spacing.default')};
  background-color: ${theme('color.white')};
  border: ${theme('border.tertiary')};
  border-radius: ${theme('radius.lg')};

  &.active {
    color: ${theme('color.green')};
    background-color: ${theme('color.lightGrey')};
    font-weight: 700;

    ${Symbol} {
      fill: ${theme('color.orange')};
    }
  }

  ${ifProp(
    'dropdown',
    css`
      background-color: ${theme('color.lightGrey')};

      &.active {
        color: ${theme('color.white')};
        background-color: ${theme('color.grey')};
        font-weight: 700;
      }
    `
  )}
`

export const Dropdown = styled.div`
  width: 100%;
`

export const Button = styled.button`
  display: flex;
  align-items: center;
  flex: 1;
  height: 60px;
  width: 100%;
  padding-left: ${theme('spacing.default')};
  padding-right: ${theme('spacing.default')};
  background-color: ${theme('color.white')};
  border: ${theme('border.tertiary')};
  border-radius: ${theme('radius.lg')};
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  ${ifProp(
    'isActive',
    css`
      font-weight: 700;
      color: ${theme('color.green')};
      background-color: ${theme('color.lightGrey')};
      margin-bottom: ${theme('spacing.default')};

      ${Symbol} {
        fill: ${theme('color.orange')};
      }
    `
  )}
`

export const Arrow = styled.span`
  margin-left: auto;
  line-height: 0;
  transform: rotate(-90deg);
  transition: all 0.1s linear;

  ${Symbol} {
    margin-right: 0;
    fill: ${theme('color.orange')};
  }

  ${ifProp(
    'isActive',
    css`
      transform: rotate(0deg);
    `
  )}
`

export const Content = styled.ul``
