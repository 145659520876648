import styled from 'styled-components/macro'
import { theme } from 'styled-tools'

export const Container = styled.section`
  padding-top: 68px;
  padding-bottom: 26px;
  height: 100%;
  background-color: ${theme('color.white')};
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  max-width: 745px;
  margin-left: auto;
  margin-right: auto;
  padding-left: ${theme('spacing.default')};
  padding-right: ${theme('spacing.default')};
`

export const Header = styled.header`
  display: flex;
  justify-content: center;
`

export const Logo = styled.div``

export const Footer = styled.footer``

export const Message = styled.p`
  text-align: center;
  color: ${theme('color.grey')};
`
