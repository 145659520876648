import Immutable from 'seamless-immutable'
import { createActions, createReducer } from 'reduxsauce'
import { getMonth, getYear } from 'date-fns'

const today = new Date()

const initialState = Immutable({
  schedules: [],
  selectedSchedule: null,
  selectedDate: null,
  month: String(getMonth(today)),
  year: String(getYear(today)),
  currentMonth: String(getMonth(today)),
  currentYear: String(getYear(today)),
  nextMonth: null,
  nextYear: null,
  schedulesSearch: '',
  currentProfessional: null,
  currentClinic: null,
  createSchedule: true,
  disableButton: true,
  isUpdating: false,
  scheduleDetails: {
    name: null,
    date: null
  },
  deleteModal: {
    isOpen: false
  },
  cancelModal: {
    isOpen: false
  },
  monthModal: {
    isOpen: false,
    direction: null
  },
  loading: {
    schedules: false,
    actions: false
  }
})

const { Types, Creators } = createActions(
  {
    getSchedulesRequest: ['context', 'id'],
    getSchedulesSuccess: ['schedules'],
    getSchedulesFailure: [],

    createSchedulesRequest: ['context', 'schedule', 'redirect'],
    createSchedulesSuccess: ['schedule'],
    createSchedulesFailure: [],

    updateSchedulesRequest: ['context', 'id', 'schedule', 'redirect'],
    updateSchedulesSuccess: ['schedule'],
    updateSchedulesFailure: [],

    deleteSchedulesRequest: ['schedule', 'navigate'],
    deleteSchedulesSuccess: ['schedule'],
    deleteSchedulesFailure: [],

    setSelectedSchedule: ['schedule'],
    clearSelectedSchedule: [],

    setSelectedDate: ['date'],
    clearSelectedDate: [],

    openCancelModal: [],
    closeCancelModal: [],

    setMonthDirectionModal: ['direction'],
    openMonthModal: [],
    closeMonthModal: [],

    setMonth: ['month'],
    setYear: ['year'],
    setCurrentMonth: ['currentMonth'],
    setCurrentYear: ['currentYear'],

    setDeleteSchedulesModal: ['schedule'],
    openDeleteSchedulesModal: [],
    closeDeleteSchedulesModal: [],

    clearSchedulesState: [],

    setSchedulesSearch: ['schedulesSearch'],

    setCurrentProfessional: ['currentProfessional'],
    setCurrentClinic: ['currentClinic'],
    setNextMonth: ['nextMonth'],
    setNextYear: ['nextYear'],
    setCreateSchedule: ['createSchedule'],

    setDisableButton: ['disableButton'],

    setIsUpdating: ['isUpdating'],

    setScheduleDetails: ['scheduleDetails']
  },
  { prefix: 'schedules/' }
)

const schedules = createReducer(initialState, {
  [Types.GET_SCHEDULES_REQUEST]: state =>
    state.merge({
      loading: { ...state.loading, schedules: true }
    }),

  [Types.GET_SCHEDULES_SUCCESS]: (state, { schedules }) =>
    state.merge({
      schedules,
      loading: { ...state.loading, schedules: false }
    }),

  [Types.GET_SCHEDULES_FAILURE]: state =>
    state.merge({
      loading: { ...state.loading, schedules: false }
    }),

  [Types.CREATE_SCHEDULES_REQUEST]: state =>
    state.merge({
      loading: { ...state.loading, actions: true }
    }),

  [Types.CREATE_SCHEDULES_SUCCESS]: (state, { schedule }) =>
    state.merge({
      schedules: [...state.schedules, schedule],
      loading: { ...state.loading, actions: false },
      monthModal: { ...state.monthModal, isOpen: false }
    }),

  [Types.CREATE_SCHEDULES_FAILURE]: state =>
    state.merge({
      loading: { ...state.loading, actions: false },
      monthModal: { ...state.monthModal, isOpen: false }
    }),

  [Types.UPDATE_SCHEDULES_REQUEST]: state =>
    state.merge({
      loading: { ...state.loading, actions: true }
    }),

  [Types.UPDATE_SCHEDULES_SUCCESS]: (state, { schedule }) =>
    state.merge({
      schedules: state.schedules.map(currentSchedule =>
        currentSchedule._id === schedule._id ? schedule : currentSchedule
      ),
      loading: { ...state.loading, actions: false },
      monthModal: { ...state.monthModal, isOpen: false }
    }),

  [Types.UPDATE_SCHEDULES_FAILURE]: state =>
    state.merge({
      loading: { ...state.loading, actions: false },
      monthModal: { ...state.monthModal, isOpen: false }
    }),

  [Types.DELETE_SCHEDULES_REQUEST]: state =>
    state.merge({ loading: { ...state.loading, actions: true } }),

  [Types.DELETE_SCHEDULES_SUCCESS]: (state, { schedule }) =>
    state.merge({
      schedules: state.schedules.filter(current => current._id !== schedule),
      loading: { ...state.loading, actions: false },
      monthModal: state.monthModal.merge({ isOpen: false })
    }),

  [Types.DELETE_SCHEDULES_FAILURE]: state =>
    state.merge({
      loading: { ...state.loading, actions: false }
    }),

  [Types.SET_SELECTED_SCHEDULE]: (state, { schedule }) =>
    state.merge({
      selectedSchedule: schedule
    }),

  [Types.CLEAR_SELECTED_SCHEDULE]: state =>
    state.merge({
      selectedSchedule: null
    }),

  [Types.SET_SELECTED_DATE]: (state, { date }) =>
    state.merge({
      selectedDate: date
    }),

  [Types.CLEAR_SELECTED_DATE]: state =>
    state.merge({
      selectedDate: null
    }),

  [Types.OPEN_CANCEL_MODAL]: state =>
    state.merge({
      cancelModal: { isOpen: true }
    }),

  [Types.CLOSE_CANCEL_MODAL]: state =>
    state.merge({
      cancelModal: { isOpen: false }
    }),

  [Types.SET_MONTH_DIRECTION_MODAL]: (state, { direction }) =>
    state.merge({
      monthModal: { ...state.monthModal, direction }
    }),

  [Types.OPEN_MONTH_MODAL]: state =>
    state.merge({
      monthModal: { ...state.monthModal, isOpen: true }
    }),

  [Types.CLOSE_MONTH_MODAL]: state =>
    state.merge({
      monthModal: { ...state.monthModal, isOpen: false }
    }),

  [Types.SET_MONTH]: (state, { month }) =>
    state.merge({
      month
    }),

  [Types.SET_YEAR]: (state, { year }) =>
    state.merge({
      year
    }),

  [Types.SET_CURRENT_MONTH]: (state, { currentMonth }) =>
    state.merge({
      currentMonth
    }),

  [Types.SET_CURRENT_YEAR]: (state, { currentYear }) =>
    state.merge({
      currentYear
    }),

  [Types.SET_DELETE_SCHEDULES_MODAL]: (state, { schedule }) =>
    state.merge({
      deleteModal: { ...state.deleteModal, schedule }
    }),

  [Types.OPEN_DELETE_SCHEDULES_MODAL]: state =>
    state.merge({
      deleteModal: { ...state.deleteModal, isOpen: true }
    }),

  [Types.CLOSE_DELETE_SCHEDULES_MODAL]: state =>
    state.merge({
      deleteModal: { ...state.deleteModal, isOpen: false }
    }),

  [Types.CLEAR_SCHEDULES_STATE]: state => initialState,

  [Types.SET_SCHEDULES_SEARCH]: (state, { schedulesSearch }) =>
    state.merge({
      schedulesSearch
    }),

  [Types.SET_CURRENT_PROFESSIONAL]: (state, { currentProfessional }) =>
    state.merge({
      currentProfessional
    }),

  [Types.SET_CURRENT_CLINIC]: (state, { currentClinic }) =>
    state.merge({
      currentClinic
    }),

  [Types.SET_NEXT_MONTH]: (state, { nextMonth }) =>
    state.merge({
      nextMonth
    }),

  [Types.SET_NEXT_YEAR]: (state, { nextYear }) =>
    state.merge({
      nextYear
    }),

  [Types.SET_CREATE_SCHEDULE]: (state, { createSchedule }) =>
    state.merge({
      createSchedule
    }),

  [Types.SET_DISABLE_BUTTON]: (state, { disableButton }) =>
    state.merge({
      disableButton
    }),

  [Types.SET_IS_UPDATING]: (state, { isUpdating }) =>
    state.merge({ isUpdating }),

  [Types.SET_SCHEDULE_DETAILS]: (state, { scheduleDetails }) =>
    state.merge({
      scheduleDetails: state.scheduleDetails.merge(scheduleDetails)
    })
})

export const SchedulesTypes = Types
export const SchedulesActions = Creators

export default schedules
