import React from 'react'
import PropTypes from 'prop-types'

import logo from 'images/logo.svg'

import { Container, Wrapper, Header, Logo, Footer, Message } from './styled'

function Auth({ children }) {
  return (
    <Container>
      <Wrapper>
        <Header>
          <Logo>
            <img src={logo} alt='Gurumed' />
          </Logo>
        </Header>

        {children}

        <Footer>
          <Message>
            © 2021 – Gurumed é um projeto da uxmob – tecnologia e inovação.
            CNPJ: 40.091.369/0001-71. Edifício Boulevard Empresarial, rua
            Ewerton Visco, 290 – Sala 1901 – Salvador, BA – 41820-022
          </Message>
        </Footer>
      </Wrapper>
    </Container>
  )
}

Auth.propTypes = {
  children: PropTypes.node.isRequired
}

export default Auth
