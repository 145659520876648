import React from 'react'

export function Left(props) {
  return (
    <svg
      {...props}
      xmlns='http://www.w3.org/2000/svg'
      width='9.305'
      height='15.792'
      viewBox='0 0 9.305 15.792'
    >
      <defs>
        <style>{'.left-a{fill:#959595;}'}</style>
      </defs>
      <path
        className='left-a'
        d='M17.31,19.789l-5.483-5.483L17.31,8.823a1.409,1.409,0,0,0-1.992-1.992L8.831,13.317a1.407,1.407,0,0,0,0,1.992L15.317,21.8a1.407,1.407,0,0,0,1.992,0,1.438,1.438,0,0,0,0-2.007Z'
        transform='translate(-8.417 -6.418)'
      />
    </svg>
  )
}
