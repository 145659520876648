import React, { useState, useCallback, useMemo, useEffect } from 'react'

import { useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'

import {
  Container,
  List,
  Item,
  Link,
  Symbol,
  Dropdown,
  Button,
  Arrow,
  Content
} from './styled'

const items = {
  clinica: [
    { label: 'Início', icon: 'home', to: '/clinic/home' },
    {
      id: 1,
      isDropdown: true,
      label: 'Perfil clínica',
      icon: 'building',
      url: '/clinic/profile',
      children: [
        { label: 'Dados do perfil', to: '/clinic/profile/general' },
        { label: 'Especialidades', to: '/clinic/profile/specialties' },
        { label: 'Convênios', to: '/clinic/profile/agreements' },
        { label: 'Exames', to: '/clinic/profile/exams' },
        { label: 'Procedimentos', to: '/clinic/profile/procedures' },
        { label: 'Palavra-chave', to: '/clinic/profile/keywords' },
        { label: 'Leia com atenção', to: '/clinic/profile/additional-infos' }
      ]
    },
    {
      label: 'Corpo clínico',
      icon: 'professionals',
      to: '/clinic/professionals'
    },
    { label: 'Configurar agenda', icon: 'schedules', to: '/clinic/schedules' },
    { label: 'Agendamentos', icon: 'appointments', to: '/clinic/appointments' },
    { label: 'Configurações', icon: 'settings', to: '/clinic/settings' }
  ],

  laboratorio: [
    { label: 'Início', icon: 'home', to: '/laboratory/home' },
    {
      id: 1,
      isDropdown: true,
      label: 'Perfil clínica',
      icon: 'building',
      url: '/laboratory/profile',
      children: [
        { label: 'Dados do perfil', to: '/laboratory/profile/general' },
        { label: 'Exames', to: '/laboratory/profile/exams' },
        { label: 'Convênios', to: '/laboratory/profile/agreements' },
        { label: 'Cadastrar dúvidas', to: '/laboratory/profile/faq' },
        { label: 'Nossas unidades', to: '/laboratory/profile/locations' },
        { label: 'Palavra-chave', to: '/laboratory/profile/keywords' },
        {
          label: 'Leia com atenção',
          to: '/laboratory/profile/additional-infos'
        }
      ]
    },
    {
      label: 'Corpo clínico',
      icon: 'professionals',
      to: '/laboratory/professionals'
    },
    {
      label: 'Configurar agenda',
      icon: 'schedules',
      to: '/laboratory/schedules'
    },
    {
      label: 'Agendamentos',
      icon: 'appointments',
      to: '/laboratory/appointments'
    },
    { label: 'Configurações', icon: 'settings', to: '/laboratory/settings' }
  ],

  profissional: [
    { label: 'Início', icon: 'home', to: '/professional/home' },
    {
      id: 1,
      isDropdown: true,
      label: 'Perfil',
      icon: 'building',
      url: '/professional/profile',
      children: [
        { label: 'Dados iniciais', to: '/professional/profile/general' },
        {
          label: 'Locais de atendimento',
          to: '/professional/profile/locations'
        },
        { label: 'Áreas de atuação', to: '/professional/profile/atuations' },
        { label: 'Convênios', to: '/professional/profile/agreements' },
        { label: 'Exames', to: '/professional/profile/exams' },
        { label: 'Procedimentos', to: '/professional/profile/procedures' },
        {
          label: 'Especializações',
          to: '/professional/profile/specializations'
        },
        {
          label: 'Palavra-chave',
          to: '/professional/profile/keywords'
        }
      ]
    },
    {
      label: 'Configurar agenda',
      icon: 'schedules',
      to: '/professional/schedules'
    },
    {
      label: 'Agendamentos',
      icon: 'appointments',
      to: '/professional/appointments'
    }
    // { label: 'Configurações', icon: 'settings', to: '/professional/settings' }
  ]
}

const path = 'profile'

function Nav() {
  const { category } = useSelector(state => state.user)

  const clinicMatch = useRouteMatch('/clinic/profile')
  const laboratoryMatch = useRouteMatch('/laboratory/profile')
  const professionalMatch = useRouteMatch('/professional/profile')

  const matches = useMemo(
    () => ({
      clinica: clinicMatch,
      laboratorio: laboratoryMatch,
      profissional: professionalMatch
    }),
    [clinicMatch, laboratoryMatch, professionalMatch]
  )

  const { id } = useMemo(
    () => items[category].find(item => item.url === matches[category]?.url),
    [category, matches]
  )

  const isOpen = useMemo(() => matches[category]?.url.includes(path), [
    category,
    matches
  ])

  const [activeDropdown, setActiveDropdown] = useState({
    id: null,
    isOpen: false
  })

  useEffect(() => {
    if (id && isOpen) {
      setActiveDropdown({
        id,
        isOpen
      })
    } else {
      setActiveDropdown({
        id: null,
        isOpen: false
      })
    }
  }, [id, isOpen])

  const handleToggleDropdown = useCallback(
    item => {
      const { id } = item

      setActiveDropdown({
        id: id,
        isOpen: !activeDropdown.isOpen
      })
    },
    [activeDropdown.isOpen]
  )

  return (
    <Container>
      <List>
        {items[category].map(item => {
          const isActive =
            activeDropdown.isOpen && activeDropdown.id === item.id

          return (
            <Item key={item.label}>
              {item.isDropdown && (
                <Dropdown>
                  <Button
                    isActive={isActive ? 1 : 0}
                    className={isActive && 'active'}
                    onClick={() => handleToggleDropdown(item)}
                  >
                    <Symbol name={item.icon} />
                    {item.label}

                    <Arrow isActive={isActive ? 1 : 0}>
                      <Symbol name='arrow' />
                    </Arrow>
                  </Button>

                  {isActive && (
                    <Content>
                      {item.children.map(children => (
                        <Item key={children.label}>
                          <Link
                            to={children.to}
                            dropdown={item.isDropdown ? 1 : 0}
                          >
                            {children.label}
                          </Link>
                        </Item>
                      ))}
                    </Content>
                  )}
                </Dropdown>
              )}

              {item.to && (
                <Link to={item.to}>
                  <Symbol name={item.icon} />
                  {item.label}
                </Link>
              )}
            </Item>
          )
        })}
      </List>
    </Container>
  )
}

export default Nav
