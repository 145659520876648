import styled from 'styled-components/macro'
import { theme } from 'styled-tools'

export const Container = styled.header`
  background-color: ${theme('color.lightGreen')};
`

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1444px;
  margin-left: auto;
  margin-right: auto;
  padding-left: ${theme('spacing.default')};
  padding-right: ${theme('spacing.default')};
  height: 100px;
`

export const Clinic = styled.h1`
  font-size: 1.125rem;
  font-weight: 500;
  color: ${theme('color.white')};
`

export const Logout = styled.button`
  height: 40px;
  padding-left: ${theme('spacing.md')};
  padding-right: ${theme('spacing.md')};
  font-size: 0.875rem;
  font-weight: 500;
  text-transform: uppercase;
  color: ${theme('color.white')};
  background-color: transparent;
  border: 2px solid ${theme('colors.white')};
  border-radius: ${theme('radius.lg')};
  cursor: pointer;

  &:focus {
    outline: none;
  }
`
