import { takeLatest, call, put, all } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { toast } from 'react-toastify'

import api from 'services/api'

import { AuthTypes, AuthActions } from 'store/ducks/auth'
import { UserActions } from 'store/ducks/user'
import { ProfessionalProfileActions } from 'store/ducks/professionalProfile'
import { ClinicProfileActions } from 'store/ducks/clinicProfile'
import { LaboratoryProfileActions } from 'store/ducks/laboratoryProfile'

function* signIn({ email, password }) {
  try {
    const { data: response } = yield call(api.post, '/clients/login', {
      email,
      password
    })

    const { token, data } = response
    const { category } = data

    let user = null

    if (category === 'clinica') {
      user = {
        ...data,
        clinica: {
          ...data.clinica
        },
        to: '/clinic/home'
      }

      yield put(ClinicProfileActions.saveProfile(user))
    }

    if (category === 'laboratorio') {
      user = {
        ...data,
        laboratorio: {
          ...data.laboratorio
        },
        to: '/laboratory/home'
      }

      yield put(LaboratoryProfileActions.saveProfile(user))
    }

    if (category === 'profissional') {
      user = {
        ...data,
        profissional: {
          ...data.profissional
        },
        to: '/professional/home'
      }

      yield put(ProfessionalProfileActions.saveProfile(user))
    }

    yield put(UserActions.saveUser(user))
    yield put(AuthActions.signInSuccess(token))

    localStorage.setItem('@gurumed:token', token)
    localStorage.setItem('@gurumed:user', JSON.stringify(user))

    yield put(push(user.to))
  } catch (error) {
    yield put(AuthActions.signInFailure())

    toast.error(
      'Falha ao realizar o login, verifique seus dados e tente novamente.'
    )
  }
}

function* forgotPassword({ email }) {
  try {
    yield call(api.post, '/clients/recovery/password', {
      email
    })

    yield put(AuthActions.forgotPasswordSuccess())
    yield put(AuthActions.showForgotPasswordModal())
  } catch (error) {
    yield put(AuthActions.forgotPasswordFailure())

    toast.error(
      'Falha ao recuperar seu acesso, verifique seus dados e tente novamente.'
    )
  }
}

function* signOut() {
  localStorage.clear()

  yield put(push('/signin'))
}

export default all([
  takeLatest(AuthTypes.SIGN_IN_REQUEST, signIn),
  takeLatest(AuthTypes.FORGOT_PASSWORD_REQUEST, forgotPassword),
  takeLatest(AuthTypes.SIGN_OUT, signOut)
])
