import React from 'react'

export function Add(props) {
  return (
    <svg
      {...props}
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      width='35'
      height='35'
      viewBox='0 0 35 35'
    >
      <defs>
        <style>
          {
            '.add-a,.add-b{fill:none;}.add-b{clip-rule:evenodd;}.add-c{clip-path:url(#add-a);}.add-d{clip-path:url(#add-b);}.add-e{clip-path:url(#add-c);}.add-f{clip-path:url(#add-d);}.add-g{clip-path:url(#add-e);}.add-h{clip-path:url(#add-f);}.add-i{fill:#00c4ae;}.add-j{clip-path:url(#add-g);}.add-k{fill:#fff;}.add-l{fill:rgba(255,255,255,0);}'
          }
        </style>
        <clipPath id='add-a'>
          <rect className='add-a' width='35' height='35' />
        </clipPath>
        <clipPath id='add-b'>
          <path className='add-b' d='M9.856-.287H0V-20H19.712V-.287Z' />
        </clipPath>
        <clipPath id='add-c'>
          <path className='add-a' d='M-1365,805H435V-796H-1365Z' />
        </clipPath>
        <clipPath id='add-d'>
          <rect
            className='add-a'
            width='20'
            height='20'
            transform='translate(0 -20)'
          />
        </clipPath>
        <clipPath id='add-e'>
          <path
            className='add-b'
            d='M19.712-5.445A5.158,5.158,0,0,1,14.557-.287h-9.4A5.158,5.158,0,0,1,0-5.445v-9.4A5.159,5.159,0,0,1,5.155-20h9.4a5.159,5.159,0,0,1,5.156,5.159Z'
          />
        </clipPath>
        <clipPath id='add-f'>
          <path className='add-a' d='M0,0H20V-20H0Z' />
        </clipPath>
        <clipPath id='add-g'>
          <path
            className='add-b'
            d='M8.574-5.036v-3.8H4.751v-2.621H8.574v-3.8h2.553v3.8h3.836v2.621H11.127v3.8Z'
          />
        </clipPath>
      </defs>
      <g className='add-c'>
        <g transform='translate(8 28)'>
          <g className='add-d'>
            <g className='e'>
              <g className='add-f'>
                <g className='add-g'>
                  <g className='add-h'>
                    <path className='add-i' d='M-5,4.713H24.712V-25H-5Z' />
                  </g>
                </g>
              </g>
            </g>
          </g>
          <g className='add-j'>
            <g className='e'>
              <path className='add-k' d='M-.249-.036H19.963V-20.251H-.249Z' />
            </g>
          </g>
        </g>
        <rect className='add-l' width='35' height='35' />
      </g>
    </svg>
  )
}
