import React from 'react'
import PropTypes from 'prop-types'

import { Container, Loading } from './styled'

function Button({
  as,
  to,
  type,
  variant,
  size,
  disabled,
  onClick,
  loading,
  mr,
  mb,
  ml,
  width,
  children
}) {
  return (
    <Container
      as={as}
      to={to}
      type={type}
      variant={variant}
      size={size}
      onClick={onClick}
      disabled={disabled || loading}
      mr={mr}
      mb={mb}
      ml={ml}
      width={width}
    >
      {loading ? <Loading variant={variant} /> : children}
    </Container>
  )
}

Button.defaultProps = {
  type: 'button',
  variant: 'primary',
  size: 'default',
  onClick: () => {},
  disabled: false,
  loading: false,
  ml: '0px',
  mb: '0px',
  mr: '0px'
}

Button.propTypes = {
  as: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.func,
    PropTypes.object
  ]),
  to: PropTypes.string,
  type: PropTypes.oneOf(['button', 'submit']),
  variant: PropTypes.oneOf([
    'primary',
    'secondary',
    'tertiary',
    'quaternary',
    'danger',
    'outline'
  ]),
  size: PropTypes.oneOf(['sm', 'default', 'md']),
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  mr: PropTypes.string,
  mb: PropTypes.string,
  ml: PropTypes.string,
  width: PropTypes.string,
  children: PropTypes.node.isRequired
}

export default Button
