import React, { Suspense, lazy } from 'react'
import { useSelector } from 'react-redux'
import { Switch, Redirect } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'

import Loading from 'components/Loading'

import Route from './Route'

import SignIn from 'pages/SignIn'
import ForgotPassword from 'pages/ForgotPassword'

// import ClinicHome from 'pages/Clinic/Home'
// import Professionals from 'pages/Clinic/Professionals'
// import ClinicGeneral from 'pages/Clinic/Profile/General'
// import ClinicSpecialties from 'pages/Clinic/Profile/Specialties'
// import ClinicAgreements from 'pages/Clinic/Profile/Agreements'
// import ClinicKeywords from 'pages/Clinic/Profile/Keywords'
// import ClinicExams from 'pages/Clinic/Profile/Exams'
// import ClinicProcedures from 'pages/Clinic/Profile/Procedures'
// import ClinicSchedules from 'pages/Clinic/Schedules'
// import ClinicAppointments from 'pages/Clinic/Appointments'
// import ClinicSettings from 'pages/Clinic/Settings'

// import ProfessionalHome from 'pages/Professional/Home'
// import ProfessionalGeneral from 'pages/Professional/Profile/General'
// import ProfessionalLocations from 'pages/Professional/Profile/Locations'
// import ProfessionalAtuations from 'pages/Professional/Profile/Atuations'
// import ProfessionalAgreements from 'pages/Professional/Profile/Agreements'
// import ProfessionalKeywords from 'pages/Professional/Profile/Keywords'
// import ProfessionalExams from 'pages/Professional/Profile/Exams'
// import ProfessionalProcedures from 'pages/Professional/Profile/Procedures'
// import ProfessionalSpecializations from 'pages/Professional/Profile/Specializations'
// import ProfessionalSchedules from 'pages/Professional/Schedules'
// import ProfessionalAppointments from 'pages/Professional/Appointments'
// import ProfessionalSettings from 'pages/Professional/Settings'

import history from 'services/history'

const ROUTES = {
  clinica: [
    {
      key: 'home',
      path: '/clinic/home',
      component: lazy(() => import('pages/Clinic/Home'))
    },

    {
      key: 'reports',
      path: '/clinic/reports',
      component: lazy(() => import('pages/Clinic/Reports'))
    },

    {
      key: 'professionals',
      path: '/clinic/professionals',
      component: lazy(() => import('pages/Clinic/Professionals'))
    },

    {
      key: 'professionals/new',
      path: '/clinic/professionals/new',
      component: lazy(() => import('pages/Clinic/Professionals'))
    },

    {
      key: 'professionals/update',
      path: '/clinic/professionals/update/:id',
      component: lazy(() => import('pages/Clinic/Professionals'))
    },

    {
      key: 'profile/general',
      path: '/clinic/profile/general',
      component: lazy(() => import('pages/Clinic/Profile/General'))
    },

    {
      key: 'profile/specialties',
      path: '/clinic/profile/specialties',
      component: lazy(() => import('pages/Clinic/Profile/Specialties'))
    },

    {
      key: 'profile/agreements',
      path: '/clinic/profile/agreements',
      component: lazy(() => import('pages/Clinic/Profile/Agreements'))
    },

    {
      key: 'profile/keywords',
      path: '/clinic/profile/keywords',
      component: lazy(() => import('pages/Clinic/Profile/Keywords'))
    },

    {
      key: 'profile/additional-infos',
      path: '/clinic/profile/additional-infos',
      component: lazy(() => import('pages/Clinic/Profile/AdditionalInfos'))
    },

    {
      key: 'profile/exams',
      path: '/clinic/profile/exams',
      component: lazy(() => import('pages/Clinic/Profile/Exams'))
    },

    {
      key: 'profile/procedures',
      path: '/clinic/profile/procedures',
      component: lazy(() => import('pages/Clinic/Profile/Procedures'))
    },

    {
      key: 'schedules',
      path: '/clinic/schedules',
      component: lazy(() => import('pages/Clinic/Schedules'))
    },

    {
      key: 'schedules/new',
      path: '/clinic/schedules/new',
      component: lazy(() => import('pages/Clinic/Schedules'))
    },

    {
      key: 'schedules/update',
      path: '/clinic/schedules/update/:id?',
      component: lazy(() => import('pages/Clinic/Schedules'))
    },

    {
      key: 'appointments',
      path: '/clinic/appointments',
      component: lazy(() => import('pages/Clinic/Appointments'))
    },

    {
      key: 'settings',
      path: '/clinic/settings',
      component: lazy(() => import('pages/Clinic/Settings'))
    }
  ],

  laboratorio: [
    {
      key: 'home',
      path: '/laboratory/home',
      component: lazy(() => import('pages/Laboratory/Home'))
    },

    {
      key: 'professionals',
      path: '/laboratory/professionals',
      component: lazy(() => import('pages/Laboratory/Professionals'))
    },

    {
      key: 'professionals/new',
      path: '/laboratory/professionals/new',
      component: lazy(() => import('pages/Laboratory/Professionals'))
    },

    {
      key: 'professionals/update',
      path: '/laboratory/professionals/update/:id',
      component: lazy(() => import('pages/Laboratory/Professionals'))
    },

    {
      key: 'profile/general',
      path: '/laboratory/profile/general',
      component: lazy(() => import('pages/Laboratory/Profile/General'))
    },

    {
      key: 'profile/agreements',
      path: '/laboratory/profile/agreements',
      component: lazy(() => import('pages/Laboratory/Profile/Agreements'))
    },

    {
      key: 'profile/keywords',
      path: '/laboratory/profile/keywords',
      component: lazy(() => import('pages/Laboratory/Profile/Keywords'))
    },

    {
      key: 'profile/additional-infos',
      path: '/laboratory/profile/additional-infos',
      component: lazy(() => import('pages/Laboratory/Profile/AdditionalInfos'))
    },

    {
      key: 'profile/exams',
      path: '/laboratory/profile/exams',
      component: lazy(() => import('pages/Laboratory/Profile/Exams'))
    },

    {
      key: 'profile/locations',
      path: '/laboratory/profile/locations',
      component: lazy(() => import('pages/Laboratory/Profile/Locations'))
    },

    {
      key: 'profile/faq',
      path: '/laboratory/profile/faq',
      component: lazy(() => import('pages/Laboratory/Profile/Faq'))
    },

    {
      key: 'schedules',
      path: '/laboratory/schedules',
      component: lazy(() => import('pages/Laboratory/Schedules'))
    },

    {
      key: 'schedules/new',
      path: '/laboratory/schedules/new',
      component: lazy(() => import('pages/Laboratory/Schedules'))
    },

    {
      key: 'schedules/update',
      path: '/laboratory/schedules/update/:id',
      component: lazy(() => import('pages/Laboratory/Schedules'))
    },

    {
      key: 'appointments',
      path: '/laboratory/appointments',
      component: lazy(() => import('pages/Laboratory/Appointments'))
    },

    {
      key: 'settings',
      path: '/laboratory/settings',
      component: lazy(() => import('pages/Laboratory/Settings'))
    }
  ],

  profissional: [
    {
      key: 'home',
      path: '/professional/home',
      component: lazy(() => import('pages/Professional/Home'))
    },

    {
      key: 'profile/general',
      path: '/professional/profile/general',
      component: lazy(() => import('pages/Professional/Profile/General'))
    },

    {
      key: 'profile/locations',
      path: '/professional/profile/locations',
      component: lazy(() => import('pages/Professional/Profile/Locations'))
    },

    {
      key: 'profile/locations/new',
      path: '/professional/profile/locations/new',
      component: lazy(() => import('pages/Professional/Profile/Locations'))
    },

    {
      key: 'profile/locations/update',
      path: '/professional/profile/locations/update/:id',
      component: lazy(() => import('pages/Professional/Profile/Locations'))
    },

    {
      key: 'profile/atuations',
      path: '/professional/profile/atuations',
      component: lazy(() => import('pages/Professional/Profile/Atuations'))
    },

    {
      key: 'profile/agreements',
      path: '/professional/profile/agreements',
      component: lazy(() => import('pages/Professional/Profile/Agreements'))
    },
    {
      key: 'profile/keywords',
      path: '/professional/profile/keywords',
      component: lazy(() => import('pages/Professional/Profile/Keywords'))
    },

    {
      key: 'profile/exams',
      path: '/professional/profile/exams',
      component: lazy(() => import('pages/Professional/Profile/Exams'))
    },

    {
      key: 'profile/procedures',
      path: '/professional/profile/procedures',
      component: lazy(() => import('pages/Professional/Profile/Procedures'))
    },

    {
      key: 'profile/specializations',
      path: '/professional/profile/specializations',
      component: lazy(() =>
        import('pages/Professional/Profile/Specializations')
      )
    },

    {
      key: 'schedules',
      path: '/professional/schedules',
      component: lazy(() => import('pages/Professional/Schedules'))
    },

    {
      key: 'schedules/new',
      path: '/professional/schedules/new',
      component: lazy(() => import('pages/Professional/Schedules'))
    },

    {
      key: 'schedules/update',
      path: '/professional/schedules/update/:id',
      component: lazy(() => import('pages/Professional/Schedules'))
    },

    {
      key: 'appointments',
      path: '/professional/appointments',
      component: lazy(() => import('pages/Professional/Appointments'))
    }

    // {
    //   key: 'settings',
    //   path: '/professional/settings',
    //   component: lazy(() => import('pages/Professional/Settings'))
    // }
  ]
}

function Routes() {
  const { to, category } = useSelector(state => state.user)

  return (
    <ConnectedRouter history={history}>
      <Suspense fallback={<Loading />}>
        <Switch>
          <Route path='/' exact>
            <SignIn />
          </Route>

          <Route path='/forgot-password' exact>
            <ForgotPassword />
          </Route>

          {!!category &&
            !!ROUTES[category] &&
            ROUTES[category].length > 0 &&
            ROUTES[category].map(({ key, path, component: Component }) => (
              <Route key={key} path={path} exact isPrivate>
                <Component />
              </Route>
            ))}

          <Redirect from='/' to={to || '/'} />

          {/* <Route path='/clinic/home' exact isPrivate>
          <ClinicHome />
        </Route> */}
          {/* <Route path='/clinic/profile/general' exact isPrivate>
          <ClinicGeneral />
        </Route> */}
          {/* <Route path='/clinic/profile/specialties' exact isPrivate>
          <ClinicSpecialties />
        </Route> */}
          {/*
        <Route path='/clinic/profile/agreements' exact isPrivate>
          <ClinicAgreements />
        </Route> */}
          {/* <Route path='/clinic/profile/keywords' exact isPrivate>
          <ClinicKeywords />
        </Route> */}
          {/*
        <Route path='/clinic/profile/exams' exact isPrivate>
          <ClinicExams />
        </Route> */}
          {/*
        <Route path='/clinic/profile/procedures' exact isPrivate>
          <ClinicProcedures />
        </Route> */}
          {/* <Route path='/clinic/professionals' exact isPrivate>
          <Professionals />
        </Route>

        <Route path='/clinic/professionals/new' exact isPrivate>
          <Professionals />
        </Route>

        <Route path='/clinic/professionals/update/:id' exact isPrivate>
          <Professionals />
        </Route> */}
          {/* <Route path='/clinic/schedules' exact isPrivate>
          <ClinicSchedules />
        </Route>

        <Route path='/clinic/schedules/new' exact isPrivate>
          <ClinicSchedules />
        </Route>

        <Route path='/clinic/schedules/update/:id' exact isPrivate>
          <ClinicSchedules />
        </Route> */}
          {/* <Route path='/clinic/appointments' exact isPrivate>
          <ClinicAppointments />
        </Route> */}
          {/* <Route path='/clinic/settings' exact isPrivate>
          <ClinicSettings />
        </Route> */}
          {/* <Route path='/professional/home' exact isPrivate>
            <ProfessionalHome />
          </Route> */}

          {/* <Route path='/professional/profile/general' exact isPrivate>
            <ProfessionalGeneral />
          </Route>

          <Route path='/professional/profile/locations' exact isPrivate>
            <ProfessionalLocations />
          </Route>

          <Route path='/professional/profile/locations/new' exact isPrivate>
            <ProfessionalLocations />
          </Route> */}
          {/*
          <Route
            path='/professional/profile/locations/update/:id'
            exact
            isPrivate
          >
            <ProfessionalLocations />
          </Route>

          <Route path='/professional/profile/atuations' exact isPrivate>
            <ProfessionalAtuations />
          </Route>

          <Route path='/professional/profile/agreements' exact isPrivate>
            <ProfessionalAgreements />
          </Route>

          <Route path='/professional/profile/keywords' exact isPrivate>
            <ProfessionalKeywords />
          </Route>

          <Route path='/professional/profile/exams' exact isPrivate>
            <ProfessionalExams />
          </Route>

          <Route path='/professional/profile/procedures' exact isPrivate>
            <ProfessionalProcedures />
          </Route>

          <Route path='/professional/profile/specializations' exact isPrivate>
            <ProfessionalSpecializations />
          </Route> */}

          {/* <Route path='/professional/schedules' exact isPrivate>
            <ProfessionalSchedules />
          </Route>

          <Route path='/professional/schedules/new' exact isPrivate>
            <ProfessionalSchedules />
          </Route>

          <Route path='/professional/schedules/update/:id' exact isPrivate>
            <ProfessionalSchedules />
          </Route> */}

          {/* <Route path='/professional/appointments' exact isPrivate>
            <ProfessionalAppointments />
          </Route>

          <Route path='/professional/settings' exact isPrivate>
            <ProfessionalSettings />
          </Route> */}
        </Switch>
      </Suspense>
    </ConnectedRouter>
  )
}

export default Routes
