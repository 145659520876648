import React from 'react'

export function Remove(props) {
  return (
    <svg
      {...props}
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      width='35'
      height='35'
      viewBox='0 0 35 35'
    >
      <defs>
        <style>
          {
            '.remove-a,.remove-b{fill:none;}.remove-a,.remove-d{clip-rule:evenodd;}.remove-c,.remove-d{fill:#c40000;}.remove-e{fill:rgba(255,255,255,0);}.remove-f{clip-path:url(#remove-a);}.remove-g{clip-path:url(#remove-b);}.remove-h{clip-path:url(#remove-c);}.i{clip-path:url(#remove-d);}.j{clip-path:url(#remove-e);}.remove-k{clip-path:url(#remove-f);}.remove-l{fill:#fff;}'
          }
        </style>
        <clipPath id='remove-a'>
          <path className='remove-a' d='M9.856-.287H0V-20H19.712V-.287Z' />
        </clipPath>
        <clipPath id='remove-b'>
          <path className='remove-b' d='M-1365,805H435V-796H-1365Z' />
        </clipPath>
        <clipPath id='remove-c'>
          <rect
            className='remove-c'
            width='20'
            height='20'
            transform='translate(0 -20)'
          />
        </clipPath>
        <clipPath id='remove-d'>
          <path
            className='remove-d'
            d='M19.712-5.445A5.158,5.158,0,0,1,14.557-.287h-9.4A5.158,5.158,0,0,1,0-5.445v-9.4A5.159,5.159,0,0,1,5.155-20h9.4a5.159,5.159,0,0,1,5.156,5.159Z'
          />
        </clipPath>
        <clipPath id='remove-e'>
          <path className='remove-c' d='M0,0H20V-20H0Z' />
        </clipPath>
        <clipPath id='remove-f'>
          <path
            className='remove-a'
            d='M8.574-5.036v-3.8H4.751v-2.621H8.574v-3.8h2.553v3.8h3.836v2.621H11.127v3.8Z'
          />
        </clipPath>
      </defs>
      <path className='remove-e' d='M0,0H35V35H0Z' />
      <path className='remove-e' d='M0,0H35V35H0Z' />
      <g transform='translate(8 28)'>
        <g className='remove-f'>
          <g className='remove-g'>
            <g className='remove-h'>
              <g className='i'>
                <g className='remove-j'>
                  <path className='remove-c' d='M-5,4.713H24.712V-25H-5Z' />
                </g>
              </g>
            </g>
          </g>
        </g>
        <g className='remove-k' transform='translate(10.06 3.999) rotate(-45)'>
          <g className='remove-g'>
            <path className='remove-l' d='M-.249-.036H19.963V-20.251H-.249Z' />
          </g>
        </g>
      </g>
    </svg>
  )
}
