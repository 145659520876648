import styled from 'styled-components/macro'
import { theme, withProp } from 'styled-tools'
import { rgba } from 'polished'

export const Container = styled.section`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 16px;
  padding-right: 16px;
  background-color: ${withProp(theme('color.text'), color =>
    rgba(color, 0.39)
  )};
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 514px;
  padding: 40px 84px;
  background-color: ${theme('color.white')};
  border-radius: ${theme('radius.lg')};
`

export const Description = styled.p`
  text-align: center;
  margin-bottom: 42px;
`
