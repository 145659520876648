import Immutable from 'seamless-immutable'
import { createActions, createReducer } from 'reduxsauce'

const initialState = Immutable({
  token: localStorage.getItem('@gurumed:token') || null,
  isAuthenticated: !!localStorage.getItem('@gurumed:token'),
  modal: {
    isOpen: false
  },
  loading: false
})

const { Types, Creators } = createActions({
  signInRequest: ['email', 'password'],
  signInSuccess: [],
  signInFailure: [],

  forgotPasswordRequest: ['email'],
  forgotPasswordSuccess: [],
  forgotPasswordFailure: [],

  showForgotPasswordModal: [],
  hideForgotPasswordModal: [],

  signOut: []
})

const auth = createReducer(initialState, {
  [Types.SIGN_IN_REQUEST]: state =>
    state.merge({
      loading: true
    }),

  [Types.SIGN_IN_SUCCESS]: (state, { token }) =>
    state.merge({
      token,
      isAuthenticated: true,
      loading: false
    }),

  [Types.SIGN_IN_FAILURE]: state =>
    state.merge({
      loading: false
    }),

  [Types.FORGOT_PASSWORD_REQUEST]: state =>
    state.merge({
      loading: true
    }),

  [Types.FORGOT_PASSWORD_SUCCESS]: state =>
    state.merge({
      loading: false
    }),

  [Types.FORGOT_PASSWORD_FAILURE]: state => state.merge({ loading: false }),

  [Types.SHOW_FORGOT_PASSWORD_MODAL]: state =>
    state.merge({
      modal: {
        isOpen: true
      }
    }),

  [Types.HIDE_FORGOT_PASSWORD_MODAL]: state =>
    state.merge({
      modal: {
        isOpen: false
      }
    }),

  [Types.SIGN_OUT]: state =>
    state.merge({
      token: null,
      isAuthenticated: false
    })
})

export const AuthTypes = Types
export const AuthActions = Creators

export default auth
