import React from 'react'

export function Plus(props) {
  return (
    <svg
      {...props}
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      width='35'
      height='35'
      viewBox='0 0 35 35'
    >
      <defs>
        <style>
          {
            '.plus-a,.plus-b{fill:none;}.plus-b{clip-rule:evenodd;}.plus-c{clip-path:url(#plus-a);}.plus-d{clip-path:url(#plus-b);}.plus-e{clip-path:url(#plus-c);}.plus-f{clip-path:url(#plus-d);}.plus-g{clip-path:url(#plus-e);}.plus-h{clip-path:url(#plus-f);}.plus-i{fill:#00c4ae;}.plus-j{clip-path:url(#plus-g);}.plus-k{fill:#fff;}.plus-l{fill:rgba(255,255,255,0);}'
          }
        </style>
        <clipPath id='plus-a'>
          <rect className='plus-a' width='35' height='35' />
        </clipPath>
        <clipPath id='plus-b'>
          <path className='plus-b' d='M9.856-.287H0V-20H19.712V-.287Z' />
        </clipPath>
        <clipPath id='plus-c'>
          <path className='plus-a' d='M-1365,805H435V-796H-1365Z' />
        </clipPath>
        <clipPath id='plus-d'>
          <rect
            className='plus-a'
            width='20'
            height='20'
            transform='translate(0 -20)'
          />
        </clipPath>
        <clipPath id='plus-e'>
          <path
            className='plus-b'
            d='M19.712-5.445A5.158,5.158,0,0,1,14.557-.287h-9.4A5.158,5.158,0,0,1,0-5.445v-9.4A5.159,5.159,0,0,1,5.155-20h9.4a5.159,5.159,0,0,1,5.156,5.159Z'
          />
        </clipPath>
        <clipPath id='plus-f'>
          <path className='plus-a' d='M0,0H20V-20H0Z' />
        </clipPath>
        <clipPath id='plus-g'>
          <path
            className='plus-b'
            d='M8.574-5.036v-3.8H4.751v-2.621H8.574v-3.8h2.553v3.8h3.836v2.621H11.127v3.8Z'
          />
        </clipPath>
      </defs>
      <g className='plus-c'>
        <g transform='translate(8 28)'>
          <g className='plus-d'>
            <g className='plus-e'>
              <g className='plus-f'>
                <g className='plus-g'>
                  <g className='plus-h'>
                    <path className='plus-i' d='M-5,4.713H24.712V-25H-5Z' />
                  </g>
                </g>
              </g>
            </g>
          </g>
          <g className='plus-j'>
            <g className='plus-e'>
              <path className='plus-k' d='M-.249-.036H19.963V-20.251H-.249Z' />
            </g>
          </g>
        </g>
        <rect className='plus-l' width='35' height='35' />
      </g>
    </svg>
  )
}
