import React from 'react'

export function Date(props) {
  return (
    <svg
      {...props}
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
    >
      <defs>
        <style>{'.date-a{fill:none;}.date-b{fill:#494949;}'}</style>
      </defs>
      <path className='date-a' d='M0,0H24V24H0Z' />
      <path
        className='date-b'
        d='M16,3V4H8V3A1,1,0,0,0,6,3V4H5A1.991,1.991,0,0,0,3.01,6L3,20a2,2,0,0,0,2,2H19a2.006,2.006,0,0,0,2-2V6a2.006,2.006,0,0,0-2-2H18V3a1,1,0,0,0-2,0Zm2,17H6a1,1,0,0,1-1-1V9H19V19A1,1,0,0,1,18,20Z'
      />
      <rect
        className='date-b'
        width='5'
        height='5'
        rx='1'
        transform='translate(12 13)'
      />
    </svg>
  )
}
