import { all } from 'redux-saga/effects'

// -> General
import auth from './auth'
import schedules from './schedules'
import appointments from './appointments'
import reports from './reports'

// -> Clinic
import clinicProfile from './clinicProfile'
import professionals from './professionals'

// -> Laboratory
import laboratoryProfile from './laboratoryProfile'

// -> Professional
import professionalProfile from './professionalProfile'

function* rootSaga() {
  return yield all([
    // -> General
    auth,
    schedules,
    appointments,
    reports,

    // -> Clinic
    clinicProfile,
    professionals,

    // -> Laboratory
    laboratoryProfile,

    // -> Professional
    professionalProfile
  ])
}

export default rootSaga
