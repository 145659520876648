import { createStore, compose, applyMiddleware } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import createSagaMiddleware from 'redux-saga'

import rootReducer from './ducks'
import rootSaga from './sagas'
import logger from './logger'
import history from 'services/history'

const sagaMiddleware = createSagaMiddleware()

const middlewares = [routerMiddleware(history), sagaMiddleware]

const enhancer =
  process.env.NODE_ENV === 'development'
    ? compose(applyMiddleware(...middlewares), logger())
    : applyMiddleware(...middlewares)

const store = createStore(rootReducer(history), enhancer)

sagaMiddleware.run(rootSaga)

export default store
