import styled, { css, keyframes } from 'styled-components/macro'
import { theme, prop, withProp, switchProp, ifProp } from 'styled-tools'
import { transparentize } from 'polished'

const animation = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

export const Container = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${prop('width', 'auto')};
  margin-right: ${prop('mr')};
  margin-bottom: ${prop('mb')};
  margin-left: ${prop('ml')};
  padding-left: 18px;
  padding-right: 18px;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 0;
  white-space: nowrap;
  background-color: transparent;
  border: none;
  border-radius: ${theme('radius.md')};
  transition: all 0.2s linear;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  ${switchProp('variant', {
    primary: css`
      color: ${theme('color.green')};
      background-color: ${theme('color.orange')};
    `,

    secondary: css`
      color: ${theme('color.white')};
      background-color: ${theme('color.purple')};
    `,

    tertiary: css`
      color: ${theme('color.white')};
      background-color: ${theme('color.blue')};
    `,

    quaternary: css`
      color: ${theme('color.white')};
      background-color: ${theme('color.lightGreen')};
    `,

    danger: css`
      color: ${theme('color.white')};
      background-color: ${theme('color.red')};
    `,

    outline: css`
      color: ${theme('color.blue')};
      background-color: transparent;
      border: ${theme('border.outline')};
      border-radius: 5px;
    `
  })};

  ${switchProp('size', {
    sm: css`
      height: 40px;
    `,

    default: css`
      height: 50px;
    `,

    md: css`
      height: 60px;
    `
  })}

  ${ifProp(
    'disabled',
    css`
      opacity: 0.6;
      cursor: not-allowed;
    `
  )}
`

export const Loading = styled.span`
  display: inline-block;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  animation: 1s 0.3s ${animation} infinite;

  ${switchProp('variant', {
    primary: css`
      border: 2px solid ${withProp(theme('color.green'), transparentize(0.6))};
      border-top-color: ${withProp(theme('color.green'), transparentize(0.1))};
    `,

    secondary: css`
      border: 2px solid ${withProp(theme('color.white'), transparentize(0.6))};
      border-top-color: ${withProp(theme('color.white'), transparentize(0.1))};
    `,

    tertiary: css`
      border: 2px solid ${withProp(theme('color.white'), transparentize(0.6))};
      border-top-color: ${withProp(theme('color.white'), transparentize(0.1))};
    `,

    quaternary: css`
      border: 2px solid ${withProp(theme('color.white'), transparentize(0.6))};
      border-top-color: ${withProp(theme('color.white'), transparentize(0.1))};
    `,

    danger: css`
      border: 2px solid ${withProp(theme('color.white'), transparentize(0.6))};
      border-top-color: ${withProp(theme('color.white'), transparentize(0.1))};
    `,

    outline: css`
      border: 2px solid ${withProp(theme('color.blue'), transparentize(0.6))};
      border-top-color: ${withProp(theme('color.blue'), transparentize(0.1))};
    `
  })}
`
