import React from 'react'

export function Canceled(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='35.47'
      height='30.08'
      viewBox='0 0 35.47 30.08'
    >
      <defs>
        <style>{'.canceled-a{fill:red;}'}</style>
      </defs>
      <g transform='translate(9.619 84.229)'>
        <path
          className='canceled-a'
          d='M10.915-54.357A15.031,15.031,0,0,0,25.851-69.293,15.034,15.034,0,0,0,10.9-84.229,15,15,0,0,0-3.961-70.559a7.97,7.97,0,0,1,1.98-.1A12.9,12.9,0,0,1,10.9-82.308,12.99,12.99,0,0,1,23.915-69.293a12.889,12.889,0,0,1-3.41,8.786c-1.325-1.832-4.884-3.8-9.6-3.8a15.632,15.632,0,0,0-4.244.581,8.547,8.547,0,0,1,.268,2.144,9.145,9.145,0,0,1-2.159,5.867A14.683,14.683,0,0,0,10.915-54.357ZM10.9-66.851c2.859.015,5.078-2.427,5.078-5.614A5.309,5.309,0,0,0,10.9-77.959a5.3,5.3,0,0,0-5.078,5.495C5.838-69.278,8.041-66.881,10.9-66.851Zm-13.119,12.7a7.468,7.468,0,0,0,7.4-7.4,7.442,7.442,0,0,0-7.4-7.416,7.452,7.452,0,0,0-7.4,7.416A7.439,7.439,0,0,0-2.218-54.149ZM-4.5-58.08a.81.81,0,0,1-1.161-.03.809.809,0,0,1-.03-1.161l2.323-2.308-2.159-2.189a.764.764,0,0,1,0-1.1.8.8,0,0,1,1.117,0l2.174,2.159L.075-65.034A.81.81,0,0,1,1.236-65a.8.8,0,0,1,.03,1.161L-1.057-61.52,1.1-59.376a.787.787,0,0,1,0,1.132.8.8,0,0,1-1.117,0L-2.174-60.4Z'
        />
      </g>
    </svg>
  )
}
