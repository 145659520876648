import { takeLatest, call, put, all } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import { format, parseISO } from 'date-fns'
import { formatToBRL } from 'brazilian-values'

import { ReportsTypes, ReportsActions } from '../ducks/reports'

import api from 'services/api'
import Constants from 'utils/constants'

function* getReports({ id, context }) {
  try {
    const endpoint = {
      [Constants.CONTEXTS.CLINIC]: `/clinics/${id}/reports`,
      [Constants.CONTEXTS.LABORATORY]: `/laboratories/${id}/reports`,
      [Constants.CONTEXTS.PROFESSIONAL]: `/professionals/${id}/reports`
    }

    const { data: payload } = yield call(api.get, endpoint[context])

    const reports = {
      ...payload,
      displayDate: format(parseISO(payload?.date), 'dd/MM/yyyy'),
      // displayDueDate: format(parseISO(payload.dueDate), 'dd/MM/yyyy'),
      // displayPeriod: `${format(
      //   parseISO(payload.period.start),
      //   'dd/MM/yyyy'
      // )} a ${format(parseISO(payload.period.end), 'dd/MM/yyyy')}`,
      // displayBill: formatToBRL(payload.bill),
      appointments: {
        ...payload.appointments,
        displayRevenues: formatToBRL(payload.appointments.revenues)
      }
      // extracts: payload.extracts.map(extract => ({
      //   ...extract,
      //   appointments: extract.appointments.map(appointment => ({
      //     ...appointment,
      //     displayDateAndShift: `${format(
      //       parseISO(appointment.date),
      //       'dd/MM/yyyy'
      //     )}, pela ${appointment.shift.toLowerCase()}`
      //   })),
      //   displayPrice: formatToBRL(extract.price),
      //   displayDisabled: extract.price <= 0
      // }))
    }

    yield put(ReportsActions.getReportsSuccess(reports))
  } catch (error) {
    yield put(ReportsActions.getReportsFailure())

    toast.error('Falha ao buscar relatórios, tente novamente!')
  }
}

export default all([takeLatest(ReportsTypes.GET_REPORTS_REQUEST, getReports)])
