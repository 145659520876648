import Immutable from 'seamless-immutable'
import { createActions, createReducer } from 'reduxsauce'

const initialState = Immutable({
  appointments: [],
  details: null,
  filterByStatus: '',
  filterByProfessional: '',
  filterByClinic: '',
  modal: {
    isOpen: false,
    type: null,
    appointment: null
  },
  loading: {
    appointments: false,
    actions: false,
    modal: false
  }
})

const { Types, Creators } = createActions({
  getAppointmentsRequest: ['context', 'id', 'filter'],
  getAppointmentsSuccess: ['appointments', 'details'],
  getAppointmentsFailure: [],

  confirmAppointmentRequest: ['confirmedBy', 'appointment'],
  confirmAppointmentSuccess: ['appointment'],
  confirmAppointmentFailure: [],

  cancelAppointmentRequest: [
    'justification',
    'canceledBy',
    'canceledByType',
    'appointment'
  ],
  cancelAppointmentSuccess: ['appointment'],
  cancelAppointmentFailure: [],

  attendAppointmentRequest: ['attendConfirmedBy', 'appointment'],
  attendAppointmentSuccess: ['appointment'],
  attendAppointmentFailure: [],

  notAttendAppointmentRequest: ['appointment'],
  notAttendAppointmentSuccess: ['appointment'],
  notAttendAppointmentFailure: [],

  setAppointmentsModal: ['options'],

  setAppointmentsFilterByStatus: ['status'],
  setAppointmentsFilterByProfessional: ['professional'],
  setAppointmentsFilterByClinic: ['clinic'],

  clearAppointmentsState: []
})

const appointments = createReducer(initialState, {
  [Types.GET_APPOINTMENTS_REQUEST]: state =>
    state.merge({ loading: { ...state.loading, appointments: true } }),

  [Types.GET_APPOINTMENTS_SUCCESS]: (state, { appointments, details }) =>
    state.merge({
      appointments,
      details,
      loading: { ...state.loading, appointments: false }
    }),

  [Types.GET_APPOINTMENTS_FAILURE]: state =>
    state.merge({ loading: { ...state.loading, appointments: false } }),

  [Types.CONFIRM_APPOINTMENT_REQUEST]: state =>
    state.merge({ loading: { ...state.loading, modal: true } }),

  [Types.CONFIRM_APPOINTMENT_SUCCESS]: (state, { appointment }) =>
    state.merge({
      appointments: state.appointments.map(current =>
        current._id === appointment._id
          ? {
              ...current,
              status: appointment.status,
              formattedConfirmedDate: appointment.formattedConfirmedDate,
              confirmedBy: appointment.confirmedBy
            }
          : current
      ),
      loading: { ...state.loading, modal: false }
    }),

  [Types.CONFIRM_APPOINTMENT_FAILURE]: state =>
    state.merge({ loading: { ...state.loading, modal: false } }),

  [Types.CANCEL_APPOINTMENT_REQUEST]: state =>
    state.merge({ loading: { ...state.loading, modal: true } }),

  [Types.CANCEL_APPOINTMENT_SUCCESS]: (state, { appointment }) =>
    state.merge({
      appointments: state.appointments.map(current =>
        current._id === appointment._id
          ? {
              ...current,
              status: appointment.status,
              formattedCanceledDate: appointment.formattedCanceledDate,
              canceledBy: appointment.canceledBy
            }
          : current
      ),
      loading: { ...state.loading, modal: false }
    }),

  [Types.CANCEL_APPOINTMENT_FAILURE]: state =>
    state.merge({ loading: { ...state.loading, modal: false } }),

  [Types.ATTEND_APPOINTMENT_REQUEST]: state =>
    state.merge({ loading: { ...state.loading, modal: true } }),

  [Types.ATTEND_APPOINTMENT_SUCCESS]: (state, { appointment }) =>
    state.merge({
      appointments: state.appointments.map(current =>
        current._id === appointment._id
          ? {
              ...current,
              status: appointment.status,
              formattedAttendConfirmedDate:
                appointment.formattedAttendConfirmedDate,
              attendConfirmedBy: appointment.attendConfirmedBy
            }
          : current
      ),
      loading: { ...state.loading, modal: false }
    }),

  [Types.NOT_ATTEND_APPOINTMENT_FAILURE]: state =>
    state.merge({ loading: { ...state.loading, modal: false } }),

  [Types.NOT_ATTEND_APPOINTMENT_REQUEST]: state =>
    state.merge({ loading: { ...state.loading, modal: true } }),

  [Types.NOT_ATTEND_APPOINTMENT_SUCCESS]: (state, { appointment }) =>
    state.merge({
      appointments: state.appointments.map(current =>
        current._id === appointment._id
          ? {
              ...current,
              status: appointment.status
            }
          : current
      ),
      loading: { ...state.loading, modal: false }
    }),

  [Types.ATTEND_APPOINTMENT_FAILURE]: state =>
    state.merge({ loading: { ...state.loading, modal: false } }),

  [Types.SET_APPOINTMENTS_MODAL]: (state, { options }) =>
    state.merge({ modal: { ...options } }),

  [Types.SET_APPOINTMENTS_FILTER_BY_STATUS]: (state, { status }) =>
    state.merge({
      filterByStatus: status
    }),

  [Types.SET_APPOINTMENTS_FILTER_BY_PROFESSIONAL]: (state, { professional }) =>
    state.merge({
      filterByProfessional: professional
    }),

  [Types.SET_APPOINTMENTS_FILTER_BY_CLINIC]: (state, { clinic }) =>
    state.merge({
      filterByClinic: clinic
    }),

  [Types.CLEAR_APPOINTMENTS_STATE]: () => initialState
})

export const AppointmentsTypes = Types
export const AppointmentsActions = Creators

export default appointments
