import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'

import { AuthActions } from 'store/ducks/auth'

import Button from 'components/Button'

import { Container, Wrapper, Description } from './styled'

function Modal() {
  const dispatch = useDispatch()

  const handleCloseModal = useCallback(
    e => {
      if (e.target !== e.currentTarget) return

      dispatch(AuthActions.hideForgotPasswordModal())
    },
    [dispatch]
  )

  return (
    <Container onClick={handleCloseModal}>
      <Wrapper>
        <Description>
          As instruções para recuperar sua senha foi enviada com sucesso
        </Description>

        <Button as={Link} to='/signin' variant='primary'>
          Voltar para o login
        </Button>
      </Wrapper>
    </Container>
  )
}

export default Modal
