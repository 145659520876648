import React from 'react'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { ThemeProvider } from 'styled-components'
import { ToastContainer } from 'react-toastify'

import Routes from './routes'
import GlobalStyle from './styles/GlobalStyle'
import store from './store'
import history from './services/history'
import theme from './theme'

function App() {
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <ThemeProvider theme={theme}>
          <Routes />
          <GlobalStyle />
          <ToastContainer autoClose={3000} />
        </ThemeProvider>
      </ConnectedRouter>
    </Provider>
  )
}

export default App
