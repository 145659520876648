import React from 'react'

export function Schedules(props) {
  return (
    <svg
      {...props}
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
    >
      <defs>
        <style>{'.a{fill:none;}.b{}'}</style>
      </defs>
      <path className='a' d='M0,0H24V24H0Z' />
      <path
        className='b'
        d='M16,3V4H8V3A1,1,0,0,0,6,3V4H5A1.991,1.991,0,0,0,3.01,6L3,20a2,2,0,0,0,2,2H19a2.006,2.006,0,0,0,2-2V6a2.006,2.006,0,0,0-2-2H18V3a1,1,0,0,0-2,0Zm2,17H6a1,1,0,0,1-1-1V9H19V19A1,1,0,0,1,18,20Z'
      />
      <g transform='translate(-335.9 -189.001)'>
        <path
          className='b'
          d='M598.845,200a1.623,1.623,0,1,0,1.622,1.623A1.623,1.623,0,0,0,598.845,200Z'
          transform='translate(-250.969 0)'
        />
        <path
          className='b'
          d='M350.85,895.13c0-.017,0-.068,0-.077a1.414,1.414,0,0,0-.116-.448,2.263,2.263,0,0,0-1.156-1.074,1.925,1.925,0,0,1,.026.463c-.021.252-.043.422-.065.537a.805.805,0,0,1,.376.358,1.581,1.581,0,0,1,.051,1.062.3.3,0,1,1-.278-.091,1.284,1.284,0,0,0-.031-.836.519.519,0,0,0-.242-.229l-.006,0a.783.783,0,0,0-.134-.046.552.552,0,0,0-.469.068,1.3,1.3,0,0,0-.42.725.3.3,0,1,1-.289-.045,1.59,1.59,0,0,1,.545-.921.82.82,0,0,1,.616-.127c.016-.092.035-.24.056-.476a1.939,1.939,0,0,0-.058-.569l-.042-.014c-.08-.027-.161-.052-.245-.074a4.352,4.352,0,0,0-2.176,0q-.126.034-.246.074h0a2.627,2.627,0,0,0-.151.934,2.012,2.012,0,0,0,.05.461.644.644,0,0,1,.078-.006.6.6,0,1,1-.35.114,2.246,2.246,0,0,1-.068-.57,3.2,3.2,0,0,1,.093-.8,2.273,2.273,0,0,0-1.17,1.081,1.416,1.416,0,0,0-.116.448c0,.009,0,.06,0,.077s0,.055,0,.059v1.3a.469.469,0,0,0,.469.469h5.012a.469.469,0,0,0,.469-.469v-1.3S350.85,895.143,350.85,895.13Z'
          transform='translate(0 -689.457)'
        />
      </g>
    </svg>
  )
}
